import { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getSettings } from '@src/api/setting';
import useCalculateDeliveryCharges from '@src/hooks/useDeliveryCharges';
import { BUFFET, DROP_OFF, KITCHEN_STAFF } from '@src/lib/constants';
import { calculatePercentage, formatsNumber, preventInvalidChars } from '@src/lib/helper';
import { editIcon, trashOutlineIcon } from '@src/lib/imgUrl';
import { Order } from '@src/model/Order';
import { Settings } from '@src/model/Setting';
import { updateInvoiceAction } from '@src/redux/action/invoiceDetails';
import { LoginStateProps, ReduxProps } from '@src/redux/type';
import useOrderSummary from '@src/screens/OrderSummary/useOrderSummary';

import { StyledWidthProvider } from '../DeliveryDetails/styles';
import InvoiceMessage, { InvoiceMessageProps } from '../InvoiceMessage';

import {
  StyledAmount,
  StyledAmountBeforeDiscount,
  StyledAmountContainer,
  StyledCardContainer,
  StyledEditInput,
  StyledText,
  StyledTotalContainer,
} from './style';
import { EditableFieldProps } from './types';

interface EditValueProps {
  editDiscount: boolean;
  editService: boolean;
  serviceFee: number;
  editDelivery: boolean;
  deliveryFee: number;
  setDeliveryFee: Dispatch<SetStateAction<number>>;
  setEditDelivery: Dispatch<SetStateAction<boolean>>;
  setServiceFee: Dispatch<SetStateAction<number>>;
  setEditService: Dispatch<SetStateAction<boolean>>;
  setEditDiscount: Dispatch<SetStateAction<boolean>>;
}

// eslint-disable-next-line complexity
const InvoiceCard = ({
  invoiceMessage,
  totalAmount = 0,
  setTotalAmount,
  discountAmount,
  discountType = 'percentage',
  discountValue,
  editValues,
  deliveryAmount,
  printMode = false,
  perPersonCost = 0,
  isPriceOverriden = false,
  overridenSubtotal = 0,
  originalTotalAmount = 0,
}: {
  totalAmount?: number;
  discountAmount?: number;
  invoiceMessage?: InvoiceMessageProps;
  discountType?: 'amount' | 'percentage';
  discountValue?: number;
  deliveryAmount?: number;
  editValues?: EditValueProps;
  setTotalAmount?: Dispatch<SetStateAction<string | number>>;
  printMode?: boolean;
  perPersonCost?: number;
  isPriceOverriden?: boolean;
  overridenSubtotal?: number;
  originalTotalAmount?: number;
}) => {
  const [settings, setSettings] = useState<Settings>();
  const [newDiscount, setNewDiscount] = useState<number>(0);

  const dispatch = useDispatch();

  const {
    vat: vatCharges,
    serviceFee,
    discountAmount: invoiceDiscountAmount,
    discount: invoiceDiscount,
    discountType: invoiceDiscountType,
    finalTotal,
    subTotal,
  } = useSelector((state: ReduxProps) => state?.invoiceDetailsReducer);

  const { orderId, numberOfPerson, packageDetails, emirate, Bookings } = useSelector(
    (state: ReduxProps) => state?.eventDetailsReducer
  );

  const { eventFormatData } = useOrderSummary();

  const serviceType = packageDetails?.service;

  const { role } = useSelector(
    (state: { loginReducer: { loginState: LoginStateProps } }) => state?.loginReducer?.loginState
  );

  const fetchSettings = async () => {
    try {
      const result = await getSettings({});
      result?.data && setSettings(result?.data);
    } catch (error) {
      console.log(error);
    }
  };

  const handleServiceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
      editValues?.setServiceFee(Number(e.target.value));
    }
  };

  const handleDeliveryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0 && Number(e.target.value) <= 1000000) {
      editValues?.setDeliveryFee(Number(e.target.value));
    }
  };

  const handleDiscountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (Number(e.target.value) >= 0 && Number(e.target.value) <= 100) {
      setNewDiscount(e.target.value as unknown as number);
    }
  };

  const finalDeliveryFee = useCalculateDeliveryCharges(eventFormatData, emirate, packageDetails?.service, settings);

  const renderServiceFee = useCallback(() => {
    const serviceFeeDisplay = `(${
      orderId?.servicePercentage ?? (numberOfPerson > 150 ? 0 : settings?.serviceCharges)
    }%)`;

    const isEditing = editValues?.editService;

    return !editValues ? (
      serviceFeeDisplay
    ) : (
      <EditableField
        isEditing={isEditing}
        value={
          isEditing
            ? editValues?.serviceFee
            : orderId?.servicePercentage ?? (numberOfPerson > 150 ? 0 : settings?.serviceCharges)
        }
        onValueChange={handleServiceChange}
        onToggleEdit={() => {
          editValues?.setEditService(!isEditing);
          editValues?.setServiceFee(numberOfPerson > 150 ? 0 : settings?.serviceCharges ?? 0);
        }}
      />
    );
  }, [editValues?.serviceFee, settings, editValues?.editService, orderId?.servicePercentage]);

  const renderDeliveryFee = useCallback(() => {
    const isEditing = editValues?.editDelivery;
    return !editValues ? null : (
      <EditableField
        isEditing={isEditing}
        type='amount'
        inputType='number'
        suffix=''
        value={
          isEditing ? editValues?.deliveryFee : orderId?.deliveryCost ?? (numberOfPerson > 150 ? 0 : finalDeliveryFee)
        }
        onValueChange={handleDeliveryChange}
        onToggleEdit={() => {
          editValues?.setEditDelivery(!isEditing);
          editValues?.setDeliveryFee(orderId?.deliveryCost ?? (numberOfPerson > 150 ? 0 : finalDeliveryFee ?? 0));
        }}
        isDeliveryFee={true}
      />
    );
  }, [editValues?.deliveryFee, settings, editValues?.editDelivery, orderId?.deliveryCost, deliveryAmount, Bookings]);

  const renderDiscountFields = useCallback(() => {
    const discountDisplay =
      invoiceDiscountType === 'percentage'
        ? `(${invoiceDiscount ?? 0}%)`
        : `(AED ${formatsNumber(invoiceDiscountAmount ?? 0, true)})`;

    const isEditing = editValues?.editDiscount;

    return !editValues ? (
      discountDisplay
    ) : (
      <EditableField
        isEditing={isEditing}
        value={isEditing ? newDiscount : invoiceDiscount ?? 0}
        type={invoiceDiscountType}
        onValueChange={handleDiscountChange}
        onToggleEdit={() => {
          editValues?.setEditDiscount(!isEditing);
          setNewDiscount(0);
        }}
        suffix={invoiceDiscountType === 'percentage' ? '%' : ''}
      />
    );
  }, [editValues?.editDiscount, invoiceDiscount, invoiceDiscountAmount, newDiscount, invoiceDiscountType]);

  const getPreDiscountValue = useCallback(() => {
    const serviceBeforeDiscount = calculatePercentage(
      subTotal,
      editValues?.editService
        ? editValues?.serviceFee
        : orderId?.servicePercentage ?? (numberOfPerson > 150 ? 0 : settings?.serviceCharges)
    );

    const vatBeforeDiscount = calculatePercentage(
      subTotal + serviceBeforeDiscount + Number(editValues?.deliveryFee ?? deliveryAmount),
      settings?.vatCharges
    );

    return subTotal + serviceBeforeDiscount + vatBeforeDiscount;
  }, [subTotal, editValues?.editService, editValues?.serviceFee, settings, deliveryAmount, editValues?.deliveryFee]);

  const getDiscountAmount = (newDiscountPercentage: number) => {
    if (editValues?.editDiscount) {
      return newDiscountPercentage;
    }

    return discountAmount;
  };

  const getDiscount = () => {
    if (editValues?.editDiscount) {
      return Number(newDiscount);
    }

    return discountValue;
  };

  useEffect(() => {
    fetchSettings();
  }, []);

  // eslint-disable-next-line complexity
  useEffect(() => {
    const newDiscountPercentage = calculatePercentage(totalAmount, newDiscount);

    const newSubTotal =
      totalAmount - (editValues?.editDiscount ? newDiscountPercentage : discountAmount ?? orderId?.discountValue ?? 0);

    const serviceCharges = calculatePercentage(
      newSubTotal,
      editValues?.serviceFee || (orderId?.servicePercentage ?? (numberOfPerson > 150 ? 0 : settings?.serviceCharges))
    );

    const vat = calculatePercentage(
      newSubTotal + (serviceType === 'buffet' ? serviceCharges : editValues?.deliveryFee ?? deliveryAmount ?? 0),
      settings?.vatCharges
    );

    if (!printMode)
      dispatch(
        updateInvoiceAction({
          vat,
          subTotal: totalAmount,
          discountAmount: getDiscountAmount(newDiscountPercentage),
          discountType: editValues?.editDiscount ? 'percentage' : discountType,
          discount: getDiscount(),
          ...(serviceType === 'buffet'
            ? { serviceFee: serviceCharges }
            : { deliveryCost: editValues?.deliveryFee, serviceFee: 0 }),
          finalTotal:
            newSubTotal +
            vat +
            (serviceType === 'buffet' ? serviceCharges : orderId?.deliveryCost ?? deliveryAmount ?? 0),
        })
      );

    setTotalAmount?.(
      newSubTotal + vat + (serviceType === 'buffet' ? serviceCharges : orderId?.deliveryCost ?? deliveryAmount ?? 0)
    );
  }, [
    settings,
    totalAmount,
    discountAmount,
    deliveryAmount,
    discountValue,
    editValues?.serviceFee,
    editValues?.deliveryFee,
    newDiscount,
    editValues?.editService,
    discountType,
    editValues?.editDiscount,
    orderId?.discountPercentage,
    orderId?.discountValue,
    orderId?.servicePercentage,
    orderId?.deliveryCost,
    numberOfPerson,
    isPriceOverriden,
  ]);

  const deliveryChargesValue = (editValues?.deliveryFee as number) ?? orderId?.deliveryCost ?? deliveryAmount;

  return (
    <StyledCardContainer>
      <div>
        {isPriceOverriden ? (
          !printMode ? (
            <StyledAmountContainer>
              <StyledText> Original Subtotal</StyledText>
              <StyledAmount>AED {formatsNumber(originalTotalAmount, true)}</StyledAmount>
            </StyledAmountContainer>
          ) : (
            <></>
          )
        ) : (
          <></>
        )}

        <StyledAmountContainer>
          <StyledText>Subtotal {isPriceOverriden ? `(${numberOfPerson} x ${perPersonCost})` : ''}</StyledText>
          <StyledAmount>
            AED {formatsNumber(isPriceOverriden && overridenSubtotal ? overridenSubtotal : subTotal, true)}
          </StyledAmount>
        </StyledAmountContainer>

        {invoiceDiscountAmount !== undefined && (
          <StyledAmountContainer>
            <StyledText>Discount {renderDiscountFields()}</StyledText>{' '}
            <StyledAmount>- AED {formatsNumber(invoiceDiscountAmount, true)}</StyledAmount>
          </StyledAmountContainer>
        )}
        {serviceType === BUFFET && (!!settings?.serviceCharges || orderId?.servicePercentage !== undefined) && (
          <StyledAmountContainer>
            <StyledText>Service Fee {renderServiceFee()}</StyledText>

            <StyledAmount>AED {formatsNumber(serviceFee, true)}</StyledAmount>
          </StyledAmountContainer>
        )}
        {serviceType === DROP_OFF && (!!settings?.deliveryCharges || orderId?.deliveryCost !== undefined) && (
          <StyledAmountContainer>
            <StyledText>Delivery Fee {renderDeliveryFee()}</StyledText>
            {deliveryChargesValue ? (
              <StyledAmount>
                AED
                {formatsNumber((editValues?.deliveryFee as number) ?? orderId?.deliveryCost ?? deliveryAmount, true)}
              </StyledAmount>
            ) : (
              <StyledAmount>Based on Emirate</StyledAmount>
            )}
          </StyledAmountContainer>
        )}
        {!!settings?.vatCharges && (
          <StyledAmountContainer>
            <StyledText>VAT {`(${settings?.vatCharges}%)`}</StyledText>
            <StyledAmount>AED {formatsNumber(vatCharges, true)}</StyledAmount>
          </StyledAmountContainer>
        )}
        <StyledTotalContainer>
          <StyledText>Total</StyledText>
          <StyledAmount>
            {!!invoiceDiscountAmount && (
              <StyledAmountBeforeDiscount>
                AED {formatsNumber(getPreDiscountValue() || 0, true)}
              </StyledAmountBeforeDiscount>
            )}
            AED {formatsNumber(finalTotal, true)}
          </StyledAmount>
        </StyledTotalContainer>
        <ExtraFields role={role} orderId={orderId} />
      </div>
      {invoiceMessage && (
        <InvoiceMessage
          isBorderRadius={invoiceMessage?.isBorderRadius}
          isErrorIcon={invoiceMessage?.isErrorIcon}
          message={invoiceMessage?.message}
          isCheckBox={invoiceMessage?.isCheckBox}
          handleCheck={invoiceMessage?.handleCheck}
        />
      )}
    </StyledCardContainer>
  );
};

export default InvoiceCard;

const ExtraFields = ({ role, orderId }: { role: string; orderId?: Order }) =>
  role !== KITCHEN_STAFF ? (
    <>
      {!!orderId?.refundedAmount && (
        <StyledTotalContainer $marginTop={10}>
          <StyledText>Refunded Amount</StyledText>
          <StyledAmount>AED {formatsNumber(orderId?.refundedAmount || 0, true)}</StyledAmount>
        </StyledTotalContainer>
      )}

      {!!orderId?.creditNote && (
        <StyledTotalContainer $marginTop={10}>
          <StyledText>Credit Note</StyledText>
          <StyledAmount>AED {formatsNumber(orderId?.creditNote || 0, true)}</StyledAmount>
        </StyledTotalContainer>
      )}
    </>
  ) : null;

const EditableField = ({
  isEditing,
  value,
  type = 'percentage',
  onValueChange,
  onToggleEdit,
  inputType = 'number.integer',
  suffix = '%',
  isDeliveryFee = false,
}: EditableFieldProps) => (
  <>
    {isEditing ? (
      <>
        <StyledWidthProvider width='60px'>
          <StyledEditInput value={value} onChange={onValueChange} type={inputType} onKeyDown={preventInvalidChars} />
        </StyledWidthProvider>
        {suffix}
      </>
    ) : type === 'percentage' ? (
      `(${value})%`
    ) : isDeliveryFee ? null : (
      `(AED ${value})`
    )}
    <img
      src={isEditing ? trashOutlineIcon : editIcon}
      alt={isEditing ? 'remove icon' : 'edit icon'}
      onClick={onToggleEdit}
    />
  </>
);
