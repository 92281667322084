import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps, DeleteBodySchema } from '@src/lib/getUpdate';
import { Dish } from '@src/model/Dish';

import apiRequest from './api';

export const createDish = (data: Dish) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_DISH}`,
    data,
  });
};

export const updateDish = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.DISH}/${id}`,
    data,
  });
};

export const updateDishStatus = (data: DeleteBodySchema, status: boolean, doubleConfirmed: boolean) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_DISH_STATUS}`,
    data: { ...data, status, doubleConfirmed },
  });
};

export const getDishById = (id: string, params?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DISH}/${id}`,
    config: { params },
  });
};

export const getDishes = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.DISH_LIST}`,
    config: { params },
  });
};
