import { lazy } from 'react';

import { UiRoutes } from '@src/lib/constants';
import { routesConfigType } from '@src/lib/types';
import DropoffChooseMeal from '@src/screens/DropoffChooseMeal';

const Dashboard = lazy(() => import('../screens/Dashboard'));
const Login = lazy(() => import('../screens/Login'));
const Home = lazy(() => import('../screens/Home'));
const Staffs = lazy(() => import('@src/screens/StaffManagement'));
const Ingredients = lazy(() => import('@src/screens/IngredientManagement'));
const Dishes = lazy(() => import('@src/screens/DishManagement'));
const Promos = lazy(() => import('@src/screens/PromoManagement'));
const AddOns = lazy(() => import('@src/screens/AddOnsManagement'));
const WeeklyMenus = lazy(() => import('@src/screens/WeeklyMenuManagement'));
const Packages = lazy(() => import('@src/screens/PackageManagement'));
const Orders = lazy(() => import('@src/screens/OrderManagement'));
const Settings = lazy(() => import('@src/screens/Settings/detail'));

const StaffDetails = lazy(() => import('../screens/StaffManagement/detail'));
const CreateStaff = lazy(() => import('../screens/StaffManagement/create'));
const EditStaff = lazy(() => import('@src/screens/StaffManagement/edit'));
const Clients = lazy(() => import('@src/screens/ClientManagement'));
const ClientDetails = lazy(() => import('@src/screens/ClientManagement/detail'));
const CreateClient = lazy(() => import('@src/screens/ClientManagement/create'));
const EditClient = lazy(() => import('@src/screens/ClientManagement/edit'));
const AllergensList = lazy(() => import('@src/screens/AllergensManagement'));
const CreateAllergens = lazy(() => import('@src/screens/AllergensManagement/create'));
const AllergenDetails = lazy(() => import('@src/screens/AllergensManagement/detail'));
const EditAllergen = lazy(() => import('@src/screens/AllergensManagement/edit'));
const Categories = lazy(() => import('@src/screens/CategoryManagement'));
const CategoryDetails = lazy(() => import('@src/screens/CategoryManagement/detail'));
const CreateCategory = lazy(() => import('@src/screens/CategoryManagement/create'));
const EditCategory = lazy(() => import('@src/screens/CategoryManagement/edit'));
const DishDetails = lazy(() => import('@src/screens/DishManagement/detail'));
const CreateDish = lazy(() => import('@src/screens/DishManagement/create'));
const EditDish = lazy(() => import('@src/screens/DishManagement/edit'));
const PromoDetails = lazy(() => import('@src/screens/PromoManagement/detail'));
const CreatePromo = lazy(() => import('@src/screens/PromoManagement/create'));
const EditPromo = lazy(() => import('@src/screens/PromoManagement/edit'));
const AddOnsDetails = lazy(() => import('@src/screens/AddOnsManagement/detail'));
const CreateAddOns = lazy(() => import('@src/screens/AddOnsManagement/create'));
const EditAddOns = lazy(() => import('@src/screens/AddOnsManagement/edit'));

const PackageDetails = lazy(() => import('@src/screens/PackageManagement/detail'));
const CreatePackage = lazy(() => import('@src/screens/PackageManagement/create'));
const EditPackage = lazy(() => import('@src/screens/PackageManagement/edit'));
const Menu = lazy(() => import('@src/screens/MenuManagement'));
const MenuDetails = lazy(() => import('@src/screens/MenuManagement/detail'));
const CopyMenu = lazy(() => import('@src/screens/MenuManagement/copy'));
const EditMenu = lazy(() => import('@src/screens/MenuManagement/edit'));
const CreateMenu = lazy(() => import('@src/screens/MenuManagement/create'));
const AddOnMenu = lazy(() => import('@src/screens/AddOnMenuManagement'));
const AddOnMenuDetails = lazy(() => import('@src/screens/AddOnMenuManagement/detail'));
const CopyAddOnMenu = lazy(() => import('@src/screens/AddOnMenuManagement/copy'));
const EditAddOnMenu = lazy(() => import('@src/screens/AddOnMenuManagement/edit'));
const CreateAddOnMenu = lazy(() => import('@src/screens/AddOnMenuManagement/create'));
const CreateWeeklyMenu = lazy(() => import('@src/screens/WeeklyMenuManagement/create'));
const EditWeeklyMenu = lazy(() => import('@src/screens/WeeklyMenuManagement/edit'));
const WeeklyMenuDetails = lazy(() => import('@src/screens/WeeklyMenuManagement/detail'));
const CopyWeeklyMenu = lazy(() => import('@src/screens/WeeklyMenuManagement/copy'));
const EditSettings = lazy(() => import('@src/screens/Settings/edit'));
const EditOrder = lazy(() => import('@src/screens/OrderManagement/edit'));
const PackagesScreen = lazy(() => import('@src/screens/Packages'));
const CreateOrderDetails = lazy(() => import('@src/screens/OrderDetailScreen'));
const ChooseMeal = lazy(() => import('@src/screens/ChooseMeal'));
const Feedback = lazy(() => import('@src/screens/Feedback'));
const DishFeedback = lazy(() => import('@src/screens/DishFeedback'));
const Delivery = lazy(() => import('@src/screens/Delivery'));
const ThankYouCard = lazy(() => import('@src/screens/Thankyou'));
const OrderSummary = lazy(() => import('@src/screens/OrderSummary'));
const PrintQR = lazy(() => import('@src/screens/PrintQR'));
const FeedbackDetails = lazy(() => import('@src/screens/Feedback/details'));
const PrintDetails = lazy(() => import('@src/screens/OrderManagement/printDetail'));
const EventFeedback = lazy(() => import('@src/screens/Feedback/eventFeedback'));
const BookingFeedback = lazy(() => import('@src/screens/Feedback/MenuOfTheDay/index'));
const DishFeedBackDetails = lazy(() => import('@src/screens/DishFeedback/details'));
const FeedbackDishDetails = lazy(() => import('@src/screens/Feedback/DishReview/index'));

const routesConfig: Array<routesConfigType> = [
  {
    path: UiRoutes.DASHBOARD,
    component: Dashboard,
    layout: 'private',
  },
  {
    path: UiRoutes.LOGIN,
    component: Login,
    layout: 'public',
  },
  {
    path: UiRoutes.HOME,
    component: Home,
    layout: 'private',
  },
  {
    path: UiRoutes.STAFF_MANAGEMENT,
    component: Staffs,
    layout: 'private',
  },
  {
    path: UiRoutes.FEEDBACK,
    component: Feedback,
    layout: 'private',
  },
  {
    path: UiRoutes.DISH_FEEDBACK,
    component: DishFeedback,
    layout: 'private',
  },
  {
    path: UiRoutes.STAFF_DETAILS,
    component: StaffDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_STAFF,
    component: CreateStaff,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_STAFF,
    component: EditStaff,
    layout: 'private',
  },
  {
    path: UiRoutes.CLIENT_MANAGEMENT,
    component: Clients,
    layout: 'private',
  },
  {
    path: UiRoutes.CLIENT_DETAILS,
    component: ClientDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_CLIENT,
    component: CreateClient,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_CLIENT,
    component: EditClient,
    layout: 'private',
  },
  {
    path: UiRoutes.INGREDIENT_MANAGEMENT,
    component: Ingredients,
    layout: 'private',
  },
  {
    path: UiRoutes.CATEGORY_MANAGEMENT,
    component: Categories,
    layout: 'private',
  },
  {
    path: UiRoutes.CATEGORY_DETAILS,
    component: CategoryDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_CATEGORY,
    component: CreateCategory,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_CATEGORY,
    component: EditCategory,
    layout: 'private',
  },
  {
    path: UiRoutes.ALLERGEN_MANAGEMENT,
    component: AllergensList,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_ALLERGEN,
    component: CreateAllergens,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_ALLERGEN,
    component: EditAllergen,
    layout: 'private',
  },
  {
    path: UiRoutes.ALLERGEN_DETAILS,
    component: AllergenDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.DISH_MANAGEMENT,
    component: Dishes,
    layout: 'private',
  },
  {
    path: UiRoutes.DISH_DETAILS,
    component: DishDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_DISH,
    component: CreateDish,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_DISH,
    component: EditDish,
    layout: 'private',
  },

  {
    path: UiRoutes.ADD_ON_MANAGEMENT,
    component: AddOns,
    layout: 'private',
  },
  {
    path: UiRoutes.ADD_ONS_DETAILS,
    component: AddOnsDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_ADD_ONS,
    component: CreateAddOns,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_ADD_ONS,
    component: EditAddOns,
    layout: 'private',
  },

  {
    path: UiRoutes.PROMO_MANAGEMENT,
    component: Promos,
    layout: 'private',
  },
  {
    path: UiRoutes.PROMO_DETAILS,
    component: PromoDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_PROMO,
    component: CreatePromo,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_PROMO,
    component: EditPromo,
    layout: 'private',
  },
  {
    path: UiRoutes.MENU_MANAGEMENT,
    component: Menu,
    layout: 'private',
  },
  {
    path: UiRoutes.MENU_DETAILS,
    component: MenuDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_MENU,
    component: EditMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.COPY_MENU,
    component: CopyMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_MENU,
    component: CreateMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.ADDON_MENU_MANAGEMENT,
    component: AddOnMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.ADDON_MENU_DETAILS,
    component: AddOnMenuDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_ADDON_MENU,
    component: EditAddOnMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.COPY_ADDON_MENU,
    component: CopyAddOnMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_ADDON_MENU,
    component: CreateAddOnMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.WEEKLY_MENU_MANAGEMENT,
    component: WeeklyMenus,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_WEEKLY_MENU,
    component: CreateWeeklyMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_WEEKLY_MENU,
    component: EditWeeklyMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.COPY_WEEKLY_MENU,
    component: CopyWeeklyMenu,
    layout: 'private',
  },
  {
    path: UiRoutes.WEEKLY_MENU_DETAILS,
    component: WeeklyMenuDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.SETTINGS,
    component: Settings,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_SETTINGS,
    component: EditSettings,
    layout: 'private',
  },
  {
    path: UiRoutes.PACKAGE_MANAGEMENT,
    component: Packages,
    layout: 'private',
  },
  {
    path: UiRoutes.PACKAGE_DETAILS,
    component: PackageDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CREATE_PACKAGE,
    component: CreatePackage,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_PACKAGE,
    component: EditPackage,
    layout: 'private',
  },
  {
    path: UiRoutes.ORDER_MANAGEMENT,
    component: Orders,
    layout: 'private',
  },
  {
    path: UiRoutes.EDIT_ORDER,
    component: EditOrder,
    layout: 'private',
  },
  {
    path: UiRoutes.PACKAGES,
    component: PackagesScreen,
    layout: 'private',
  },
  {
    path: UiRoutes.ORDER_DETAILS,
    component: CreateOrderDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.CHOOSE_MEAL,
    component: ChooseMeal,
    layout: 'private',
  },
  {
    path: UiRoutes.DROP_OFF_CHOOSE_MEAL,
    component: DropoffChooseMeal,
    layout: 'private',
  },
  {
    path: UiRoutes.DELIVERY,
    component: Delivery,
    layout: 'private',
  },
  {
    path: UiRoutes.ORDER_SUMMARY,
    component: OrderSummary,
    layout: 'private',
  },
  {
    path: UiRoutes.THANKYOU,
    component: ThankYouCard,
    layout: 'private',
  },
  {
    path: UiRoutes.PRINT_QR,
    component: PrintQR,
    layout: 'private',
  },
  {
    path: UiRoutes.FEEDBACK_DETAILS,
    component: FeedbackDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.EVENT_FEEDBACK,
    component: EventFeedback,
    layout: 'private',
  },
  {
    path: UiRoutes.BOOKING_FEEDBACK,
    component: BookingFeedback,
    layout: 'private',
  },
  {
    path: UiRoutes.DISH_FEEDBACK_DETAILS,
    component: DishFeedBackDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.FEEDBACK_DISH_DETAILS,
    component: FeedbackDishDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.FEEDBACK_ADDONS_DETAILS,
    component: FeedbackDishDetails,
    layout: 'private',
  },
  {
    path: UiRoutes.PRINT_DETAILS,
    component: PrintDetails,
    layout: 'private',
  },
];

export default routesConfig;
