import { styled } from 'styled-components';

import { StyledInput } from '../FormComponent/styles';

export const StyledCardContainer = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
`;

export const StyledAmountContainer = styled.div`
  display: flex;
  padding: 8px 12px 8px 4px;
  font-size: 14px;
  font-weight: 700;
`;

export const StyledText = styled.div`
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  display: flex;
  flex: 1;
  gap: 2px;
  align-items: end;
`;

export const StyledAmount = styled.div`
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
  align-items: center;
  justify-content: end;
`;
export const StyledTotalContainer = styled.div<{ $marginTop?: number }>`
  background-color: ${({ theme }) => theme.primaryColor.azure};
  display: flex;
  gap: 4px;
  font-size: 18px;
  font-weight: 700;
  padding: 8px 12px 8px 4px;
  border-radius: 8px;
  margin-top: ${({ $marginTop }) => ($marginTop ? `${$marginTop}px` : '0px')};
`;

export const StyledAmountBeforeDiscount = styled.span`
  font-weight: 400;
  text-decoration: line-through;
  font-size: 14px;
`;

export const StyledEditInput = styled(StyledInput)`
  .MuiInputBase-root {
    height: 32px;
    background-color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  }
`;
