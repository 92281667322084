import { useEffect, useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import { useField } from 'formik';

import { FormControlLabel, Radio, RadioGroup } from '@mui/material';

import { cleanPhoneNumber } from '@src/lib/helper';
import { theme } from '@src/styles/theme';

import CustomModal from '../DeleteModal';
import ToggleIcon from '../Icons/toggleIcon';

import {
  extraStyles,
  StyledCheckBox,
  StyledCheckBoxContainer,
  StyledError,
  StyledInput,
  StyledInputFieldContainer,
  StyledLabel,
  StyledLabelContainer,
  StyledPhoneInputContainer,
  StyledToggleFieldContainer,
} from './styles';
import { InputLayoutProps, RadioOptionProps } from './types';

import 'react-phone-input-2/lib/style.css';

export const RadioButtonLayout = ({
  id,
  label,
  radioOptions,
  gridColumn,
  gridRow,
  onChange,
  onBlur,
  required = true,
}: InputLayoutProps) => {
  const [field, meta] = useField(id);

  return (
    <StyledInputFieldContainer $gridColumn={gridColumn} $gridRow={gridRow}>
      <StyledLabel id={id}>{label + (required ? ' *' : '')}</StyledLabel>
      <RadioGroup row name={id} value={field.value} onChange={onChange} onBlur={onBlur}>
        {radioOptions &&
          radioOptions.map(({ label: radioLabel, value }: RadioOptionProps, ind) => (
            <FormControlLabel
              key={`${label}-${ind}`}
              value={value}
              control={
                <Radio
                  sx={{
                    '& .MuiSvgIcon-root': {
                      fontSize: 16,
                    },
                  }}
                />
              }
              label={<StyledLabel>{radioLabel}</StyledLabel>}
              sx={{
                display: 'flex',
                alignItems: 'flex-end',
              }}
            />
          ))}
        {meta.error && meta.touched && <StyledError>{meta.error}</StyledError>}
      </RadioGroup>
    </StyledInputFieldContainer>
  );
};

export const PhoneInputLayout = ({
  id,
  label,
  required = true,
  disabled,
  fixedCountry = 'ae',
  fixedCountryCode,
  isLogin = true,
  handleMessageClear,
}: InputLayoutProps) => {
  const [field, meta, helpers] = useField(id);

  const handleChange = (value: string) => {
    helpers.setValue('+' + value || '');
  };

  const handleStyledInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputNumber = cleanPhoneNumber(event.target.value);
    helpers.setValue(inputNumber);

    if (handleMessageClear) {
      handleMessageClear();
    }
  };

  return (
    <StyledPhoneInputContainer>
      <div style={{ display: 'flex', gap: '0.2em' }}>
        <PhoneInput
          country={fixedCountry}
          value={field.value}
          onChange={handleChange}
          placeholder={label}
          dropdownStyle={{ borderRadius: '8px', width: '90px', display: fixedCountryCode }}
          inputStyle={{ display: 'none' }}
          containerStyle={{ maxWidth: '60px' }}
          buttonStyle={{
            width: '100%',
            borderRadius: '8px',
            backgroundColor: 'transparent',
            backdropFilter: `blur(${theme.fontSize.get('xl')})`,
            border: `1px solid ${theme.primaryColor.lunarElegance}`,
          }}
          copyNumbersOnly={false}
        />
        <StyledInput
          label={label}
          value={field.value}
          onChange={handleStyledInputChange}
          error={meta.touched && Boolean(meta.error)}
          onFocus={() => field.value == '' && fixedCountryCode && helpers.setValue(fixedCountryCode)}
          onBlur={() => helpers.setTouched(true)}
          required={required}
          disabled={disabled}
          sx={isLogin ? extraStyles : {}}
          InputLabelProps={{
            shrink: field.value !== undefined && field.value !== '' && true,
          }}
        />
      </div>

      {meta.touched && meta.error && <StyledError>{meta.error}</StyledError>}
    </StyledPhoneInputContainer>
  );
};

export const ToggleField = ({
  id,
  isToggleOnLabel,
  isToggleOffLabel,
  label,
  confirmationPopupData,
  gridColumn,
  gridRow,
  isOpenPop = true,
  showInline = false,
}: InputLayoutProps) => {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [field, meta, helpers] = useField(id);
  const [isToggle, setIsToggle] = useState<boolean>(!!field.value);
  const [isModalOpen, setIsModalOpen] = useState(false);

  useEffect(() => {
    setIsToggle(!!field.value);
  }, [field.value]);

  const changeStatus = () => {
    setIsToggle(prevIsToggle => {
      const newIsToggle = !prevIsToggle;
      helpers.setValue(newIsToggle);
      return newIsToggle;
    });
  };

  useEffect(
    () =>
      setIsToggle(prevIsToggle => {
        const newIsToggle = !prevIsToggle;
        helpers.setValue(newIsToggle);

        return newIsToggle;
      }),
    []
  );

  return (
    <>
      <StyledInputFieldContainer $gridColumn={gridColumn} $gridRow={gridRow}>
        <StyledLabelContainer $showInline={showInline}>
          <StyledLabel>{label}</StyledLabel>
          <StyledToggleFieldContainer>
            <ToggleIcon
              isToggled={isToggle}
              onClick={() => {
                isOpenPop ? setIsModalOpen(true) : changeStatus();
              }}
            />
            <StyledLabel>{isToggle ? isToggleOnLabel : isToggleOffLabel}</StyledLabel>
          </StyledToggleFieldContainer>
        </StyledLabelContainer>
      </StyledInputFieldContainer>
      {isModalOpen && (
        <CustomModal
          isOpen={isModalOpen}
          modalName={confirmationPopupData?.label ?? 'Are you sure to apply changes ?'}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          onSave={() => {
            changeStatus();
            setIsModalOpen(false);
          }}
        />
      )}
    </>
  );
};

export const CheckBoxInputLayout = ({ id, label, value, gridColumn, gridRow, onChange, onBlur }: InputLayoutProps) => {
  const isChecked = Boolean(value);
  return (
    <StyledInputFieldContainer $gridColumn={gridColumn} $gridRow={gridRow}>
      <StyledCheckBoxContainer>
        <StyledCheckBox id={id} name={id} type='checkbox' onChange={onChange} onBlur={onBlur} checked={isChecked} />
        <StyledLabel $isCheckBox={true}>{label}</StyledLabel>
      </StyledCheckBoxContainer>
    </StyledInputFieldContainer>
  );
};

export const InputField = ({
  id,
  label,
  onChange,
  onBlur,
  required = true,
  onClick,
  type,
  disabled = false,
  inputRef,
  onKeyDown,
}: InputLayoutProps) => {
  const [field, meta] = useField(id);
  return (
    <StyledInput
      key={id}
      id={id}
      inputRef={inputRef}
      variant='outlined'
      label={label}
      onChange={onChange}
      onBlur={onBlur}
      onClick={onClick}
      onKeyDown={onKeyDown}
      type={type}
      required={required}
      disabled={disabled}
      value={field?.value}
      size='small'
      error={meta?.touched && !!meta?.error}
      helperText={meta?.error && meta?.touched && meta?.error}
      InputLabelProps={{
        shrink: !!field?.value,
      }}
    />
  );
};
