import { theme } from '@src/styles/theme';

const AddonsIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg width='22' height='22' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M16 4H18V6H20V8H18V10H16V8H14V6H16V4Z'
      fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    />
    <path
      fillRule='evenodd'
      clipRule='evenodd'
      d='M12 12V6H4V20H18V12H12ZM6 8H10V12H6V8ZM10 14V18H6V14H10ZM16 14V18H12V14H16Z'
      fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    />
  </svg>
);

export default AddonsIcon;
