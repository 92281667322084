const TransactionIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='22'
    height='22'
    fill='#d45602'
    stroke='#d45602'
    viewBox='0 0 500.204 500.204'
  >
    <path d='M396.319 0H103.884c-16.63 0-30.161 13.53-30.161 30.161v439.882c0 16.631 13.53 30.161 30.161 30.161H342.86c2.785 0 5.455-1.107 7.425-3.075l73.12-73.121a10.5 10.5 0 0 0 3.075-7.424V30.161C426.48 13.53 412.95 0 396.319 0zm-42.958 464.353v-28.109c0-5.051 4.109-9.161 9.161-9.161h28.109l-37.27 37.27zM103.884 21h292.435c5.052 0 9.161 4.109 9.161 9.161v32.452H94.723V30.161c0-5.052 4.11-9.161 9.161-9.161zm-9.161 449.043V83.613H405.48v322.47h-42.958c-16.631 0-30.161 13.53-30.161 30.161v42.96H103.884c-5.051 0-9.161-4.11-9.161-9.161z' />
    <path d='M137.952 161.361h137.5c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5h-137.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5zM317.452 161.361h35.5c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5h-35.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5zM137.952 244.736h137.5c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5h-137.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5zM317.452 244.736h35.5c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5h-35.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5zM285.952 317.611c0-5.799-4.701-10.5-10.5-10.5h-137.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5h137.5c5.799 0 10.5-4.701 10.5-10.5zM317.452 328.111h35.5c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5h-35.5c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5zM352.952 388.111c5.799 0 10.5-4.701 10.5-10.5s-4.701-10.5-10.5-10.5h-100c-5.799 0-10.5 4.701-10.5 10.5s4.701 10.5 10.5 10.5h100z' />
  </svg>
);

export default TransactionIcon;
