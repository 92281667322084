import styled from 'styled-components';

import { blastLogo, loginFrameLogo, logo, textHighlight } from '@src/lib/imgUrl';

const StyledMainWrapper = styled.div`
  display: flex;
  max-width: 100vw;
  min-height: 100vh;
  overflow-y: auto;
`;

const StyledImageContainer = styled.div`
  display: none;
  width: 34%;
  background: url(${loginFrameLogo}) no-repeat center/cover;
  @media screen and (min-width: 890px) {
    display: flex;
  }
`;

const StyledContentContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: ${({ theme }) => theme.space.get('l')} 0;
  background: linear-gradient(to right, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(${loginFrameLogo}) no-repeat center/cover;
  @media screen and (min-width: 890px) {
    width: 66%;
    background: transparent;
  }
`;

const StyledSection = styled.section<{ $gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $gap }) => $gap || '40px'};
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;

const StyledHeader = styled.div`
  font-family: 'the-seasons', sans-serif;
  font-size: ${({ theme }) => theme.fontSize.get('xl')};
  color: ${({ theme }) => theme.primaryColor.whiteSmoke};
  white-space: wrap;

  @media screen and (min-width: 890px) {
    white-space: nowrap;
    color: ${({ theme }) => theme.primaryColor.black};
  }
`;

const StyledBackLing = styled.span<{ $isVerification?: boolean }>`
  background: url(${textHighlight}) no-repeat bottom;
  background-size: 100% auto;
  padding-bottom: ${({ theme, $isVerification }) =>
    $isVerification ? ` ${theme.fontSize.get('xs')}` : `calc( ${theme.fontSize.get('xs')}/2)`};
  @media screen and (min-width: 890px) {
    padding-bottom: ${({ theme, $isVerification }) =>
      $isVerification ? `${theme.fontSize.get('s')}` : `calc(${theme.fontSize.get('xs')}/2)`};
  }
`;

const StyledLabel = styled.div<{ error?: boolean }>`
  font-family: 'Lato', sans-serif;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-weight: 500;
  line-height: 30px;
  color: ${({ theme, error }) => (error ? theme.primaryColor.red : theme.primaryColor.brandPaletteGray)};
  @media screen and (min-width: 890px) {
    color: ${({ theme, error }) => (error ? theme.primaryColor.red : theme.primaryColor.brandPaletteGray)};
  }
`;

const StyledMaxWidthWrapper = styled.div<{ $isVerification?: boolean }>`
  display: flex;
  flex-direction: column;
  min-width: 80%;
  max-width: 80%;
  gap: 60px;

  @media screen and (min-width: 650px) {
    min-width: 65%;
    max-width: 65%;
  }
  @media screen and (min-width: 1024px) {
    min-width: 60%;
    max-width: 60%;
  }
  @media screen and (min-width: 1440px) {
    min-width: 50%;
    max-width: 50%;
  }
`;

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  gap: 60px;
`;

const StyledButton = styled.button<{ $loginColor?: boolean; $cursorPointer?: boolean; $isValid?: boolean }>`
  width: 100%;
  padding: 1rem;
  border: none;
  border-radius: 8px;
  ${({ $cursorPointer }) =>
    $cursorPointer ? ` cursor: ${$cursorPointer ? 'pointer' : 'not-allowed'};` : `cursor: pointer;`}
  background-color: ${({ theme }) => theme.primaryColor.white};
  color: ${({ theme }) => theme.primaryColor.black};
  @media screen and (min-width: 890px) {
    ${({ theme, $isValid, $loginColor }) =>
      $isValid
        ? `
      color: ${theme.primaryColor.white};
      background-color: ${theme.primaryColor.deepCerulean};`
        : `
      background-color: ${$loginColor ? theme.primaryColor.lightGray2 : theme.primaryColor.shadePurple};
      color: ${$loginColor ? theme.primaryColor.black : theme.primaryColor.white};`}
  }
`;

const StyledEmailAddress = styled.span`
  font-weight: 700;
`;

const StyledIconWrapper = styled.div`
  display: flex;
  width: fit-content;
  border-radius: calc(${({ theme }) => theme.space.get('2xs')});
  border: 2px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  padding: ${({ theme }) => theme.space.get('3xs')};
  backdrop-filter: blur(${({ theme }) => theme.space.get('3xs')});

  @media screen and (min-width: 890px) {
    padding: ${({ theme }) => theme.space.get('2xs')};
    border-radius: ${({ theme }) => theme.fontSize.get('xs')};
  }

  &:hover {
    border: 2px solid ${({ theme }) => theme.primaryColor.brandPaletteGray};
  }
`;

const StyledSvgImage = styled.img`
  height: auto;
  content: url(${logo});
  width: 100px;

  @media screen and (min-width: 890px) {
    width: 170px;
    content: url(${blastLogo});
  }
`;

const StyledLineContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  margin-bottom: ${({ theme }) => theme.space.get('3xs')};

  &::before,
  &::after {
    content: '';
    flex-grow: 1;
    height: 1px;
    background-color: ${({ theme }) => theme.primaryColor.brandPalette};
  }

  &::before {
    margin: 0 ${({ theme }) => theme.space.get('3xs')};

    @media screen and (min-width: 890px) {
      margin: 0 ${({ theme }) => theme.space.get('m')};
    }
  }

  &::after {
    margin: 0 ${({ theme }) => theme.space.get('3xs')};

    @media screen and (min-width: 890px) {
      margin: 0 ${({ theme }) => theme.space.get('m')};
    }
  }
`;

const StyledOtpInput = styled.input<{ $hasValue: boolean }>`
  text-align: center;
  background-color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  margin: 3px;
  width: 50px;
  padding: 10px;
  border-radius: 6px;
  border: 1px solid
    var(
      --Brand-Palette-Secondary-100,
      ${({ theme, $hasValue }) => ($hasValue ? theme.primaryColor.royalBlue : theme.primaryColor.borderBlue)}
    );
  &:focus {
    border: 1px solid var(--Brand-Palette-Secondary, ${({ theme }) => theme.primaryColor.royalBlue});
    outline: none;
  }
  &::placeholder {
    color: ${({ theme }) => theme.primaryColor.borderBlue};
  }
`;

const StyledOtpResend = styled.button`
  border: none;
  padding: 0;
  color: ${({ theme }) => theme.primaryColor.cyanGreen};
  background-color: transparent;
  cursor: pointer;
  font-weight: 700;
`;

export {
  StyledBackLing,
  StyledButton,
  StyledContentContainer,
  StyledEmailAddress,
  StyledForm,
  StyledHeader,
  StyledIconWrapper,
  StyledImageContainer,
  StyledLabel,
  StyledLineContainer,
  StyledMainWrapper,
  StyledMaxWidthWrapper,
  StyledOtpInput,
  StyledOtpResend,
  StyledSection,
  StyledSvgImage,
};
