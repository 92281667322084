import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps } from '@src/lib/getUpdate';
import { CreateBookingProps } from '@src/model/Event';

import apiRequest from './api';

export const createBooking = (data: CreateBookingProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_BOOKING}`,
    data,
  });
};

export const deleteBookingById = (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.DELETE_BOOKING}`,
    data: {
      filter: {
        _id: id,
      },
      options: {},
    },
  });
};

export const getBookingById = (id: string, params?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_BOOKING_BY_ID}/${id}`,
    config: {
      params,
    },
  });
};

export const updateBookingById = (id: string, data: DataProps) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.GET_BOOKING_BY_ID}/${id}`,
    data,
  });
};

export const getAllBookings = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_ALL_BOOKINGS}`,
    config: { params },
  });
};
