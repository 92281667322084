import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledInvoiceMessage = styled.div<{
  isBorderRadius?: boolean;
  textColor?: string;
  backgroundColor?: string;
}>`
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 12px;
  font-weight: 500;
  padding: 8px 12px;
  background-color: ${props => props.backgroundColor ?? props.theme.primaryColor.vanilla};
  color: ${props => props.textColor ?? props.theme.primaryColor.burntSienna};
  ${({ isBorderRadius }) => (isBorderRadius ? 'border-radius:8px;' : '')}
`;

export const StyledCheckBox = styled.input<{ color?: string }>`
  width: 14px;
  height: 14px;
  margin: 0;
  &:enabled:checked {
    accent-color: ${props => props.color ?? props.theme.primaryColor.darkYellow};
  }
`;

export const StyledLink = styled(Link)`
  text-decoration: none;
`;

export const StyledCheckboxLabel = styled.span`
  font-size: 16px;
  font-family: ${props => props.theme.fontFamily.lato};
  color: '#0F222E';
  font-weight: 700;
`;
