import { Space, space } from './space';

export const FontTShirtSizes = ['3xs', '2xs', 'xs', 's', 'm', 'l', 'xl', '2xl', '3xl', '4xl', '5xl'] as const;
export type FontSizes = typeof FontTShirtSizes[number];

export interface ThemeInterface {
  primaryColor: Record<string, string>;
  space: Space;
  fontSize: Map<FontSizes, string>;
  fontFamily: {
    firstFont: string;
    secondFont: string;
    tertiaryFont: string;
    lato: string;
    seasons: string;
  };
}

export const theme: ThemeInterface = {
  primaryColor: {
    shadePurple: '#255573',
    royalBlue: '#3790B3',
    brightCyan: '#34F5FC',
    twilightBlue: '#163345',
    sereneSkyBlue: '#d3e5f5',
    gentleAzure: '#d3e5f5',
    pristineSnowfall: '#ffffff',
    midnightInk: '#001e2f',
    duskHarbor: '#0f222e',
    slateShadow: '#555',
    silkenMoonlight: '#f3f4f6',
    lunarElegance: '#e5e7eb',
    azure: '#ebf7ff',
    lightGray: '#eae9e9',
    red: '#d32f2f',
    lightRed: '#FF7F7F',
    blue: 'blue',
    snow: '#F9FAFB',
    burntSienna: '#8A2C0D',
    green: '#90EE90',
    vanilla: '#FFF8F1',
    borderBlue: '#d7e9f0',
    cornflowerBlue: '#6060f9',
    white: 'white',
    whiteSmoke: '#F8F8F8',
    black: '#000000',
    fluentBlack: '#1e445c',
    steelBlue: '#228fb4',
    deepCerulean: '#09769b',
    fluentGray: '#47728c',
    mutedGray: '#4B5563',
    lightGray2: '#D1D5DB',
    softBlue: '#CCD0E5',
    lightPink: '#FFC0CB',
    aquamarine: '#BCF0DA',
    royalGray: '#6b7280',
    navyBlue: '#00137e',
    skyBlue: '#EBF7FF',
    aqua: '#f3faf7',
    aliceBlue: '#F8FCFF',
    coral: '#FFE8BD',
    creamColor: '#FFFCF6',
    darkYellow: '#c17f00',
    brandPaletteGray: '#374151',
    brandPalette: '#AEC7D7',
    cyanGreen: '#15676A',
    lightGreen: '#03543F',
    successGreen: '#DEF7EC',
    brandPaletteRed: '#FBD5D5',
    brandPaletteOrange: '#FCD9BD',
    denseGray: '#d0e4f0',
    powderBlue: '#D0E4F0',
    cream: '#fff8eb',
    sereneSky: '#AEC7D7',
    aquaBlue: '#8cabbe',
    GlacierMist: '#EBFEFF',
    deepNavy: '#0F222E',
    babyBlue: '#ebf4f7',
    lightYellow: '#ffdd99',
    grayishBlue: '#dddee4',
    cyan: '#cdf4f2',
    peachPuff: '#ffeee6',
  },

  fontSize: new Map([
    ['3xs', 'clamp(0.64rem, calc(0.58rem + 0.06vw), 0.76rem)'],
    ['2xs', 'clamp(0.70rem, calc(0.67rem + 0.12vw), 0.81rem)'],
    ['xs', 'clamp(0.76rem, calc(0.75rem + 0.06vw), 0.85rem)'],
    ['s', 'clamp(0.88rem, calc(0.83rem + 0.21vw), 1.17rem)'],
    ['m', 'clamp(1.01rem, calc(0.92rem + 0.43vw), 1.61rem)'],
    ['l', 'clamp(1.16rem, calc(1.00rem + 0.76vw), 2.22rem)'],
    ['xl', 'clamp(1.33rem, calc(1.08rem + 1.24vw), 3.07rem)'],
    ['2xl', 'clamp(1.53rem, calc(1.14rem + 1.93vw), 4.23rem)'],
    ['3xl', 'clamp(1.76rem, calc(1.18rem + 2.91vw), 5.84rem)'],
    ['4xl', 'clamp(2.02rem, calc(1.16rem + 4.31vw), 8.06rem)'],
    ['5xl', 'clamp(2.33rem, calc(1.07rem + 6.28vw), 11.12rem)'],
  ]),

  space,

  fontFamily: {
    firstFont: 'Lato',
    secondFont: 'Lato',
    tertiaryFont: 'Lato',
    lato: 'Lato',
    seasons: 'the-seasons',
  },
};
