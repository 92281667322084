type ArrowRightIconProps = {
  fill: string;
};

const ArrowRightIcon = ({ fill }: ArrowRightIconProps) => (
  <svg width='12' height='17' viewBox='0 0 12 17' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.93994 13.7788L10.2866 9.43208C10.7999 8.91875 10.7999 8.07875 10.2866 7.56542L5.93994 3.21875'
      stroke={fill}
      stroke-width='1.5'
      stroke-miterlimit='10'
      stroke-linecap='round'
      stroke-linejoin='round'
    />
  </svg>
);

export default ArrowRightIcon;
