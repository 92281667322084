import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';

import Headerbar from '@src/components/Headerbar';
import Sidebar from '@src/components/Sidebar';
import { getPathTitle, UiRoutes } from '@src/lib/constants';
import { toggleSidebar } from '@src/redux/action/sidebar';
import { ReduxProps, SideBarStateProps } from '@src/redux/type';

import { StyledContainer, StyledContent, StyledHeader, StyledSidebar } from './style';

// This is a placeholder function for your actual authentication check

const PrivateRoute = ({ component: Component }: any) => {
  const dispatch = useDispatch();

  const location = useLocation();

  // Get the current path from the location object
  const currentPath = location.pathname;

  const isSidebarOpen = useSelector((state: { sidebarReducer: SideBarStateProps }) => state?.sidebarReducer?.isOpen);

  const { isUserAuthenticated } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  const onToggleClick = () => {
    dispatch(toggleSidebar());
  };

  // If the user is authenticated, render the component
  // Otherwise, redirect to the login page
  return isUserAuthenticated ? (
    <StyledContainer>
      {!isSidebarOpen && (
        <StyledSidebar>
          <Sidebar />
        </StyledSidebar>
      )}
      <StyledContent>
        <StyledHeader>
          <Headerbar toggleSidebar={onToggleClick} headerMenuItem={getPathTitle(currentPath)} />
        </StyledHeader>
        <div>
          <Component />
        </div>
      </StyledContent>
    </StyledContainer>
  ) : (
    <Navigate to={UiRoutes.LOGIN} replace />
  );
};

export default PrivateRoute;
