/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { debounce } from 'lodash';

import { styled, Tab, tabClasses, Tabs } from '@mui/material';

import { createBooking, updateBookingById } from '@src/api/booking';
import { getEventById } from '@src/api/event';
import { saveUser } from '@src/api/login';
import { createPackagesEvent } from '@src/api/package';
import { getWeeklyMenu } from '@src/api/weeklyMenu';
import ChooseMealHelmet from '@src/components/ChooseMealHelmet';
import DeleteModal from '@src/components/DeleteModal';
import MaxWidthContainer from '@src/components/MaxWidthContainer/MaxWidthContainer';
import CartModal from '@src/components/Modals/CartModal';
import OtpVerificationModal from '@src/components/Modals/OtpVerificationModal';
import SaveProgressModal from '@src/components/Modals/SaveProgressModal';
import OrderFooter from '@src/components/OrderFooter';
import OrderList from '@src/components/OrderList';
import useAuthentication from '@src/hooks/useAuthentication';
import { useFetchEventData } from '@src/hooks/usefetchEventData';
import { useFetchSettings } from '@src/hooks/useFetchSettings';
import { useIsMobile } from '@src/hooks/useIsMobile';
import useMediaQuery from '@src/hooks/useMediaQuery';
import {
  BUFFET,
  dateFormatter,
  DEFAULT_SERVICE_TYPE,
  DROP_OFF,
  getErrorMessage,
  isAddonCategory,
  statusCodes,
  UiRoutes,
  WORDPRESS_BASE_URL,
} from '@src/lib/constants';
import { getUpdateBody } from '@src/lib/getUpdate';
import {
  capitalizeFirstLetterOnly,
  formatNumber,
  getDishPrice,
  getDishQuantity,
  getMealKeyFromIndex,
  getMealTypeIndex,
  getTotalAmount,
  isElementInViewport,
} from '@src/lib/helper';
import { dropoffHeaderIcon } from '@src/lib/imgUrl';
import { Category } from '@src/model/Category';
import { AddOn, Booking, CreateBookingProps, Dish, Event } from '@src/model/Event';
import { CreatePackagesEventParams } from '@src/model/Package';
import { Settings } from '@src/model/Setting';
import { ReqBodyInterface } from '@src/model/user';
import { MealType, WeeklyAddonPayload, WeeklyDish, WeeklyDishPayload, WeeklyMenuOrder } from '@src/model/WeeklyMenu';
import { clearBookingAction, updateBookingAction } from '@src/redux/action/bookingDetails';
import { createEventAction, updateEventAction } from '@src/redux/action/eventDetails';
import { inOrderJourney } from '@src/redux/action/journeyStatus';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';
import { StyledMainWrapper } from '@src/styles/mainLayoutWrapper';
import { theme } from '@src/styles/theme';

import { StyledColumnOne, StyledContentWrapper } from '../OrderDetailScreen/styles';

import { DropoffFoodContainer } from './DropoffFoodContainer';
import { BreadCrumbContainer, BreadCrumbText, StyledCenteredFlexColumn, StyledColumnTwo, StyledHelmet } from './styles';

const DropOffChooseMeal = () => {
  const params = useSearchParams();
  const selectedDate = params[0].get('date') ?? new Date().toISOString();
  const selectedCategoryParam = params[0].get('selectedCategory'); //

  const _eventId = params[0].get('eventId');

  const [isSaveForLater, setSaveForLater] = useState<boolean>(false);
  const [weeklyMenu, setWeeklyMenu] = useState<WeeklyMenuOrder>();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [totalAmount, setTotalAmount] = useState<number | string>('0');
  const [allDishes, setAllDishes] = useState<Record<string, Record<string, WeeklyDish[]>>>({});
  const [category, setCategory] = useState<Record<MealType, Category[]>>();
  const [searchText, setSearchText] = useState<string>('');
  const [eventId, setEventId] = useState<string>('');
  const [isNextDisable, setIsNextDisable] = useState(false);
  const [isCartModalOpen, setIsCartModalOpen] = useState(false);

  const [selectedMealType, setSelectedMealType] = useState<number>(getMealTypeIndex({ type: 'lunch' }));
  const [selectedSubCategory, setSelectedSubCategory] = useState<number>(0);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobile = useIsMobile();

  const { data: settings } = useFetchSettings<Settings>({});

  const {
    setIsLoginModalOpen,
    isLoginModalOpen,
    isOtpModalOpen,
    otpModalProps,
    headingOutModalProps,
    loginModalProps,
    phoneNumber: enteredPhoneNumber,
  } = useAuthentication();

  const { isUserAuthenticated, phoneNumber, email } = useSelector(
    (state: ReduxProps) => state?.loginReducer?.loginState
  );

  const defaultBooking = useSelector((state: ReduxProps) => state?.bookingDetailsReducer?.booking);

  const { fetchEventData } = useFetchEventData();

  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);

  const fetchWeeklyMenu = (id: string) => {
    getWeeklyMenu(dateFormatter(selectedDate), {
      filter: {
        eventId: id,
        service: DROP_OFF,
      },
    })
      .then(res => {
        setWeeklyMenu(res?.data?.data);
        setCategory(res?.data?.data?.categories as Record<string, Category[]>);
      })
      .catch(err => {
        console.error('Error fetching weekly menu:', err);
      });
  };

  const createDropOffEvent = async () => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      const _params = {
        emirate: 'dubai',
        numberOfPerson: 1,
        makeMyOwn: true,
        packageDetails: {
          service: 'drop-off',
          name: 'BUILD MY OWN',
        },
        contactDetails: {
          email: clientEmail,
          phoneNumber: clientPhoneNumber,
          name: {
            firstName: firstName,
            lastName: lastName,
          },
        },
        customerId: clientId,
      } as unknown as CreatePackagesEventParams;

      createPackagesEvent(_params).then(res => {
        dispatch(createEventAction(res?.data as Event));

        if (res?.data?._id && selectedDate) {
          setEventId(res?.data?._id); // ?
          fetchWeeklyMenu(res?.data?._id);
        }
      });
    } catch (error) {
      console.error(error);
      const errorContent = getErrorMessage(error);
      console.error('Error creating drop-off event:', errorContent);
      // setErrorMessage(errorContent);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  useEffect(() => {
    dispatch(inOrderJourney());
  }, []);

  useEffect(() => {
    if (!_eventId) {
      createDropOffEvent();
      return;
    } else {
      fetchEventData({
        id: _eventId,
        skipErrorMessage: true,
        onError: () => {
          console.log('Event Fetch Failed');
          // setEventFetchFailed(true);
        },
        params: {
          options: {
            eventCategories: true,
          },
        },
      }).then(() => {
        fetchWeeklyMenu(_eventId);
      });
    }

    return () => {
      if (countdownIntervalRef.current) {
        clearInterval(countdownIntervalRef.current);
      }
    };
  }, []);

  useEffect(() => {
    return () => {
      dispatch(clearBookingAction());
    };
  }, []);

  const {
    Bookings,
    _id,
    makeMyOwn,
    numberOfPerson,
    packageDetails: packageData,
    eventStatus,
    startDate: eventStartDate,
  } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const {
    company,
    email: clientEmail,
    firstName,
    lastName,
    phoneNumber: clientPhoneNumber,
    _id: clientId,
  } = useSelector((state: ReduxProps) => state?.clientDetailsReducer);

  const dropoffEditMode = packageData.service === DROP_OFF && !!_eventId;

  const [updatedBooking, setUpdatedBooking] = useState<Booking>({} as Booking);

  useEffect(() => {
    if (Bookings && Bookings?.length > 0) {
      const matchingBooking = Bookings.find(booking => booking.eventId === _eventId);
      matchingBooking && setUpdatedBooking({ ...matchingBooking });
    }
  }, [Bookings, _eventId]);

  const serviceTypeForSetting = packageData.service === BUFFET ? BUFFET : 'drop_off';

  const serviceType = packageData?.service ?? DEFAULT_SERVICE_TYPE;

  const getMealLabel = (meal: MealType) => {
    switch (meal) {
      case 'break':
        return 'Break';
      case 'breakfast':
        return 'Breakfast';
      case 'lunch':
        return 'Lunch/Dinner';
      default:
        return 'Breakfast';
    }
  };

  const getDishDetails = useCallback(
    (id: string) => {
      return Bookings?.find(item => item?._id === eventId)?.dishes?.find(defaultDish => defaultDish?.dishId === id);
    },
    [Bookings]
  );

  const getCurrentCategory = useCallback(() => {
    if (category) {
      return Object.values(category)[selectedMealType]?.[selectedSubCategory];
    }
  }, [category, selectedMealType, selectedSubCategory]);

  const refetchAndSaveEvent = async (id: string) => {
    if (_id) {
      const response = await getEventById({}, _id);

      if (response.status === statusCodes.API_SUCCESS_CODE) {
        const result = response?.data;
        result && dispatch(updateEventAction(result));
      }
    }

    setEventId(id);
  };

  const handleBookingUpdate = async () => {
    const loaderRequest = `unique_${Date.now()}`;
    const bookingId = eventId;
    setIsNextDisable(true);

    const selectedBooking = dropoffEditMode
      ? defaultBooking?.length > 0
        ? defaultBooking[0]
        : updatedBooking
      : defaultBooking?.length > 0
      ? defaultBooking[0]
      : ({} as Booking);

    const dishes: WeeklyDishPayload[] = [];
    const addOns: WeeklyAddonPayload[] = [];

    try {
      dispatch(startLoaderAction(loaderRequest));
      selectedBooking?.addOns?.forEach(addon => {
        addOns.push({
          category: addon?.category?._id as string,
          includedInPackage: makeMyOwn ? false : !!addon?.includedInPackage,
          itemCount: addon?.itemCount || 1,
          name: addon?.name,
          price: addon?.price,
          quantity: addon?.quantity,
          addOnId: addon?.addOnId,
        });
      });
      selectedBooking?.dishes?.forEach(dish => {
        dishes.push({
          category: dish?.category?._id as string,
          includedInPackage: makeMyOwn ? false : !!dish?.includedInPackage,
          itemCount: dish?.itemCount || (1 as number),
          name: dish?.name,
          dishId: dish?.dishId,
          price: getDishPrice(dish, serviceType),
          quantity: getDishQuantity(dish, serviceType),
        });
      });

      const newBooking = {
        eventId: _id,
        dishes,
        bookingDate: dateFormatter(eventStartDate || new Date().toISOString()),
        extra: false,
        packageDetails: {
          name: 'BUILD MY OWN',
          packageId: makeMyOwn ? undefined : packageData?.packageId,
          service: packageData?.service,
          type: 'lunch',
        },
        makeMyOwn: makeMyOwn,
      } as CreateBookingProps;

      if (!dropoffEditMode) {
        return await createBooking(newBooking)
          .then(async res => {
            dispatch(updateBookingAction([...(defaultBooking as Booking[])]));
            await refetchAndSaveEvent(res?._id);
          })
          .catch(error => error);
      } else {
        return await updateBookingById(
          updatedBooking._id as string,
          getUpdateBody({ setData: { dishes, ...(addOns?.length ? { addOns: addOns } : { addOns: [] }) } })
        )
          .then(async () => {
            await refetchAndSaveEvent(bookingId);
          })
          .catch(error => error);
      }
    } finally {
      setIsNextDisable(false);
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const headingOutOtpProps = {
    ...otpModalProps,
    onSave: () => {
      // todo
    },
    message: `We’ve sent a One-Time Password (OTP) to your mobile number ending with ***${enteredPhoneNumber?.slice(
      -3
    )}. Please input the OTP provided to verify your identity and proceed with saving for later.`,
  };

  const handleNext = debounce(
    useCallback(() => {
      handleBookingUpdate()
        .then(async res => {
          if (res?.name !== 'AxiosError' && (dropoffEditMode ? _eventId : eventId)) {
            navigate(UiRoutes.DELIVERY.replace(':id', dropoffEditMode ? _eventId : eventId));
          }
        })
        .catch(err => {
          console.log('error', err);
        });
    }, [navigate, handleBookingUpdate]),
    0
  );

  const handleDishItemCount = useCallback(
    ({ id, itemCount }: { itemCount: number; id: string }) => {
      // Determining the current booking data based on the mode
      const curBooking = dropoffEditMode ? updatedBooking : defaultBooking[0];

      // Updating the dishes array with the modified current dish item
      const updatedDishes = curBooking.dishes.map(dish => (dish.dishId === id ? { ...dish, itemCount } : dish));
      const filteredDishes = curBooking.dishes?.filter(({ dishId }) => dishId !== id) || [];

      // Preparing the updated booking data based on mode
      const updatedBookingData = dropoffEditMode
        ? { ...updatedBooking, dishes: itemCount <= 0 ? filteredDishes : updatedDishes }
        : { ...defaultBooking[0], dishes: itemCount <= 0 ? filteredDishes : updatedDishes };

      // Dispatching the action to update the booking in Redux
      dispatch(updateBookingAction([updatedBookingData]));

      // Updating local state if in drop-off edit mode to be in sync with Redux
      setUpdatedBooking(prev => ({
        ...prev,
        dishes: itemCount <= 0 ? filteredDishes : updatedDishes,
      }));
      // if (dropoffEditMode) {
      // }
    },
    [dispatch, dropoffEditMode, updatedBooking, defaultBooking]
  );

  const getBookingDishes = () => {
    if (dropoffEditMode) {
      return defaultBooking?.length !== 0 ? defaultBooking?.[0]?.dishes : Bookings?.[0]?.dishes || [];
    } else {
      return defaultBooking?.length > 0 ? defaultBooking?.[0]?.dishes : [];
    }
  };

  const getBookingAddons = () => {
    if (dropoffEditMode) {
      return defaultBooking?.length !== 0 ? defaultBooking?.[0]?.dishes : Bookings?.[0]?.dishes || [];
    } else {
      return defaultBooking?.length > 0 ? defaultBooking?.[0]?.dishes : [];
    }
  };

  const handleCheckbox = useCallback(
    ({ isChecked, id, selectItemCount }: { isChecked: boolean; id: string; selectItemCount?: number }) => {
      if (!weeklyMenu) return;

      const numberOfDishes = getCurrentCategory()?.allowedDishes as number;
      const currentCategory = getCurrentCategory()?.name as string;

      const isAddon = isAddonCategory(currentCategory);

      const selectedDishes = isAddon
        ? getBookingAddons().filter(addonItem => addonItem)
        : getBookingDishes().filter(dish => dish?.category?.name === currentCategory);

      const selectedMeal = category ? (Object.keys(category)[selectedMealType] as MealType) : 'lunch';

      if (isChecked) {
        const selectedDish = isAddon
          ? weeklyMenu.addOnMenu?.addOns.find(addOn => addOn?._id === id)
          : weeklyMenu?.[selectedMeal]?.dishes.find(dish => dish?._id === id);

        const includedInPackage = selectedDishes.length < numberOfDishes;

        const dish: Dish = {
          addOn: false,
          category: {
            _id: selectedDish?.category?._id,
            name: selectedDish?.category?.name,
            sequence: selectedDish?.category?.sequence,
          },
          dishId: selectedDish?._id as string,
          name: selectedDish?.name as string,
          price: selectedDish?.price as number,
          dropOffPrice: selectedDish?.dropOffPrice as number,
          quantity: selectedDish?.quantity as number,
          dropOffQuantity: selectedDish?.dropOffQuantity as number,
          sapId: '',
          subCategory: '',
          itemCount: selectItemCount || selectedDish?.itemCount,
          includedInPackage: includedInPackage,
        };

        const addon: AddOn = {
          addOnId: selectedDish?._id as string,
          category: {
            _id: selectedDish?.category?._id,
            name: selectedDish?.category?.name,
            sequence: selectedDish?.category?.sequence,
          },
          name: selectedDish?.name as string,
          price: selectedDish?.price as number,
          quantity: selectedDish?.quantity as number,
          sapId: '',
          itemCount: selectItemCount,
          subCategory: { _id: selectedDish?.subCategory?._id, name: selectedDish?.subCategory?.name },
          includedInPackage,
        };

        if (dropoffEditMode) {
          const { dishes: dishesArr } = updatedBooking; // from the server

          // loop, if found, update itemCount
          // and if not found push unto the array
          let matchFound = false;

          if (dishesArr) {
            const updatedCurrentDishes = dishesArr.map(_dish => {
              if (_dish.dishId === id) {
                matchFound = true;
                return { ..._dish, itemCount: selectItemCount }; // working
              }

              return _dish;
            });

            const newDishesArr = matchFound ? updatedCurrentDishes : [...updatedCurrentDishes, dish];

            setUpdatedBooking({ ...updatedBooking, dishes: newDishesArr });
            dispatch(updateBookingAction([{ ...updatedBooking, dishes: newDishesArr }]));
          }
        } else {
          const copyBooking: Booking = {
            eventId: _id || '',
            dishes: isAddon ? [] : [dish],
            bookingDate: dateFormatter(eventStartDate || new Date().toISOString()),
            extra: false,
            addOns: isAddon ? [addon] : [],
            numberOfPerson,
            packageDetails: {
              name: 'BUILD MY OWN',
              packageId: undefined,
              service: DROP_OFF,
              type: 'lunch',
            },
            _id: eventId,
            makeMyOwn: true,
            bookingTime: new Date().toISOString(),
          };

          dispatch(
            updateBookingAction(
              defaultBooking?.some(defBooking => {
                return defBooking._id === eventId;
              })
                ? defaultBooking?.map(item => {
                    if (item?._id === eventId) {
                      return {
                        ...item,
                        ...(isAddon
                          ? { addOns: [...(item?.addOns as AddOn[]), addon] }
                          : { dishes: [...item.dishes, dish] }),
                      };
                    } else {
                      return item;
                    }
                  })
                : [...defaultBooking, copyBooking]
            )
          );
        }
      } else {
        if (packageData.service !== DROP_OFF) {
          dispatch(
            updateBookingAction(
              defaultBooking?.map(item => {
                if (item?._id === eventId) {
                  const addOns = item?.addOns?.filter(({ addOnId }) => addOnId !== id);

                  const dishes = item.dishes?.filter(({ dishId }) => dishId !== id);

                  return {
                    ...item,
                    ...(isAddon ? { addOns: addOns?.length ? addOns : [] } : { dishes: dishes?.length ? dishes : [] }),
                  };
                } else {
                  return item;
                }
              })
            )
          );
        } else {
          // Determining the current booking data based on the mode
          const curBooking = dropoffEditMode ? updatedBooking : defaultBooking[0];

          if (!curBooking) return;

          // Filter out the removed items
          const filteredAddOns = curBooking.addOns?.filter(({ addOnId }) => addOnId !== id) || [];
          const filteredDishes = curBooking.dishes?.filter(({ dishId }) => dishId !== id) || [];

          // Dispatch the action to update the booking in Redux
          dispatch(updateBookingAction([{ ...updatedBooking, dishes: filteredDishes }]));

          // Update local state if in dropoffEditMode to stay in sync with Redux

          setUpdatedBooking({ ...updatedBooking, dishes: filteredDishes });
        }
      }
    },
    [
      weeklyMenu,
      getCurrentCategory,
      getBookingDishes,
      defaultBooking,
      eventId,
      updateBookingAction,
      getBookingAddons,
      serviceType,
      updatedBooking,
    ]
  );

  useEffect(() => {
    const handleScroll = debounce(() => {
      const sections = document.querySelectorAll('.dishScroll');
      sections.forEach(section => {
        if (isElementInViewport(section as HTMLElement)) {
          setSelectedSubCategory(() => Number(section.id.replace('dish', '')));
        }
      });
    }, 100);

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [selectedSubCategory]);

  const getSelectedCategoryIndexFromName = () => {
    return category?.[getMealKeyFromIndex(selectedMealType) as MealType].findIndex(cat => {
      return cat.name.toLowerCase() === selectedCategoryParam?.replace('+', '\b');
    });
  };

  const getTotalBookingAmount = () => {
    if (packageData.service === DROP_OFF) {
      return getTotalAmount(defaultBooking?.length > 0 ? defaultBooking : Bookings!, true);
    }

    return getTotalAmount(defaultBooking, makeMyOwn);
  };

  const bookingTotal = getTotalBookingAmount();

  const orderFooterProps = useMemo(() => {
    return {
      isDropOffEvent: true,
      amount: formatNumber(totalAmount, true),
      eventStatus: eventStatus?.name,
      nextButtonText: 'Next',
      isButton: true,
      isDisable: isNextDisable,
      handleSave: () => {
        if (isUserAuthenticated) {
          setIsModalOpen(true);
        } else {
          setSaveForLater(true);
          setIsLoginModalOpen(true);
        }
      },

      handleNext: () => (isUserAuthenticated ? handleNext() : setIsLoginModalOpen(true)),
      setCartModalOpen: setIsCartModalOpen,
    };
    // as OrderFooterProps; //todo
  }, [totalAmount, makeMyOwn, settings, handleNext]);

  useEffect(() => {
    if (selectedCategoryParam && selectedCategoryParam?.length > 0) {
      const selectedCat = getSelectedCategoryIndexFromName() ?? 0;
      setSelectedSubCategory(selectedCat);
      scrollToCategory(selectedCat);
    }
  }, [selectedCategoryParam, category]);

  const setCategoriesDishesBySubCategory = useCallback(async () => {
    const addAddonsToCategories = (addon: WeeklyDish) => {
      if (addon) {
        const categoryName = addon.category?.name;
        const subcategoryName = addon.subCategory?.name ?? 'noSub';

        if (categoryValues[categoryName]) {
          if (categoryValues[categoryName][subcategoryName]) {
            categoryValues[categoryName][subcategoryName].push(addon);
          } else {
            categoryValues[categoryName][subcategoryName] = [addon] || [];
          }
        } else {
          categoryValues[categoryName] = categoryValues[categoryName] || {};
        }
      }
    };

    const addDishesToCategories = (dish: WeeklyDish) => {
      if (dish) {
        const categoryName = dish.category?.name;
        const subcategoryName = dish.subCategory?.name ?? 'noSub';

        categoryValues[categoryName] = categoryValues[categoryName] || {};
        categoryValues[categoryName][subcategoryName] = categoryValues[categoryName][subcategoryName] || [];
        categoryValues[categoryName][subcategoryName].push(dish);
      }
    };

    const bookingDishes = getBookingDishes();
    const bookingAddons = getBookingAddons();

    const categoryValues: Record<string, Record<string, WeeklyDish[]>> = {};
    const _selectedMealType = category ? getMealKeyFromIndex(selectedMealType) : 'breakfast';

    if (weeklyMenu) {
      // const allMealDishes =
      //   [...weeklyMenu.breakfast.dishes, ...weeklyMenu.breakfast.dishes, ...weeklyMenu.breakfast.dishes] || {};

      // const uniqueAllMealDishes = allMealDishes.filter(
      //   (dish, index) => allMealDishes.findIndex(d => d.dishId === dish.dishId) === index
      // );

      // const removeDuplicates = (duplicates) =>{}

      const { dishes = [] } = weeklyMenu[_selectedMealType as MealType];

      // const addOns = weeklyMenu.addOnMenu.addOns ?? [];

      dishes.forEach(dish => {
        const searchedNameText = dish.name.toLowerCase().includes(searchText);
        const searchedDescriptionText = dish.description.toLowerCase().includes(searchText);

        if (searchedNameText ?? searchedDescriptionText) {
          addDishesToCategories(dish);
        }
      });

      // addOns?.forEach(addon => {
      //   const searchedNameText = addon?.name?.toLowerCase().includes(searchText);
      //   const searchedDescriptionText = addon?.description?.toLowerCase().includes(searchText);

      //   if (searchedNameText ?? searchedDescriptionText) {
      //     addAddonsToCategories(addon);
      //   }
      // });
    }

    setAllDishes(categoryValues);
  }, [weeklyMenu, getDishDetails, searchText]);

  const getDishItemCount = (dishItemId: string): number => {
    const isAddon = isAddonCategory(getCurrentCategory()?.name ?? '');

    const dishes = isAddon ? getBookingAddons() : getBookingDishes();

    const currentDish = isAddon
      ? (dishes as unknown as AddOn[])?.find(({ addOnId }) => addOnId === dishItemId && makeMyOwn)?.itemCount || 0
      : (dishes as Dish[])?.find(({ dishId }) => dishId === dishItemId && makeMyOwn)?.itemCount || 0;

    return currentDish;
  };

  // set dishes according to Category's SubCategory
  useEffect(() => {
    const loaderRequest = `unique_${Date.now()}`;

    if (category) {
      dispatch(startLoaderAction(loaderRequest));
      setCategoriesDishesBySubCategory().finally(() => {
        dispatch(stopLoaderAction(loaderRequest));
      });
    }
  }, [category, weeklyMenu, searchText, selectedMealType]);

  const currentBooking =
    _eventId !== null
      ? Bookings && Bookings?.find(item => item?.eventId === _eventId)
      : defaultBooking?.find(item => item?.eventId === _id);

  const scrollToCategory = (index: number) => {
    setSelectedSubCategory(index);

    document.getElementById(`dish${index}`)?.scrollIntoView({ behavior: 'smooth', block: 'start' });

    const pageNumbers = document.querySelectorAll('.dishScroll').length;

    if (index + 1 > pageNumbers) {
      setSelectedSubCategory(0);
    }
  };

  const handleHeadingOutVerify = useCallback(
    async (otp?: string) => {
      const loaderRequest = `unique_${Date.now()}`;

      try {
        dispatch(startLoaderAction(loaderRequest));
        await handleBookingUpdate();

        navigate(UiRoutes.ORDER_MANAGEMENT);

        // const headingOutData = {
        //   ...(phoneNumber && phoneNumber.length > 0 && { phoneNumber: phoneNumber || enteredPhoneNumber }),
        //   ...(!phoneNumber?.length && email && { email: email }),
        //   otp: otp,
        //   orderUrl: process.env.REACT_APP_BASE_URL + UiRoutes.ORDER_DETAILS.replace(':eventId', _id ?? ''),
        //   eventId: _id,
        // };

        // const headingOutData = {
        //   ...(firstName && { firstName: firstName || '' }),
        //   ...(phoneNumber && phoneNumber?.length > 0 && { phoneNumber: phoneNumber || enteredPhoneNumber }),
        //   ...(!phoneNumber?.length && email && { email: email }),
        //   otp: otp,
        //   orderUrl: process.env.REACT_APP_BASE_URL + UiRoutes.ORDER_DETAILS.replace(':eventId', _id ?? ''),
        //   eventId: _id,
        // };

        // const verifyResult = await saveUser(headingOutData as ReqBodyInterface);

        // if (verifyResult?.status === statusCodes?.API_SUCCESS_CODE) {
        //   if (otp) {
        //     window.location.href = WORDPRESS_BASE_URL ?? '';
        //   } else {
        //   }
        // }
      } catch (error) {
        console.log(error);
      } finally {
        dispatch(stopLoaderAction(loaderRequest));
      }
    },
    [phoneNumber, enteredPhoneNumber, handleBookingUpdate]
  );

  const BreadCrumb = ({ title, crumbs }: { title: string; crumbs: string[] }) => (
    <BreadCrumbContainer>
      <img
        src={dropoffHeaderIcon}
        alt='circle-top-up'
        style={{
          height: '30px',
          width: '30px',
          marginRight: '8px',
        }}
      />
      {/* <BreadCrumbText style={{ fontWeight: 400, marginRight: '2px' }}>
        {title}
        {crumbs.length > 0 ? <BreadCrumbText>{' / ' + crumbs.join(' / ')}</BreadCrumbText> : null}
      </BreadCrumbText> */}
      <BreadCrumbText style={{ fontWeight: 400, marginRight: '2px' }}>{title}</BreadCrumbText>
    </BreadCrumbContainer>
  );

  return (
    <StyledMainWrapper>
      <MaxWidthContainer extraStyle={{ flexDirection: 'column' }} isMarginBottom>
        <StyledTabs
          value={selectedMealType}
          onChange={() => {}}
          sx={{
            margin: 'auto',
            display: 'none',
            visibility: 'hidden',
            justifyContent: 'center',
            [`&& .${tabClasses.root}`]: {
              flex: 'initial',
            },
            '& .MuiTabs-indicatorSpan': {
              maxWidth: 82,
              width: '100%',
              backgroundColor: '#698EA5',
              borderRadius: '4px 4px 0 0',
              bottom: 0,
            },
          }}
        >
          {category &&
            Object.keys(category).map(label => {
              return (
                <StyledTab
                  sx={{
                    fontSize: '16px',
                    padding: '8px',
                    fontFamily: theme.fontFamily.lato,
                    color: theme.primaryColor.shadePurple,

                    '&.Mui-selected': {
                      color: '#698EA5',
                      fontWeight: 700,
                    },
                    '&.Mui-focusVisible': {
                      backgroundColor: '#698EA5',
                      fontWeight: 700,
                    },
                  }}
                  label={getMealLabel(label as MealType)}
                />
              );
            })}
        </StyledTabs>
        {category && packageData.service === 'drop-off' && (
          <BreadCrumb
            title={'Drop-Off Catering Menu'}
            crumbs={[capitalizeFirstLetterOnly(Object.keys(category)[selectedMealType] as MealType)]}
          />
        )}
        <StyledContentWrapper style={{ flexDirection: 'column' }}>
          <StyledTabs
            value={selectedSubCategory}
            onChange={(_, index) => {
              scrollToCategory(index);
            }}
            sx={{
              display: 'flex',
              position: 'sticky',
              top: '50px',
              zIndex: 1000,

              padding: '0 16px',
              borderRadius: '8px',
              backgroundColor: '#D7E9F0',
              '& .MuiTabs-scroller': {
                overflowX: isMobile ? 'scroll !important' : 'hidden',
              },
              '& .MuiTabs-indicatorSpan': {
                maxWidth: 82,
                width: '100%',
                backgroundColor: '#FCB326',
                borderRadius: '4px 4px 0 0',
                bottom: 0,
              },
            }}
          >
            {category &&
              Object.values(category[getMealKeyFromIndex(selectedMealType) as MealType]).map(item => {
                return (
                  <StyledTab
                    sx={{
                      fontSize: '16px',
                      padding: '8px',
                      fontFamily: theme.fontFamily.lato,
                      color: theme.primaryColor.shadePurple,
                      fontWeight: 600,
                      '&.Mui-selected': {
                        fontWeight: 700,
                      },
                      '&.Mui-focusVisible': {
                        fontWeight: 700,
                      },
                    }}
                    label={item.name}
                  />
                );
              })}
          </StyledTabs>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <StyledColumnOne $isDesktop={!isMobile} $gap='24px'>
              {isMobile ? (
                <OrderList
                  setTotalAmount={setTotalAmount}
                  isRealtimeBooking
                  settings={settings}
                  selectedBooking={eventId}
                  // invoiceMessage={invoiceMessage}
                  isScrollable
                  mobileDropoff={isMobile}
                  dropoffEditMode={dropoffEditMode}
                  // showDropOffDeliveryFee={packageData.service === DROP_OFF ? false : true}
                />
              ) : null}

              <StyledCenteredFlexColumn>
                <StyledHelmet>
                  <ChooseMealHelmet
                    setWeeklyMenu={setWeeklyMenu}
                    selectedDate={selectedDate as string}
                    activeStep={0}
                    eventId={_id as string}
                    serviceType={serviceType}
                    setSearchText={setSearchText}
                  />
                </StyledHelmet>
                <DropoffFoodContainer
                  allDishes={allDishes}
                  serviceType={serviceType}
                  mealDetails={getCurrentCategory()}
                  selectedDish={
                    isAddonCategory(getCurrentCategory()?.name ?? '')
                      ? getBookingAddons() || []
                      : getBookingDishes() || []
                  }
                  isRateUs={false}
                  getDishItemCount={getDishItemCount}
                  handleDishItemCount={handleDishItemCount}
                  handleCheckbox={handleCheckbox}
                />
              </StyledCenteredFlexColumn>
            </StyledColumnOne>
            {!isMobile ? (
              <StyledColumnTwo>
                <OrderList
                  setTotalAmount={setTotalAmount}
                  isRealtimeBooking
                  settings={settings}
                  selectedBooking={eventId}
                  mobileDropoff={isMobile}
                  // invoiceMessage={invoiceMessage}
                  isScrollable
                  dropoffEditMode={dropoffEditMode}
                  showDropOffDeliveryFee={packageData.service === DROP_OFF ? false : true}
                />
              </StyledColumnTwo>
            ) : null}
          </div>
        </StyledContentWrapper>
      </MaxWidthContainer>

      {currentBooking?.dishes?.length ? <OrderFooter {...orderFooterProps} /> : <></>}
      {isModalOpen && (
        <DeleteModal
          isOpen={isModalOpen}
          modalName={'Are you sure you want to exit? Your progress will be saved.'}
          onRequestClose={() => {
            setIsModalOpen(false);
          }}
          onSave={() => {
            handleHeadingOutVerify();
          }}
        />
      )}
      <SaveProgressModal {...(isSaveForLater ? { ...headingOutModalProps, setSaveForLater } : loginModalProps)} />
      <OtpVerificationModal {...(isSaveForLater ? { ...headingOutOtpProps, setSaveForLater } : otpModalProps)} />
      {isCartModalOpen && isMobile && (
        <CartModal
          isOpen={isCartModalOpen}
          handleClose={() => setIsCartModalOpen(false)}
          children={
            <OrderList
              setTotalAmount={setTotalAmount}
              isRealtimeBooking
              settings={settings}
              selectedBooking={eventId}
              isScrollable
              dropoffEditMode={dropoffEditMode}
              showDropOffDeliveryFee={packageData.service === DROP_OFF ? false : true}
              mobileDropoff={true}
            />
          }
        />
      )}
    </StyledMainWrapper>
  );
};

export default DropOffChooseMeal;

interface StyledTabsProps {
  children?: React.ReactNode;
  value: number;
  onChange: (event: React.SyntheticEvent, newValue: number) => void;
}

export const StyledTabs = styled((props: StyledTabsProps) => (
  <Tabs {...props} TabIndicatorProps={{ children: <span className='MuiTabs-indicatorSpan' /> }} />
))({
  '& .MuiTabs-indicator': {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    height: '8px',
    borderRadius: '4px 4px 0 0',
  },
  '& .MuiTabs-indicatorSpan': {
    maxWidth: 82,
    width: '100%',
    backgroundColor: '#3790B3',
    borderRadius: '4px 4px 0 0',
    bottom: 0,
  },
});

interface StyledTabProps {
  label: string;
}

export const StyledTab = styled((props: StyledTabProps) => <Tab disableRipple {...props} />)(() => ({
  textTransform: 'none',
  fontFamily: 'Lato',
  fontWeight: 500,
  fontSize: '20px',
  marginRight: '24px',
  padding: '16px 16px 20px 16px',
  color: '#000000',
  '&.Mui-selected': {
    color: '#3790B3',
  },
  '&.Mui-focusVisible': {
    backgroundColor: '#3790B3',
  },
}));
