/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ForkEffect, takeLatest } from 'redux-saga/effects';

import ActionConstants from '../constants/';

import workerSaga from './workerSaga';

// ------------------------------------
// Watchers
// ------------------------------------
export default function* ingredientWatcher(): Generator<ForkEffect<never>, void, unknown> {
  yield takeLatest(ActionConstants.INGREDIENT_DETAILS_ACTION, workerSaga);
}
