export const loader = `${process.env.REACT_APP_IMAGE_URL}/loader.webp`;
export const logo = `${process.env.REACT_APP_IMAGE_URL}/blastLogoMobile.svg`;
export const blastLogo = `${process.env.REACT_APP_IMAGE_URL}/blastLogo.svg`;
export const profile = `${process.env.REACT_APP_IMAGE_URL}/profile-icon.png`;
export const arrow = `${process.env.REACT_APP_IMAGE_URL}/left-arrow.svg`;
export const tripleLine = `${process.env.REACT_APP_IMAGE_URL}/menu-icon.png`;
export const exportIcon = `${process.env.REACT_APP_IMAGE_URL}/export.svg`;
export const loginLogo = `${process.env.REACT_APP_IMAGE_URL}/logo.png`;
export const actionIcon = `${process.env.REACT_APP_IMAGE_URL}/action.svg`;
export const rightArrow = `${process.env.REACT_APP_IMAGE_URL}/arrow-right.svg`;
export const textHighlight = `${process.env.REACT_APP_IMAGE_URL}/backgroundColorImageLogin.svg`;
export const loginFrameLogo = `${process.env.REACT_APP_IMAGE_URL}/login-background-image.png`;
export const addIcon = `${process.env.REACT_APP_IMAGE_URL}/add-icon.svg`;
export const minusIcon = `${process.env.REACT_APP_IMAGE_URL}/minus-icon.svg`;
export const fullBuffetImg = `${process.env.REACT_APP_IMAGE_URL}/fullService.svg`;
export const dropOffImg = `${process.env.REACT_APP_IMAGE_URL}/dropoff.svg`;
export const closeIcon = `${process.env.REACT_APP_IMAGE_URL}/close.svg`;
export const peopleOutline = `${process.env.REACT_APP_IMAGE_URL}/people-outline.svg`;
export const defaultImage = `${process.env.REACT_APP_IMAGE_URL}/default-image.png`;
export const appetizers = `${process.env.REACT_APP_IMAGE_URL}/appetizer-lightbite.svg`;
export const bakeryBites = `${process.env.REACT_APP_IMAGE_URL}/bakery-bites.svg`;
export const desserts = `${process.env.REACT_APP_IMAGE_URL}/desserts.svg`;
export const hotMain = `${process.env.REACT_APP_IMAGE_URL}/hot-main.svg`;
export const hotSide = `${process.env.REACT_APP_IMAGE_URL}/hot-side.svg`;
export const package1 = `${process.env.REACT_APP_IMAGE_URL}/package1.svg`;
export const package2 = `${process.env.REACT_APP_IMAGE_URL}/package2.svg`;
export const package3 = `${process.env.REACT_APP_IMAGE_URL}/package3.svg`;
export const dinner = `${process.env.REACT_APP_IMAGE_URL}/dinner.svg`;
export const handIcon = `${process.env.REACT_APP_IMAGE_URL}/handIcon.svg`;
export const emptyMenu = `${process.env.REACT_APP_IMAGE_URL}/emptyMenu.svg`;
export const calendarIcon = `${process.env.REACT_APP_IMAGE_URL}/calendar.svg`;
export const infoCircle = `${process.env.REACT_APP_IMAGE_URL}/infoCircle.svg`;
export const arrowUpIcon = `${process.env.REACT_APP_IMAGE_URL}/arrow-up.svg`;
export const editIcon = `${process.env.REACT_APP_IMAGE_URL}/editIcon.svg`;
export const shoppingCart = `${process.env.REACT_APP_IMAGE_URL}/shopping-cart.svg`;
export const vector = `${process.env.REACT_APP_IMAGE_URL}/vector.svg`;
export const packageBackground = `${process.env.REACT_APP_IMAGE_URL}/packageBG.svg`;
export const leftArrowIcon = `${process.env.REACT_APP_IMAGE_URL}/arrow-left.svg`;
export const trashOutlineIcon = `${process.env.REACT_APP_IMAGE_URL}/trash.svg`;
export const arrowDownIcon = `${process.env.REACT_APP_IMAGE_URL}/vector.svg`;
export const mailIcon = `${process.env.REACT_APP_IMAGE_URL}/mailIcon.svg`;
export const phoneIcon = `${process.env.REACT_APP_IMAGE_URL}/call.svg`;
export const mapPinMarker = `${process.env.REACT_APP_IMAGE_URL}/mapPinMarker.svg`;
export const deliveryIcon = `${process.env.REACT_APP_IMAGE_URL}/locationIcon.svg`;
export const infoIcon = `${process.env.REACT_APP_IMAGE_URL}/infoIcon.svg`;
export const noteIcon = `${process.env.REACT_APP_IMAGE_URL}/note.svg`;
export const userTagIcon = `${process.env.REACT_APP_IMAGE_URL}/userTag.svg`;
export const successIcon = `${process.env.REACT_APP_IMAGE_URL}/successIcon.svg`;
export const warningIcon = `${process.env.REACT_APP_IMAGE_URL}/warningIcon.svg`;
export const callDark = `${process.env.REACT_APP_IMAGE_URL}/callDark.svg`;
export const orderSuccessfulImg = `${process.env.REACT_APP_IMAGE_URL}/order-successful.svg`;
export const bgImgThankYouCard = `${process.env.REACT_APP_IMAGE_URL}/bgImgThankyouCard.svg`;
export const celeryIcon = `${process.env.REACT_APP_IMAGE_URL}/celery.svg`;
export const crustaceansIcon = `${process.env.REACT_APP_IMAGE_URL}/crustaceans.svg`;
export const eggsIcon = `${process.env.REACT_APP_IMAGE_URL}/eggs.svg`;
export const fishIcon = `${process.env.REACT_APP_IMAGE_URL}/fish.svg`;
export const lupinIcon = `${process.env.REACT_APP_IMAGE_URL}/lupin.svg`;
export const milkIcon = `${process.env.REACT_APP_IMAGE_URL}/milk.svg`;
export const molluscusIcon = `${process.env.REACT_APP_IMAGE_URL}/molluscs.svg`;
export const mustardIcon = `${process.env.REACT_APP_IMAGE_URL}/mustard.svg`;
export const nutsIcon = `${process.env.REACT_APP_IMAGE_URL}/nuts.svg`;
export const peanutsIcon = `${process.env.REACT_APP_IMAGE_URL}/peanuts.svg`;
export const sesameseedsIcon = `${process.env.REACT_APP_IMAGE_URL}/sesameseeds.svg`;
export const soyaIcon = `${process.env.REACT_APP_IMAGE_URL}/soya.svg`;
export const notFound = `${process.env.REACT_APP_IMAGE_URL}/not-found.svg`;
export const tickSquareActive = `${process.env.REACT_APP_IMAGE_URL}/tickSquareActive.svg`;
export const addingIcon = `${process.env.REACT_APP_IMAGE_URL}/addIcon.svg`;
export const subtractIcon = `${process.env.REACT_APP_IMAGE_URL}/minusIcon.svg`;
export const rightArrowIcon = `${process.env.REACT_APP_IMAGE_URL}/right-arrow.svg`;
export const mobileLogo = `${process.env.REACT_APP_IMAGE_URL}/logo-mobile.png`;
export const rateUSbg = `${process.env.REACT_APP_IMAGE_URL}/rateUSbg.svg`;
export const qrLogo = `${process.env.REACT_APP_IMAGE_URL}/qr-logo.svg`;
export const tickSquare = `${process.env.REACT_APP_IMAGE_URL}/tick-square.svg`;
export const dishCateringLogo = `${process.env.REACT_APP_IMAGE_URL}/dishcateringlogohires3.jpg`;

export const docDownload = `${process.env.REACT_APP_IMAGE_URL}/document-download.svg`;

export const dropoffHeaderIcon = `${process.env.REACT_APP_IMAGE_URL}/dropoff-header-icon.svg`;
export const emptyCartHero = `${process.env.REACT_APP_IMAGE_URL}/empty_cart_hero.svg`;
export const shoppingCartSolid = `${process.env.REACT_APP_IMAGE_URL}/shopping-cart-solid.svg`;
export const blackLocationIcon = `${process.env.REACT_APP_IMAGE_URL}/location-black.svg`;
