import { theme } from '@src/styles/theme';

const StaffIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => {
  return (
    <svg width='22' height='22' viewBox='0 0 22 22' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.5 1.83325H5.5C3.97833 1.83325 2.75 3.05242 2.75 4.55575V14.5566C2.75 16.0599 3.97833 17.2791 5.5 17.2791H6.19667C6.93 17.2791 7.62667 17.5633 8.14 18.0766L9.7075 19.6258C10.4225 20.3316 11.5867 20.3316 12.3017 19.6258L13.8692 18.0766C14.3825 17.5633 15.0883 17.2791 15.8125 17.2791H16.5C18.0217 17.2791 19.25 16.0599 19.25 14.5566V4.55575C19.25 3.05242 18.0217 1.83325 16.5 1.83325ZM11 5.27075C12.1825 5.27075 13.1358 6.22409 13.1358 7.40659C13.1358 8.58909 12.1825 9.54242 11 9.54242C9.8175 9.54242 8.86417 8.57992 8.86417 7.40659C8.86417 6.22409 9.8175 5.27075 11 5.27075ZM13.4567 13.8049H8.54333C7.80083 13.8049 7.37 12.9799 7.7825 12.3658C8.40583 11.4399 9.61583 10.8166 11 10.8166C12.3842 10.8166 13.5942 11.4399 14.2175 12.3658C14.63 12.9799 14.19 13.8049 13.4567 13.8049Z'
        fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      />
    </svg>
  );
};

export default StaffIcon;
