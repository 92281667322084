export default {
  // Loader
  START_LOADER: '@loader/START_LOADER',
  STOP_LOADER: '@loader/STOP_LOADER',
  //Login
  NEW_ACTION: 'NEW_ACTION',
  TOGGLE_SIDEBAR: 'TOGGLE_SIDEBAR',
  CLOSE_SIDEBAR: 'CLOSE_SIDEBAR',
  USER_DETAILS_ACTION: 'USER_DETAILS_ACTION',
  USER_DETAILS_SUCCESS: 'USER_DETAILS_SUCCESS',
  USER_DETAILS_FAILURE: 'USER_DETAILS_FAILURE',
  USER_DETAILS_REQUEST: 'USER_DETAILS_REQUEST',
  CLEAR_USER_DETAILS: 'CLEAR_USER_DETAILS',
  //Logout
  USER_LOGOUT_ACTION: 'USER_LOGOUT_ACTION',
  USER_LOGOUT_SUCCESS: 'USER_LOGOUT_SUCCESS',
  USER_LOGOUT_FAILURE: 'USER_LOGOUT_FAILURE',
  USER_LOGOUT_REQUEST: 'USER_LOGOUT_REQUEST',
  //Staff details
  STAFF_DETAILS_ACTION: 'STAFF_DETAILS_ACTION',
  STAFF_DETAILS_SUCCESS: 'STAFF_DETAILS_SUCCES',
  STAFF_DETAILS_FAILURE: 'STAFF_DETAILS_FAILURE',
  STAFF_DETAILS_REQUEST: 'STAFF_DETAILS_REQUEST',
  //Refresh token
  REFRESH_TOKEN_ACTION: 'REFRESH_TOKEN_ACTION',
  REFRESH_TOKEN_SUCCESS: 'REFRESH_TOKEN_SUCCESS',
  REFRESH_TOKEN_FAILURE: 'REFRESH_TOKEN_FAILURE',
  REFRESH_TOKEN_REQUEST: 'REFRESH_TOKEN_REQUEST',
  //Ingredient details
  INGREDIENT_DETAILS_ACTION: 'INGREDIENT_DETAILS_ACTION',
  INGREDIENT_DETAILS_SUCCESS: 'INGREDIENT_DETAILS_SUCCES',
  INGREDIENT_DETAILS_FAILURE: 'INGREDIENT_DETAILS_FAILURE',
  INGREDIENT_DETAILS_REQUEST: 'INGREDIENT_DETAILS_REQUEST',

  // Event Details
  CREATE_EVENT_DETAILS: 'CREATE_EVENT_DETAILS',
  UPDATE_EVENT_DETAILS: 'UPDATE_EVENT_DETAILS',
  CLEAR_EVENT_DETAILS: 'CLEAR_EVENT_DETAILS',
  ADD_MORE_BOOKING: 'ADD_MORE_BOOKING',
  DELETE_BOOKING: 'DELETE_BOOKING',
  RESET_BOOKING: 'RESET_BOOKING',

  UPDATE_INVOICE_DETAILS: 'UPDATE_INVOICE_DETAILS',
  CLEAR_INVOICE_DETAILS: 'CLEAR_INVOICE_DETAILS',

  //Order Detail Page Message
  SHOW_MESSAGE: 'SHOW_MESSAGE',

  // Client Details whose order is created
  UPDATE_CLIENT_DETAILS: 'UPDATE_CLIENT_DETAILS',
  CLEAR_CLIENT_DETAILS: 'CLEAR_CLIENT_DETAILS',

  // Booking Details
  UPDATE_BOOKING_DETAILS: 'UPDATE_BOOKING_DETAILS',
  CLEAR_BOOKING_DETAILS: 'CLEAR_BOOKING_DETAILS',

  // Journey status
  IN_ORDER_JOURNEY: 'IN_ORDER_JOURNEY',
  NOT_IN_ORDER_JOURNEY: 'NOT_IN_ORDER_JOURNEY',
  ADD_ITEM: 'ADD_ITEM',
  CLEAR_JOURNEY: 'CLEAR_JOURNEY',

  //Filter
  SET_FILTER: 'SET_FILTER',
};
