import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 30px;

  ${Breakpoints.MOBILE} {
    flex-direction: column;
    align-items: start;
  }
`;
export const StyledSearch = styled.div`
  display: flex;
  box-sizing: border-box;
  gap: 12px;
  width: 200px;
  padding: 12px;
  align-items: center;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  ${Breakpoints.MOBILE} {
    width: 100%;
  }
`;

export const StyledSearchText = styled.input`
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  font-size: 14px;
  width: 80%;
  border: none;
  &:focus {
    outline: none;
  }
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;

export const StyledCheckbox = styled.div``;

export const StyledCheckboxContent = styled.div`
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-size: 14px;
`;

export const StyledSearchBar = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  ${Breakpoints.MOBILE} {
    width: 100%;
  }
`;

export const StyledSearchInput = styled.input`
  display: flex;
  padding: 10px 15px;
  justify-content: center;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  border-radius: 12px;
  cursor: pointer;
  transition: border-color 0.3s;
  width: 22vw;
  &:focus {
    border-color: ${({ theme }) => theme.primaryColor.royalGray};
    outline: none;
  }
  ${Breakpoints.MOBILE} {
    width: 100%;
  }

  font-size: 16px;
`;

export const StyledClearButton = styled.button`
  position: absolute;
  right: 8px;
  cursor: pointer;
  background: none;
  border: none;
  font-size: 16px;
  color: ${({ theme }) => theme.primaryColor.grayish};
`;
