import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { UpdateBodyParams } from '@src/lib/getUpdate';
import { Order } from '@src/model/Order';

import apiRequest from './api';

export const getOrders = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ORDER_LIST}`,
    config: { params },
  });
};

export const getOrderDetails = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ORDER_DETAILS}/${id}`,
  });
};

export const updateOrders = (data: UpdateBodyParams, id: string) => {
  return apiRequest({
    method: 'put',
    url: `${apiEndpoints.UPDATE_ORDER}/${id}`,
    data,
  });
};

export const createOrder = (data: Order) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_ORDER}`,
    data,
  });
};

export const verifyPromo = (params: any) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.VERIFY_PROMO}`,
    config: { params: params, skipErrorMessage: true },
  });
};

export const getOrderById = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_ORDER}/${id}`,
  });
};

export const updateOrderById = (data: UpdateBodyParams, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_ORDER}/${id}`,
    data,
  });
};
