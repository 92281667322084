import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { getEventById } from '@src/api/event';
import { statusCodes } from '@src/lib/constants';
import { Params } from '@src/lib/getOptions';
import { Event } from '@src/model/Event';
import { updateEventAction } from '@src/redux/action/eventDetails';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';

interface FetchEventDataProps {
  id?: string;
  skipLoading?: boolean;
  skipErrorMessage?: boolean;
  onComplete?: (result: Event) => void;
  onError?: (error: any) => void;
  onFinally?: () => void;
  params?: Params;
}

export const useFetchEventData = () => {
  const dispatch = useDispatch();

  const fetchEventData = useCallback(
    async ({ id, skipErrorMessage, skipLoading, onComplete, onError, onFinally, params }: FetchEventDataProps) => {
      if (!id) return console.error('Event Id not found');

      const loaderRequest = `unique_${Date.now()}`;

      try {
        if (!skipLoading) dispatch(startLoaderAction(loaderRequest));

        const response = await getEventById(params ? params : {}, id, skipErrorMessage);

        if (response?.status === statusCodes?.API_SUCCESS_CODE) {
          const result = response?.data;

          if (result) {
            dispatch(updateEventAction(result));
            onComplete?.(result);
          }
        }
      } catch (error) {
        console.log(error);
        onError?.(error);
      } finally {
        if (!skipLoading) dispatch(stopLoaderAction(loaderRequest));

        onFinally?.();
      }
    },
    []
  );

  return { fetchEventData };
};
