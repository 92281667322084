import { Link } from 'react-router-dom';
import styled from 'styled-components';

export const StyledMenuList = styled.div<{ $positionBottom?: boolean }>`
  padding: 12px;
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow-y: auto;
`;

export const StyledMenuItem = styled(Link)<{ $label?: string; $isSelected?: boolean }>`
  display: block;
  color: ${({ theme }) => theme.primaryColor.gentleAzure};
  ${({ $isSelected, theme }) =>
    $isSelected &&
    `background-color: ${theme.primaryColor.pristineSnowfall};
  color: ${theme.primaryColor.midnightInk};`}
  padding: 12px;
  text-decoration: none;
  border-radius: 6px;
  font-family: Lato;
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-style: normal;
  font-weight: 400;
  margin-bottom: 12px;
  display: flex;
  align-items: center;
  margin-top: ${({ $label }) => ($label === 'Setting' ? 'auto' : '')};

  &:hover:not(.active) {
    background-color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
    color: ${({ theme }) => theme.primaryColor.midnightInk};
    padding: 12px;
    margin-bottom: 12px;
    font-family: Lato;
    font-size: ${({ theme }) => theme.fontSize.get('xs')};
    font-style: normal;
    font-weight: 500;
  }

  @media (max-width: 950px) {
    justify-content: center;
  }
`;

export const StyledImage = styled.img`
  width: unset;
  @media (max-width: 768px) {
    content: url('images/logo-mobile.png');
  }
`;

export const StyledLabel = styled.div`
  margin-left: 12px;
  @media (max-width: 950px) {
    display: none;
  }
`;

export const StyledLogo = styled.div`
  display: flex;
  margin: 18px;
  align-items: center;
  padding-top: 5px;
  padding-bottom: 16px;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;

export const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.twilightBlue};
  color: ${({ theme }) => theme.primaryColor.sereneSkyBlue};
  border-top-right-radius: 10px;
  border-bottom-right-radius: 10px;
  flex: 1;
  display: flex;
  flex-direction: column;
  width: 100%; /* Adjust the width as needed */
  margin-left: auto; /* Center the wrapper */
  margin-right: auto; /* Center the wrapper */
  height: 100vh; /* Full viewport height */
  overflow: hidden; /* Hide overflow to prevent window-level scrolling */
`;

export const Header = styled.header`
  position: sticky; /* Sticky positioning */
  top: 0; /* Stick to the top */
  z-index: 10; /* Ensure it's above other content */
`;

export const MainContent = styled.main`
  overflow-y: auto; /* Makes content scrollable */
  height: calc(100vh - 100px); /* Adjust the height based on header/footer */
  /* If the header or footer sizes change, update the above value accordingly */
`;

export const Footer = styled.footer`
  background-color: #333;
  color: #fff;
  height: 50px; /* Your footer's height */
  text-align: center;
  padding: 10px 0;
  position: sticky; /* Sticky positioning */
  bottom: 0; /* Stick to the bottom */
  z-index: 10; /* Ensure it's above other content */
`;

export const Logo = styled.div`
  display: inline-block;
  width: 100px; /* Example width */
  height: 50px; /* Example height */
  background-image: url('path-to-your-logo.png'); /* Path to your logo image */
  background-size: contain;
  background-repeat: no-repeat;
`;

export const ListContainer = styled.div`
  display: flex;
  margin: 12px;
  padding: 12px;
  align-item: center;
  border-radius: 6px;
  background: var(--Brand-Palette-Gray-50, #f9fafb);
`;
