const PromoCodeIcon = () => (
  <svg xmlns='http://www.w3.org/2000/svg' xmlSpace='preserve' width='22' height='22' viewBox='0 0 490 490'>
    <path
      d='m454.523 482.367-242.943.001-.502-451.547L248.453 9.17l30.529 22.897L324.775 9.17l129.748 91.587z'
      style={{
        fill: '#3ebfea',
      }}
    />
    <path
      d='M301.879 482.367H34.752v-381.61L164.499 9.17l137.38 91.587z'
      style={{
        fill: '#64d6ed',
      }}
    />
    <path
      d='m57.646 482.368-22.894-.001v-381.61l22.894-17.329z'
      style={{
        fill: '#fff',
      }}
    />
    <path
      d='M301.879 428.941h30.529v53.425h-30.529zM378.868 428.941h30.529v53.425h-30.529z'
      style={{
        fill: '#1d65f7',
      }}
    />
    <circle
      cx={167.955}
      cy={125.236}
      r={30.529}
      style={{
        fill: '#3ebfea',
      }}
    />
    <path
      d='m217.925 482.367.957-435.036 82.997 53.426v381.61z'
      style={{
        fill: '#3ebfea',
      }}
    />
    <path
      d='M80.545 428.941h175.541v53.425H80.545zM80.545 324.101l123.927-124.126 54.167 53.968-124.126 124.126z'
      style={{
        fill: '#1d65f7',
      }}
    />
    <path
      d='m80.545 324.101 83.954-84.089v108.071l-29.986 29.986z'
      style={{
        fill: '#3c5cfb',
      }}
    />
    <path
      d='m103.439 342.062-15.264-4.706v-30.529h15.264z'
      style={{
        fill: '#fff',
      }}
    />
    <path
      d='M80.545 428.941H202.66v53.425H80.545z'
      style={{
        fill: '#3c5cfb',
      }}
    />
    <path
      d='M85.164 433.564h18.275v44.183H85.164z'
      style={{
        fill: '#fff',
      }}
    />
    <path
      d='M81.824 227.964h22.897v15.264H81.824zM81.824 197.435h38.161v15.264H81.824zM203.94 350.079h53.425v15.264H203.94z'
      style={{
        fill: '#002aa8',
      }}
    />
    <path
      d='m269.432 253.942-12.067-12.066v-13.912h-13.913L228.187 212.7h29.178v-15.264h-44.444l-8.251-8.251-8.363 8.362v-.112H135.25v15.264h45.904l-15.376 15.375v-.111h-45.793v15.264h30.639l-15.375 15.374v-.11H81.824v15.264h38.271l-15.374 15.374v-.109H81.824v15.265h7.741L69.75 324.1l25.979 25.979H81.824v15.264h29.169l23.519 23.519 23.634-23.634v.115h30.529v-15.264h-15.38l15.38-15.38v.116h68.69V319.55h-53.541l15.264-15.264h38.276v-15.265h-23.012l35.08-35.079zm-134.92 113.335L91.335 324.1 204.67 210.769l43.177 43.173-113.335 113.335zM167.955 90.819c-18.976 0-34.419 15.439-34.419 34.419 0 18.976 15.443 34.416 34.419 34.416s34.42-15.44 34.42-34.416c0-18.98-15.444-34.419-34.42-34.419zm0 53.571c-10.561 0-19.155-8.59-19.155-19.151s8.594-19.155 19.155-19.155 19.155 8.594 19.155 19.155c0 10.56-8.594 19.151-19.155 19.151z'
      style={{
        fill: '#002aa8',
      }}
    />
    <path
      d='m322.477.294-42.854 22.787L245.022.015l-38.729 25.544L167.955 0 26.402 94.367V490H463.598V94.367L322.477.294zM188.116 474.736H88.175v-38.161h160.276v38.161h-60.335zm75.599 0V421.31H72.91v53.425H41.666v-372.2l126.289-84.193 126.289 84.193v372.2h-30.529v.001zm45.794 0v-38.161h15.264v38.161h-15.264zm61.057 0h-30.529V421.31h-30.529V94.367l-89.424-59.615 24.893-16.424 126.312 84.208-.723 372.2zm15.987 0v-38.161h14.542v38.161h-14.542zm61.781 0h-31.975V421.31h-29.806V94.367l-92.504-61.668 27.549-14.65 126.736 84.487v372.2z'
      style={{
        fill: '#002aa8',
      }}
    />
  </svg>
);

export default PromoCodeIcon;
