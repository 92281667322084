import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DeleteBodySchema } from '@src/lib/getUpdate';
import { Allergen } from '@src/model/Allergen';

import apiRequest from './api';

export const getAllergens = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ALLERGEN_LIST}`,
    config: { params },
  });
};

export const deleteAllergen = (data: DeleteBodySchema) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.DELETE_ALLERGEN}`,
    data,
  });
};

export const createAllergen = (data: Allergen) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_ALLERGEN}`,
    data,
  });
};

export const updateAllergen = (data: Allergen, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_ALLERGEN}/${id}`,
    data,
  });
};
