import { useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';

import { isAddonCategory } from '@src/lib/constants';
import {
  formatDate,
  formatsNumber,
  getBookingPackageType,
  getTotalAmount,
  groupByBookingDateAndTime,
} from '@src/lib/helper';
import { Booking } from '@src/model/Event';
import { Settings } from '@src/model/Setting';
import { ReduxProps } from '@src/redux/type';

import { OrderDetailCard } from './orderDetailsCard';
export const packagesType = ['breakfast', 'lunch', 'break'];

const useOrderDetailsScreen = ({
  handleNext,
  handleSave,
  settings,
}: {
  handleNext?: () => void;
  handleSave?: () => void;
  settings?: Settings;
}) => {
  const [emptyPackages, setEmptyPackages] = useState<Record<string, string[]>>({});
  const [isActive, setIsActive] = useState<string>('');
  const [totalAmount, setTotalAmount] = useState<number | string>('');
  const eventDetails = useSelector((state: ReduxProps) => state?.eventDetailsReducer);
  const [eventFormatData, setEventFormatData] = useState<Record<string, Booking[]>>({});
  const [makeMyOwnMinimum, setMakeMyOwnMinimum] = useState<{ date: string; total: number }>();

  const {
    startDate,
    endDate,
    Bookings: eventBookings,
    makeMyOwn: isMakeMyOwn,
    numberOfPerson,
    packageDetails,
    eventCategories,
    eventStatus,
  } = eventDetails;

  const serviceType = packageDetails?.service === 'buffet' ? 'buffet' : 'drop_off';

  const emptyCard = (type: string, date: string) => {
    if (!emptyPackages[date]?.includes(type)) {
      setEmptyPackages(prev => ({ ...prev, [date]: [...(prev[date] ? prev[date] : []), type] }));
    }

    return null;
  };

  const makeMyOwnData: Record<string, string[]> = useMemo(() => {
    const getMakeMyOwn: Record<string, string[]> = {};
    const currentDate = new Date(startDate);
    const lastDate = new Date(endDate);

    while (currentDate <= lastDate) {
      getMakeMyOwn[currentDate.toISOString()] = [];
      currentDate.setDate(currentDate.getDate() + 1);
    }

    return getMakeMyOwn;
  }, [startDate, endDate]);

  const isNextButtonDisable = () => {
    if (isMakeMyOwn) {
      return false;
    }

    if (!settings) return true;

    if (!isMakeMyOwn && eventFormatData && eventCategories) {
      return Object.entries(eventFormatData ?? {}).some(([key, value]) => {
        return Object.entries(eventCategories?.[key.split('T')[0]] ?? {}).some(([type, categoriesValue]) => {
          const bookingList = getBookingPackageType({ allBookings: value, type });

          return categoriesValue?.some(categoryValue => {
            if (isAddonCategory(categoryValue?.name)) {
              return bookingList?.some(({ addOns }) => {
                const flag =
                  addOns?.filter(({ includedInPackage }) => includedInPackage).length !== categoryValue?.allowedDishes;

                return flag;
              });
            } else {
              return bookingList?.some(({ dishes }) => {
                const flag =
                  dishes?.filter(
                    ({ category: dishCategory, includedInPackage }) =>
                      dishCategory?._id === categoryValue?._id && includedInPackage
                  ).length !== categoryValue?.allowedDishes;

                return flag;
              });
            }
          });
        });
      });
    }
  };

  const orderFooterProps = {
    amount: formatsNumber(totalAmount, true),
    eventStatus: eventStatus?.name,
    nextButtonText: 'Next',
    isDisable: isMakeMyOwn ? false : isNextButtonDisable() || !eventBookings?.length,
    warningMessage:
      isMakeMyOwn && makeMyOwnMinimum?.date
        ? `AED ${formatsNumber(
            (settings?.minimumAmountforMakeMyOwn?.[serviceType] || 0) - makeMyOwnMinimum?.total,
            true
          )} needed to reach the minimum order for ${formatDate(makeMyOwnMinimum.date)}.`
        : undefined,
    isButton: false,
    handleNext,
    handleSave,
  };

  const renderDetailsCard = ({
    allBookings,
    isCarousel,
    item,
  }: {
    allBookings: Booking[];
    isCarousel: boolean;
    item: string;
  }) =>
    packagesType?.map(type => {
      const bookingPackageType = getBookingPackageType({ allBookings, type: type });

      return bookingPackageType?.length
        ? bookingPackageType?.map((booking, index) => {
            const mealType = booking?.packageDetails?.type ?? '';

            return booking?.dishes?.length || booking?.addOns?.length ? (
              <OrderDetailCard
                booking={booking}
                key={booking?._id}
                isCarousel={isCarousel}
                date={item}
                label={bookingPackageType?.length > 1 ? `${mealType}(${index + 1})` : mealType}
              />
            ) : (
              emptyCard(`${type}##${booking?._id}`, item)
            );
          })
        : emptyCard(type, item);
    });

  useEffect(() => {
    setIsActive(prev =>
      Object.keys(eventFormatData).includes(prev) ? prev : Object.keys(eventFormatData)[0] || startDate
    );
  }, [eventFormatData]);

  useEffect(() => {
    setEventFormatData(groupByBookingDateAndTime(eventBookings || [], startDate, endDate));
    setEmptyPackages({});
  }, [eventBookings]);

  useEffect(() => {
    if (isMakeMyOwn && eventFormatData && settings) {
      Object.entries(eventFormatData)?.some(([key, value]) => {
        const totalCount = getTotalAmount(value, isMakeMyOwn);

        if ((settings?.minimumAmountforMakeMyOwn?.[serviceType] || 0) > totalCount) {
          setMakeMyOwnMinimum({ date: key, total: totalCount });
          return true;
        } else {
          setMakeMyOwnMinimum(undefined);
          return false;
        }
      });
    }
  }, [eventBookings, eventFormatData, settings]);

  return {
    isActive,
    setIsActive,
    emptyPackages,
    setEmptyPackages,
    eventDetails,
    eventBookings,
    isMakeMyOwn,
    eventFormatData,
    makeMyOwnData,
    numberOfPerson,
    renderDetailsCard,
    setTotalAmount,
    orderFooterProps,
  };
};

export default useOrderDetailsScreen;
