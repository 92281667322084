const ProductIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='22'
    height='22'
    fill='#884949'
    stroke='#884949'
    viewBox='0 0 372.372 372.372'
  >
    <path d='M368.712 219.925c-5.042-8.951-14.563-14.511-24.848-14.511-4.858 0-9.682 1.27-13.948 3.672l-83.024 46.756a4.502 4.502 0 0 0-2.163 2.85c-1.448 5.911-4.857 14.164-12.865 19.911-8.864 6.361-20.855 7.686-35.466 3.939a4.652 4.652 0 0 1-.252-.071L148.252 267.6a5.505 5.505 0 0 1-3.621-6.882 5.476 5.476 0 0 1 5.251-3.872c.55 0 1.101.084 1.634.249l47.645 14.794c.076.023.154.045.232.065 11.236 2.836 20.011 2.047 26.056-2.288 7.637-5.48 8.982-15.113 9.141-16.528a2.02 2.02 0 0 0 .014-.136l.005-.039.003-.044.002-.029c.909-11.878-6.756-22.846-18.24-26.089l-.211-.064c-.35-.114-35.596-11.626-58.053-18.034-2.495-.711-9.37-2.366-19.313-2.366-13.906 0-34.651 3.295-54.549 19.025L1.67 292.159a4.5 4.5 0 0 0-.758 6.215l44.712 59.06a4.508 4.508 0 0 0 3.588 1.784c.987 0 1.954-.325 2.745-.935l57.592-44.345c1.294-.995 3.029-1.37 4.619-.995l93.02 21.982c6.898 1.63 14.353.578 20.523-2.9l130.16-73.304c13.684-7.709 18.547-25.111 10.841-38.796zM316.981 13.155h-170c-5.522 0-10 4.477-10 10v45.504c0 5.523 4.478 10 10 10h3.735v96.623c0 5.523 4.477 10 10 10h142.526c5.523 0 10-4.477 10-10V78.658h3.738c5.522 0 10-4.477 10-10V23.155c.001-5.523-4.477-10-9.999-10zm-63.965 89.262h-42.072c-4.411 0-8-3.589-8-8s3.589-8 8-8h42.072c4.411 0 8 3.589 8 8s-3.589 8-8 8zm53.965-43.759h-150V33.155h150v25.503z' />
  </svg>
);

export default ProductIcon;
