import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps, DeleteBodySchema } from '@src/lib/getUpdate';
import { WeeklyMenu } from '@src/model/WeeklyMenu';

import apiRequest from './api';

export const getWeeklyMenuById = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.WEEKLY_MENU_BY_ID}/${id}`,
  });
};

export const getWeeklyMenus = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.WEEKLY_MENU_LIST}`,
    config: { params },
  });
};

export const createWeeklyMenu = (data: WeeklyMenu) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_WEEKLY_MENU}`,
    data,
  });
};

export const updateWeeklyMenu = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.WEEKLY_MENU_BY_ID}/${id}`,
    data,
  });
};

export const updateWeeklyMenuStatus = (data: DeleteBodySchema, status: boolean) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_WEEKLY_MENU_STATUS}`,
    data: { ...data, status },
  });
};

export const getWeeklyMenu = (date: string, data?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_WEEKLY_MENU_TODAY}?date="${date}"`,
    config: { params: data },
  });
};
