import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { loginOtp, verifyLoginOtp } from '@src/api/login';
import { FormValues } from '@src/components/FormComponent/types';
import { SAVE_LATER_MODAL_DESC, SAVE_LATER_MODAL_HEADING, statusCodes, UiRoutes } from '@src/lib/constants';
import { startLoaderAction, stopLoaderAction, userAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';

const useAuthentication = () => {
  const dispatch = useDispatch();

  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [isOtpModalOpen, setIsOtpModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [nextRoute, setNextRoute] = useState('');

  const [otpState, setOtpState] = useState({
    countdown: 120,
    otp: '',
    isOtpValid: false,
  });

  const countdownIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const eventDetails = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const { _id } = eventDetails;

  const handleConfirm = async (values: FormValues) => {
    // const { mobileNumber: submittedPhoneNumber } = values;
    // const loaderRequest = `unique_${Date.now()}`;
    // try {
    //   dispatch(startLoaderAction(loaderRequest));
    //   if (submittedPhoneNumber) {
    //     const result = await loginOtp({
    //       phoneNumber: submittedPhoneNumber.toString(),
    //     });
    //     if (result.status === statusCodes.API_SUCCESS_CODE) {
    //       setPhoneNumber(submittedPhoneNumber.toString());
    //       setIsOtpModalOpen(true);
    //       setIsLoginModalOpen(false);
    //       setOtpState({
    //         ...otpState,
    //         countdown: 120,
    //       });
    //       startCountdown();
    //     }
    //   }
    // } catch (error) {
    //   console.log(error);
    // } finally {
    //   dispatch(stopLoaderAction(loaderRequest));
    // }
  };

  const handleVerify = useCallback(
    async (otp: string) => {
      // const loaderRequest = `unique_${Date.now()}`;
      // try {
      //   dispatch(startLoaderAction(loaderRequest));
      //   const verifyResult = await verifyLoginOtp({ phoneNumber }, otp);
      //   if (verifyResult?.status === statusCodes?.API_SUCCESS_CODE) {
      //     dispatch(userAction());
      //     setIsOtpModalOpen(false);
      //     setNextRoute(UiRoutes.DELIVERY.split(':id')[0] + _id);
      //   }
      // } catch (error) {
      //   console.log(error);
      // } finally {
      //   dispatch(stopLoaderAction(loaderRequest));
      // }
    },
    [phoneNumber]
  );

  const handleResendClick = async () => {
    // try {
    //   clearInterval(countdownIntervalRef.current as NodeJS.Timeout);
    //   const data = await loginOtp({
    //     phoneNumber,
    //   });
    //   if (data.status === statusCodes.API_SUCCESS_CODE) {
    //     setOtpState({
    //       ...otpState,
    //       countdown: 120,
    //     });
    //     startCountdown();
    //   }
    // } catch (error) {
    //   console.log(error);
    // }
  };

  const startCountdown = () => {
    countdownIntervalRef.current = setInterval(() => {
      setOtpState(prevState => {
        const newCountdown = prevState.countdown - 1;

        if (newCountdown <= 0) {
          clearInterval(countdownIntervalRef.current as NodeJS.Timeout);
          return { ...prevState, countdown: 0 };
        }

        return { ...prevState, countdown: newCountdown };
      });
    }, 1000);
  };

  const loginModalProps = {
    isOpen: isLoginModalOpen,
    onRequestClose: () => setIsLoginModalOpen(false),
    onSave: handleConfirm,
    message:
      'Before we proceed, could you please confirm your mobile number? We’ll send you a One-Time Password (OTP) to ensure it’s really you and to help secure your transaction.',
    heading: 'Confirm Your Mobile Number',
    submitButtonText: 'Confirm',
    onSubmitClose: false,
  };

  const headingOutModalProps = {
    isOpen: isLoginModalOpen,
    onRequestClose: () => setIsLoginModalOpen(false),
    onSave: handleConfirm,
    message: SAVE_LATER_MODAL_DESC,
    heading: SAVE_LATER_MODAL_HEADING,
    submitButtonText: 'Confirm',
    onSubmitClose: false,
  };

  const otpModalProps = {
    isOpen: isOtpModalOpen,
    onRequestClose: () => setIsOtpModalOpen(false),
    onSave: handleVerify,
    message: `We’ve sent a One-Time Password (OTP) to your mobile number ending with ${phoneNumber?.slice(
      -3
    )}. Please enter the OTP below to verify your identity and continue the checkout process.`,
    heading: 'Verify Your Mobile Number',
    submitButtonText: 'Verify',
    handleResend: handleResendClick,
    otpState,
    setOtpState,
  };

  return {
    isLoginModalOpen,
    setIsLoginModalOpen,
    isOtpModalOpen,
    setIsOtpModalOpen,
    phoneNumber,
    setPhoneNumber,
    nextRoute,
    setNextRoute,
    otpState,
    setOtpState,
    dispatch,
    handleConfirm,
    handleVerify,
    handleResendClick,
    startCountdown,
    loginModalProps,
    otpModalProps,
    headingOutModalProps,
    // authenticateUser,
  };
};

export default useAuthentication;
