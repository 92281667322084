import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';

import apiRequest from './api';

export const getDishesReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_DISHES_REPORT}`,
    config: { params, isContainHeader: true },
  });
};

export const getTransactionReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_TRANSACTION_REPORT}`,
    config: { params, isContainHeader: true },
  });
};

export const getPromoCodeReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_PROMO_CODE_REPORT}`,
    config: { params, isContainHeader: true },
  });
};

export const getLogistcsReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_LOGISTICS_REPORT}`,
    config: { params, isContainHeader: true },
  });
};

export const getProductsReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_PRODUCTS_REPORT}`,
    config: { params, isContainHeader: true },
  });
};

export const getOrdersReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_ORDERS_REPORT}`,
    config: { params, isContainHeader: true },
  });
};

export const getLabelDishesReport = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_LABEL_DISHES_REPORT}`,
    config: { params, isContainHeader: true },
  });
};
