import ArrowRightIcon from '../Icons/arrowRight';
import { customRatingStar } from '../RatingStars/styles';

import { StyledRateUSBtn, StyledRateUsLinkContainer, StyledRating, StyledSpan, StyledStarContainer } from './style';

type Props = {
  onClick?: () => void;
  headerText?: string;
  rating?: number;
  textColor?: string;
  starSize?: 'small' | 'medium' | 'large';
  totalFeedbacks?: number;
  onStarsClick?: () => void;
};

const starSizeConstants = { small: '12', medium: '23', large: '32' };

function RateStarsLinkContainer({
  onClick,
  headerText,
  starSize = 'small',
  rating,
  textColor = 'white',
  totalFeedbacks,
  onStarsClick,
}: Props) {
  return (
    <StyledRateUsLinkContainer>
      <StyledStarContainer onClick={onStarsClick} color={textColor}>
        <StyledRating value={rating || 0} sx={customRatingStar(starSizeConstants[starSize])} precision={0.5} readOnly />
        {totalFeedbacks ? `(${totalFeedbacks})` : ''}
      </StyledStarContainer>
      {headerText && (
        <div>
          <StyledRateUSBtn color={textColor} onClick={onClick}>
            <span style={{ position: 'relative' }}>
              {headerText} Rating
              <StyledSpan />
            </span>{' '}
            <ArrowRightIcon fill={textColor} />
          </StyledRateUSBtn>
        </div>
      )}
    </StyledRateUsLinkContainer>
  );
}

export default RateStarsLinkContainer;
