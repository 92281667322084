import styled from 'styled-components';

import { packageBackground } from '@src/lib/imgUrl';

export const StyledMainContainer = styled.div<{ $isScrollable: boolean }>`
  padding: 16px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  gap: 12px;
  width: -webkit-fill-available;

  ${({ $isScrollable }) =>
    $isScrollable
      ? ` overflow-y: auto;
      scrollbar-width: thin;
 `
      : ''}
`;
export const StyledMakeMyOwnImg = styled.img`
  align-self: flex-start;
`;
export const StyledPackageType = styled.div`
  display: flex;
  background-image: url(${packageBackground});
  padding: 24px 24px 24px 12px;
  background-position: center;
  background-size: cover;
  border-radius: 8px;
  gap: 8px;
  background-repeat: no-repeat;
  background-color: ${({ theme }) => theme.primaryColor.babyBlue};
  font-weight: 600;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
`;

export const StyledTextContainer = styled.div`
  gap: 12px;
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const StyledHeadingContainer = styled.div`
  gap: 4px;
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
`;

export const StyledHeading = styled.div`
  font-size: 18px;
  display: flex;
  flex: 1;
  gap: 4px;
  flex-wrap: wrap;
`;

export const StyledHeadingText = styled.div<{ $hasMargin?: boolean }>`
  font-size: 12px;
  color: ${({ theme }) => theme.primaryColor.fluentGray};
  text-wrap: nowrap;
  align-self: center;
  margin-top: ${({ $hasMargin }) => ($hasMargin ? '3px' : '')};
`;

export const StyledDate = styled.div`
  font-size: 14px;
`;

export const StyledDivider = styled.div`
  height: 12px;
  background-color: ${({ theme }) => theme.primaryColor.silkenMoonlight};
  display: flex;
`;

export const StyledOrderDetailsWrapper = styled.div``;

export const StyledDateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledAmountDesc = styled.div`
  padding: 120px 0;
  text-align: center;
`;

export const StyledPackageTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledEditContainer = styled.div<{ $setDisplay: boolean }>`
  display: flex;
  width: fit-content;
  padding: 5px 10px;
  gap: 8px;
  box-shadow: 0px 4px 32px 0px rgba(3, 84, 63, 0.25);
  margin-left: auto;
  margin-right: -8px;
  margin-top: -16px;
  margin-bottom: -14px;
  z-index: 1;
  background-color: white;
  display: ${({ $setDisplay }) => ($setDisplay ? 'flex' : 'none')};
  & > div {
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${({ theme }) => theme.primaryColor.shadePurple};
  }
  border-radius: 6px;
  cursor: pointer;
`;
