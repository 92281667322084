import actionConstants from '../constants';

const initialState: Record<string, boolean> = {
  isOpen: false,
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export default function sidebarReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.TOGGLE_SIDEBAR:
      return {
        ...state,
        isOpen: !state.isOpen,
      };
    case actionConstants.CLOSE_SIDEBAR:
      return {
        ...state,
        isOpen: true,
      };
    default:
      return state;
  }
}
