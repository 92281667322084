import { FilterData } from './types';

export interface Options {
  fields?: string[];
  filter?: { key: string; value: string | boolean | string[] | boolean[] | (string | boolean)[] | null }[];
  search?: { text: string; fieldsToBeSearched: string[] };
  orderBy?: { sort: number; fieldName: string };
  limit?: number;
  page?: number;
  rating?: boolean;
}

export interface Params {
  fields?: Record<string, number>;
  filter?: FilterData;
  options?: {
    search?: {
      text: string;
      fields: string[];
    };
    eventCategories?: boolean;
    sort?: Record<string, number>;
    limit?: number;
    page?: number;
    rating?: boolean;
    forBooking?: boolean;
    bookingId?: string | null;
  };
}

export interface Person {
  firstName?: string;
  lastName?: string;
  email?: string;
  phoneNumber?: string;
}

export const getOptions = (options: Options): Params => {
  const outputJson: Params = {
    fields: {},
  };

  if (options.fields) {
    outputJson.fields = {};
    options.fields.forEach(field => {
      if (outputJson.fields != undefined) outputJson.fields[field] = 1;
    });
  }

  if (options.filter) {
    outputJson.filter = {};
    options.filter.forEach(filter => {
      if (outputJson.filter != undefined) outputJson.filter[filter.key] = filter.value;
    });
  }

  if (options?.rating !== undefined) {
    outputJson.options = {};
    outputJson.options.rating = options.rating;
  }

  if (
    options.search ||
    options.orderBy ||
    options.limit !== undefined ||
    options.page !== undefined ||
    options?.rating !== undefined
  ) {
    outputJson.options = {};

    if (options.search) {
      outputJson.options.search = {
        text: options.search.text,
        fields: options.search.fieldsToBeSearched,
      };
    }

    if (options.orderBy) {
      outputJson.options.sort = {
        [options.orderBy.fieldName]: options.orderBy.sort,
      };
    }

    if (options.limit !== undefined) {
      outputJson.options.limit = options.limit;
    }

    if (options.page !== undefined) {
      outputJson.options.page = options.page;
    }

    if (options?.rating !== undefined) {
      outputJson.options.rating = options.rating;
    }
  }

  return outputJson;
};
