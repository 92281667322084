import actionConstants from '../constants';
import { LoginStateProps } from '../type';

const loginState: LoginStateProps = {
  isLoading: false,
  isSuccess: false,
  isTokenSuccess: false,
  isTokenFailure: false,
  isFailure: false,
  isUserAuthenticated: false,
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  role: '',
  active: '',
  accessTokenExpiry: '',
};

const initialState = {
  loginState: { ...loginState },
};

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function loginReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.USER_DETAILS_REQUEST:
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isLoading: true,
          isSuccess: false,
          isFailure: false,
        },
      };
    case actionConstants.USER_DETAILS_SUCCESS: {
      const { id, firstName, lastName, email, phoneNumber, role, active } = action?.payload?.body || {};
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isUserAuthenticated: true,
          isLoading: false,
          isSuccess: true,
          isFailure: false,
          id,
          firstName,
          lastName,
          email,
          phoneNumber,
          role,
          active,
          ...action?.payload?.extra,
        },
      };
    }
    case actionConstants.USER_DETAILS_FAILURE:
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isLoading: false,
          isSuccess: false,
          isFailure: true,
          isUserAuthenticated: false,
        },
      };

    case actionConstants.CLEAR_USER_DETAILS:
      return {
        ...state,
        loginState: {
          ...initialState?.loginState,
        },
      };
    case actionConstants.USER_LOGOUT_SUCCESS:
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isUserAuthenticated: false,
        },
      };
    case actionConstants.USER_LOGOUT_FAILURE:
      return {
        ...state,
        loginState: {
          ...state.loginState,
        },
      };
    case actionConstants.USER_LOGOUT_REQUEST:
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isLoading: true,
          isSuccess: false,
          isFailure: false,
        },
      };
    case actionConstants.REFRESH_TOKEN_REQUEST:
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isLoading: true,
          isSuccess: false,
          isFailure: false,
        },
      };
    case actionConstants.REFRESH_TOKEN_SUCCESS: {
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isLoading: false,
          isSuccess: true,
          isFailure: false,
          ...action?.payload?.extra,
        },
      };
    }
    case actionConstants.REFRESH_TOKEN_FAILURE:
      return {
        ...state,
        loginState: {
          ...state.loginState,
          isLoading: false,
          isSuccess: false,
          isFailure: true,
        },
      };

    default:
      return state;
  }
}
