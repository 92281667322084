import apiEndpoints from '@src/lib/apiEndpoints';
import { ReqBodyInterface } from '@src/model/user';

import apiRequest from './api';

export const loginOtp = (email: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.LOGIN_OTP}`,
    data: { email },
  });
};

export const verifyLoginOtp = (email: string, otp: string) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.VERIFY_LOGIN_OTP}`,
    data: { email, otp },
  });
};

export const getUserDetails = () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CURRENT_USER}`,
  });
};

//@TODO function name wrong
export const userLogout = () => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.USER_LOGOUT}`,
    data: {},
  });
};

export const refreshTokens = () => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.AUTH_REFRESH_TOKEN}`,
    data: {},
  });
};

export const saveUser = (data: ReqBodyInterface) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.SAVE_USER}`,
    data,
  });
};
