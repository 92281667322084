import styled from 'styled-components';

export const StyledEmptyContainer = styled.div`
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  min-height: 498px;
`;

export const StyledImage = styled.img`
  max-width: 100%;
`;

export const StyledDescription = styled.div`
  color: ${({ theme }) => theme.primaryColor.fluentGray};
  font-size: ${props => props.theme.fontSize.get('xs')};
  text-align: center;
  margin-top: 8px;
`;
