const OrderReportIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='24'
    height='24'
    fill='#22b2b4'
    stroke='#005557'
    strokeWidth={0.002}
    viewBox='0 0 176.26 176.26'
  >
    <path d='M67.346 136.988c-10.826 0-19.635 8.808-19.635 19.633 0 10.829 8.809 19.639 19.635 19.639s19.635-8.81 19.635-19.639c-.001-10.825-8.809-19.633-19.635-19.633zm0 27.272c-4.21 0-7.635-3.427-7.635-7.639 0-4.209 3.425-7.633 7.635-7.633s7.635 3.424 7.635 7.633c-.001 4.212-3.425 7.639-7.635 7.639zM134.533 136.988c-10.826 0-19.635 8.808-19.635 19.633 0 10.829 8.809 19.639 19.635 19.639 10.828 0 19.637-8.81 19.637-19.639 0-10.825-8.809-19.633-19.637-19.633zm0 27.272c-4.21 0-7.635-3.427-7.635-7.639 0-4.209 3.425-7.633 7.635-7.633 4.211 0 7.637 3.424 7.637 7.633 0 4.212-3.426 7.639-7.637 7.639zM152.81 134.525l23.257-89.434H38.215l-6.712-25.813H.193v12h22.03l26.848 103.246H152.81zm-37.912-51.623v13.813H86.98V82.902h27.918zm-27.918-12v-13.81h27.918v13.81H86.98zm39.918 12h26.936l-3.592 13.813h-23.344V82.902zm-12 25.813v13.811H86.98v-13.811h27.918zm-39.918-12H51.639l-3.592-13.813H74.98v13.813zm-20.22 12h20.22v13.811H58.35l-3.59-13.811zm72.138 13.81v-13.811h20.224l-3.592 13.811h-16.632zm30.057-51.623h-30.057v-13.81h33.648l-3.591 13.81zM74.98 57.092v13.81H44.926l-3.591-13.81H74.98z' />
    <path d='m96.329 26.18 13.188 13.189 8.486-8.484-13.189-13.191 7.563-7.562L78.635 0l10.132 33.742z' />
  </svg>
);

export default OrderReportIcon;
