import { HeadingOut } from '../action/journeyStatus';
import actionConstants from '../constants';

const initialState: HeadingOut = {
  inOrderJourney: false,
  items: {},
};

export default function journeyStatusReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.IN_ORDER_JOURNEY:
      return {
        ...state,
        inOrderJourney: true,
      };
    case actionConstants.NOT_IN_ORDER_JOURNEY:
      return {
        ...state,
        inOrderJourney: false,
      };

    case actionConstants.ADD_ITEM: {
      const { id, item } = action.payload;
      const currentItems = state?.items?.[id] || [];

      if (currentItems?.includes(item)) {
        return state;
      }

      return {
        ...state,
        items: {
          ...state.items,
          [id]: [...currentItems, item],
        },
      };
    }
    case actionConstants.CLEAR_JOURNEY:
      return initialState;

    default:
      return state;
  }
}
