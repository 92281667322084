import { combineReducers } from 'redux';

import bookingDetailsReducer from './bookingDetails';
import clientDetailsReducer from './clientDetails';
import eventDetailsReducer from './eventDetails';
import filterDetailsReducer from './filterDetails';
import ingredientReducer from './ingredient';
import invoiceDetailsReducer from './invoiceDetails';
import journeyStatusReducer from './journey';
import loaderReducer from './loader';
import loginReducer from './login';
import sidebarReducer from './sidebar';
import staffReducer from './staff';

const reducer = combineReducers<any>({
  loginReducer,
  sidebarReducer,
  staffReducer,
  loaderReducer,
  ingredientReducer,
  eventDetailsReducer,
  invoiceDetailsReducer,
  clientDetailsReducer,
  bookingDetailsReducer,
  journeyStatusReducer,
  filterDetailsReducer,
});

export default reducer;
