import React, { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import ReactSelect, {
  components,
  DropdownIndicatorProps,
  MultiValueRemoveProps,
  OptionProps,
  Props,
} from 'react-select';
import { FormikErrors, FormikTouched, useField } from 'formik';
import { debounce } from 'lodash';
import styled from 'styled-components';

import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import { CaretDownIcon, Cross2Icon } from '@radix-ui/react-icons';

import { theme } from '@src/styles/theme';

import { StyledError, StyledSelectOuter, StyledSubHeading } from './styles';
import { FormValues, SelectOptions, SetFieldTouched } from './types';
export interface SelectProps extends Props {
  isAddIcon?: boolean;
  isClearIcon?: boolean;
  gridColumn?: string;
  gridRow?: string;
  handleCreate?: () => void;
  handleCreateWithoutModal?: (value: string) => void;
  handleSelect?: (selectData: any) => void;
  getSelectedValue?: (value: string) => void;
  setSelectedOption?: Dispatch<SetStateAction<string | undefined>>;
  disabled?: boolean;
  isError?: boolean;
  onMenuScrollToBottom?: () => void;
  setFieldTouched?: SetFieldTouched;
  errors?: FormikErrors<FormValues>;
  touched?: FormikTouched<FormValues>;
  handleInputChange?: (text: string) => void;
}

interface CustomOptionProps extends OptionProps<any> {
  isSubHeader?: boolean;
  label: string;
}

const className = 'ndp-select';

const StyledSelect = styled.div<{ $isMulti?: boolean; $gridColumn?: string; $gridRow?: string; $isError?: boolean }>`
  position: relative;
  top: 0px;
  height: fit-content;
  width: 100%;
  grid-column: ${({ $gridColumn }) => $gridColumn && $gridColumn};
  grid-row: ${({ $gridRow }) => $gridRow && $gridRow};

  .${className} {
    font-size: 16px;
    ${({ $isMulti }) => $isMulti && `margin-top: 10px;`}
  }

  .${className}__value-container, .${className}__input-container {
    padding: 0;
    margin: 0;
  }

  .${className}__control {
    border: none;
    background: transparent;
    min-height: initial;
    padding: 0 ${props => props.theme.fontSize.get('3xs')};
    ${({ $isMulti }) => !$isMulti && `height: 44px;`}
    &:hover {
      border-color: ${props => props.theme.primaryColor.lunarElegance};
    }
  }

  .${className}__control--is-focused {
    box-shadow: none;
    border: none;
  }

  .${className}__dropdown-indicator {
    padding: 0;

    svg {
      width: ${props => props.theme.fontSize.get('s')};
      height: auto;
    }
  }

  .${className}__control--menu-is-open {
    border: none;

    .${className}__dropdown-indicator {
      padding: 0;

      svg {
        fill: ${props => props.theme.primaryColor.royalGray};
      }
    }
  }

  span.${className}__indicator-separator {
    display: none;
  }

  .${className}__menu {
    border-radius: 6px;
    box-shadow: none;
    z-index: 5;
  }

  .${className}__menu-list {
    padding: 0;
    border-radius: 6px;
    border: 1px solid ${props => props.theme.primaryColor.lightGray2};
  }

  .${className}__option {
    padding: ${props => props.theme.fontSize.get('3xs')};
    padding-left: ${props => props.theme.fontSize.get('l')};
    color: ${props => props.theme.primaryColor.black};
    background-color: ${props => props.theme.primaryColor.white};
  }

  .${className}__option--is-focused {
    background-color: ${props => props.theme.primaryColor.lightGray};
    cursor: pointer;

    &:active {
      background-color: ${props => props.theme.primaryColor.royalGray};
      color: ${props => props.theme.primaryColor.white};
    }
  }

  .${className}__option--is-selected {
    background-color: ${props => props.theme.primaryColor.lightGray2};
  }

  .${className}__option--is-disabled {
    background-color: ${props => props.theme.primaryColor.lightGray};
    color: ${props => props.theme.primaryColor.lightGray2};
    cursor: not-allowed;
  }

  // multi select
  .${className}__multi-value {
    align-items: center;
    background-color: ${props => props.theme.primaryColor.royalGray};
    padding: 2px ${props => props.theme.fontSize.get('2xs')};
    border-radius: ${props => props.theme.fontSize.get('3xs')};
  }

  .${className}__multi-value__label {
    color: ${props => props.theme.primaryColor.white};
    font-size: ${props => props.theme.fontSize.get('3xs')};
    padding: 0;
    margin: 0;
  }

  .${className}__control {
    border: ${({ $isError }) =>
      $isError ? `1px solid ${theme.primaryColor.red}` : 'none'}; /* Add border when isError is true */
    background: transparent;
    min-height: initial;
    padding: 0 ${props => props.theme.fontSize.get('3xs')};
    ${({ $isMulti }) => !$isMulti && `height: 44px;`}
    &:hover {
      border-color: ${({ $isError }) =>
        $isError
          ? theme.primaryColor.red
          : theme.primaryColor.lunarElegance}; /* Adjust border color on hover based on isError */
    }
  }

  .${className}__control--is-focused {
    box-shadow: none;
    border: none;
  }

  .${className}__multi-value__remove {
    cursor: pointer;
    padding-right: 0;

    svg {
      width: ${props => props.theme.fontSize.get('2xs')};
      height: auto;
    }

    path {
      fill: ${props => props.theme.primaryColor.white};
    }

    &:hover {
      background-color: ${props => props.theme.primaryColor.royalGray};
      color: ${props => props.theme.primaryColor.white};
    }
  }
`;

const MultiValueRemove = (props: MultiValueRemoveProps) => {
  return (
    <components.MultiValueRemove {...props}>
      <Cross2Icon />
    </components.MultiValueRemove>
  );
};

const DropdownIndicator = (
  props: DropdownIndicatorProps,
  handleCreate?: () => void,
  handleCreateWithoutModal?: (value: string) => void,
  isAddIcon?: boolean,
  isClearIcon?: boolean,
  inputValue?: string,
  setInputValue?: React.Dispatch<React.SetStateAction<string>>,
  clearSelectedValue?: () => void
) => {
  return (
    <components.DropdownIndicator {...props}>
      {isAddIcon && (
        <AddIcon
          onClick={() => {
            handleCreate && handleCreate();

            if (inputValue && handleCreateWithoutModal) {
              !props?.options?.find((option: any) => option?.label === inputValue) &&
                handleCreateWithoutModal(inputValue);
              props?.selectOption({ label: inputValue, value: inputValue });
              setInputValue && setInputValue('');
            }
          }}
          style={{
            color: theme.primaryColor.blue,
            backgroundColor: theme.primaryColor.lightGray,
            borderRadius: '50%',
            padding: '2px',
            cursor: 'pointer',
          }}
        />
      )}
      {isClearIcon && (
        <ClearIcon
          onClick={() => {
            clearSelectedValue && clearSelectedValue();
          }}
          style={{
            color: theme.primaryColor.blue,
            backgroundColor: theme.primaryColor.lightGray,
            borderRadius: '50%',
            padding: '2px',
            cursor: 'pointer',
          }}
        />
      )}
      <CaretDownIcon />
    </components.DropdownIndicator>
  );
};

const CustomOption: React.FC<CustomOptionProps> = props => {
  const {
    innerProps,
    data: { label, isSubHeader },
  } = props;

  if (isSubHeader) {
    return <StyledSubHeading {...innerProps}>{label}</StyledSubHeading>;
  } else {
    return <components.Option {...props} />;
  }
};

export const Select = ({ required = true, isError = true, ...rest }: SelectProps) => {
  const [field, meta, helpers] = useField(rest?.id ?? 'select');
  const [isFocused, setIsFocused] = useState(false);
  const { setSelectedOption, disabled, setFieldTouched, errors, touched, onMenuScrollToBottom } = rest;
  const [inputValue, setInputValue] = useState('');

  const debounceRefetch = useCallback(
    debounce((text: string) => {
      rest?.handleInputChange?.(text?.trim());
    }, 700),
    []
  );

  const clearSelectedValue = () => {
    helpers.setValue(rest?.isMulti ? [] : '');
  };

  const handleSelectChange = (selectedOption: any) => {
    helpers.setValue(selectedOption);

    setInputValue('');

    if (rest?.getSelectedValue) {
      selectedOption?.value && rest.getSelectedValue(selectedOption);
    }
  };

  const handleBlur = () => {
    if (inputValue && rest?.handleCreateWithoutModal) {
      !rest?.options?.find((option: any) => option?.label === inputValue) && rest.handleCreateWithoutModal(inputValue);
      handleSelectChange({ value: inputValue });
    }

    if (setFieldTouched) {
      setFieldTouched(field.name, true);
    }

    setIsFocused(false);
  };

  const selectedValue = useMemo(
    () => getSelectedValue({ isMulti: rest?.isMulti, options: rest?.options as SelectOptions[], value: field?.value }),
    [rest?.options, rest?.isMulti, field?.value]
  );

  return (
    <StyledSelect
      $isMulti={rest?.isMulti}
      $gridColumn={rest?.gridColumn}
      $gridRow={rest?.gridRow}
      $isError={!!errors?.[field.name] && touched?.[field.name]}
    >
      <StyledSelectOuter
        $isMulti={rest?.isMulti}
        label={rest.placeholder + (required ? ' *' : '')}
        value={selectedValue ? '' : undefined}
        InputLabelProps={{
          shrink:
            isFocused || (rest.isMulti ? Array.isArray(selectedValue) && selectedValue.length > 0 : !!selectedValue),
        }}
        $isError={!!errors?.[field.name] && touched?.[field.name]}
      />
      <ReactSelect
        isClearable={false}
        classNamePrefix={className}
        className={className}
        components={{
          MultiValueRemove,
          DropdownIndicator: props =>
            DropdownIndicator(
              props,
              rest?.handleCreate,
              rest?.handleCreateWithoutModal,
              rest?.isAddIcon,
              rest?.isClearIcon,
              inputValue,
              setInputValue,
              clearSelectedValue
            ),
          Option: CustomOption,
        }}
        name={rest?.id}
        onChange={rest?.handleSelect ?? handleSelectChange}
        onInputChange={(value, actionMeta) => {
          if (actionMeta.action === 'input-change' && rest?.handleCreateWithoutModal) {
            setInputValue(value);
          }

          if (!rest.handleInputChange) return;

          debounceRefetch(value);
        }}
        onKeyDown={event => {
          if (event.key === 'Enter' && inputValue && rest?.handleCreateWithoutModal && !event.defaultPrevented) {
            event.preventDefault();
            !rest?.options?.find((option: any) => option?.label === inputValue) &&
              rest.handleCreateWithoutModal(inputValue);
            handleSelectChange({ value: inputValue });
            setInputValue('');
          }
        }}
        isOptionDisabled={(option: any) => !!option.isSubHeader}
        value={selectedValue || null}
        required={required}
        onFocus={() => setIsFocused(true)}
        onBlur={handleBlur}
        {...rest}
        placeholder={''}
        isDisabled={disabled}
        onMenuScrollToBottom={onMenuScrollToBottom}
        menuPlacement='auto'
      />
      {meta.error && meta.touched && <StyledError>{meta.error}</StyledError>}
    </StyledSelect>
  );
};

interface GetSelectedValueProps {
  value: string | SelectOptions | string[] | SelectOptions[] | undefined;
  isMulti: boolean | undefined;
  options: SelectOptions[] | undefined;
}

const getSelectedValue = ({ isMulti, options, value }: GetSelectedValueProps) => {
  if (!value) return '';

  if (isMulti) {
    const dataValue = value as string[] | SelectOptions[];

    if (!dataValue.length) return '';

    if (typeof dataValue[0] === 'string')
      return options?.filter(option => (dataValue as string[])?.includes(option?.value));

    return dataValue;
  }

  return options?.find(option => option?.value === ((value as SelectOptions)?.value || value)) || value;
};

interface CreateSelectResultDataProps {
  value: unknown;
  isMulti?: boolean;
}

export const createSelectResultData = ({ value, isMulti }: CreateSelectResultDataProps) => {
  if (isMulti) return (value as SelectOptions[] | string[])?.map(item => (item as SelectOptions)?.value || item);

  return (value as SelectOptions)?.value || value;
};
