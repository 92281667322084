import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { Logout } from '@mui/icons-material';
import { createTheme, ListItemIcon, Menu, MenuItem, ThemeProvider } from '@mui/material';

import { UiRoutes } from '@src/lib/constants';
import { tripleLine } from '@src/lib/imgUrl';
import { setFilter } from '@src/redux/action/filterDetails';
import { clearJourney } from '@src/redux/action/journeyStatus';
import { clearUserData, logoutAction } from '@src/redux/action/login';
import { LoginStateProps } from '@src/redux/type';

import {
  DefaultProfileIcon,
  StyledHeaderContainer,
  StyledMenuButton,
  StyledMenuContainer,
  StyledMenuItem,
  StyledProfileInfo,
  StyledProfileNameContainer,
  StyledUserName,
  StyledUserRole,
} from './style';

export interface HeaderProps {
  toggleSidebar?: () => void;
  headerMenuItem: string;
}

export interface LoginState {
  name: string;
  role: string;
}

const theme = createTheme({
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato',
          fontStyle: 'normal',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '19px',
          letterSpacing: '-0.01em',
          color: '#09142F',
          '&:hover': {
            background: '#F6F7FA',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginLeft: '10px',
          marginTop: '5px',
        },
      },
    },
  },
});

const Headerbar = ({ toggleSidebar, headerMenuItem }: HeaderProps) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const {
    firstName: userFirstName,
    lastName: userLastName,
    role: userRole,
  } = useSelector((state: { loginReducer: { loginState: LoginStateProps } }) => state?.loginReducer?.loginState);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    dispatch(logoutAction());
    dispatch(clearUserData());
    dispatch(clearJourney());
    dispatch(setFilter({ orders: undefined, dishes: undefined }));
    navigate(UiRoutes.LOGIN);
  };

  const capitalizeFirstLetter = (str: string) => {
    if (!str) return '';

    return str.charAt(0).toUpperCase() + str.slice(1);
  };

  const formattedFirstName = capitalizeFirstLetter(userFirstName);
  const formattedLastName = capitalizeFirstLetter(userLastName);
  const formattedUserRoles = capitalizeFirstLetter(userRole);

  return (
    <>
      <StyledHeaderContainer>
        <StyledMenuContainer>
          <StyledMenuButton onClick={toggleSidebar} src={tripleLine} alt='Hambugre Icon' />
          <StyledMenuItem>{headerMenuItem}</StyledMenuItem>
        </StyledMenuContainer>
        <StyledProfileInfo onClick={e => handleClick(e)}>
          <DefaultProfileIcon>{userFirstName?.charAt(0) ?? 'G'}</DefaultProfileIcon>
          <StyledProfileNameContainer>
            <StyledUserName>
              {`${formattedFirstName || ''} ${formattedLastName || ''}`.trim() || 'Guest'}
            </StyledUserName>
            <StyledUserRole>{formattedUserRoles}</StyledUserRole>
          </StyledProfileNameContainer>
        </StyledProfileInfo>
      </StyledHeaderContainer>
      <ThemeProvider theme={theme}>
        <Menu
          id='basic-menu'
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'basic-button',
          }}
        >
          <MenuItem onClick={() => handleLogout()}>
            <ListItemIcon>
              <Logout fontSize='small' />
            </ListItemIcon>
            Logout
          </MenuItem>
        </Menu>
      </ThemeProvider>
    </>
  );
};

export default Headerbar;
