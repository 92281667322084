const EditWhiteIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M7.73512 2.10021L2.94595 7.16937C2.76512 7.36187 2.59012 7.74104 2.55512 8.00354L2.33928 9.89354C2.26345 10.576 2.75345 11.0427 3.43012 10.926L5.30845 10.6052C5.57095 10.5585 5.93845 10.366 6.11928 10.1677L10.9085 5.09854C11.7368 4.22354 12.1101 3.22604 10.8209 2.00687C9.53762 0.799375 8.56345 1.22521 7.73512 2.10021Z'
      stroke='white'
      strokeWidth='0.875'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M6.93604 2.94531C7.18687 4.55531 8.49354 5.78615 10.1152 5.94948'
      stroke='white'
      strokeWidth='0.875'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M1.75 12.833H12.25'
      stroke='white'
      strokeWidth='0.875'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default EditWhiteIcon;
