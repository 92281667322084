import { DateTime } from 'luxon';
import moment from 'moment';
import * as yup from 'yup';

import {
  getDishesReport,
  getLogistcsReport,
  getOrdersReport,
  getProductsReport,
  getPromoCodeReport,
  getTransactionReport,
} from '@src/api/report';
import { DishSelectOption } from '@src/components/DishAccordion';
import { SelectOptions } from '@src/components/FormComponent/types';
import { Dish } from '@src/model/Dish';
import { Booking, Dish as EventDish, Service } from '@src/model/Event';
import { ResponseProps } from '@src/model/GlobalStyles';
import { Meal } from '@src/model/Menu';
import Package from '@src/model/Package';
import { Settings } from '@src/model/Setting';
import { WeeklyDish } from '@src/model/WeeklyMenu';
import { MealTimings } from '@src/screens/OrderSummary';
import { theme } from '@src/styles/theme';

import {
  BUFFET,
  DEFAULT_SERVICE_TYPE,
  DROP_OFF,
  orderSummaryStatus,
  StatusType,
  stepperStatus,
  steps,
} from './constants';
import {
  appetizers,
  bakeryBites,
  celeryIcon,
  crustaceansIcon,
  defaultImage,
  desserts,
  dinner,
  eggsIcon,
  fishIcon,
  hotMain,
  hotSide,
  lupinIcon,
  milkIcon,
  mustardIcon,
  package1,
  package2,
  package3,
  peanutsIcon,
  sesameseedsIcon,
  soyaIcon,
} from './imgUrl';
import { permissionEnum } from './types';

export const checkPermissions = (permission: permissionEnum): boolean => {
  return permission ? true : false;
};

export const filterNumeric = (input: string) => {
  return input.replace(/[^\d]/g, '');
};

export function cleanPhoneNumber(input: string) {
  if (input) {
    const cleanedInput = input.replace(/(?!^\+)\D/g, '');
    return cleanedInput.startsWith('+') ? cleanedInput : '+' + cleanedInput;
  }

  return input;
}

export function getTimeFromTimestamp(timestamp: string) {
  const date = new Date(timestamp);

  const hours = date.getUTCHours();
  const minutes = date.getUTCMinutes();

  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;

  return formattedTime;
}

export const separateDefaultDishes = (dishes?: DishSelectOption[]) => {
  const output: Meal = {
    defaultDishes: [],
    dishes: [],
  };

  if (dishes && Array.isArray(dishes)) {
    dishes?.forEach(dish => {
      if (dish?.category) {
        output?.dishes?.push(dish?.id);

        if (dish?.default) {
          output?.defaultDishes?.push(dish?.id);
        }
      }
    });
  }

  return output;
};

export function mergeDishesWithDefaults(meal: { dishes: Dish[]; defaultDishes: Dish[] }) {
  const mergedDishes = [];
  const defaultDishList = meal?.defaultDishes || [];
  const defaultDishMap = new Map(defaultDishList?.map(dish => [dish._id, dish]));

  const dishList = meal?.dishes || [];

  for (const dish of dishList) {
    mergedDishes.push({ ...dish, default: defaultDishMap?.has(dish._id) });
  }

  return mergedDishes;
}

export function obscureInput(input: string) {
  if (input.includes('@')) {
    const [username, domain] = input.split('@');

    if (username.length > 2) {
      const obscuredUsername = username.substring(0, 2) + '*'.repeat(username.length - 2);
      return `${obscuredUsername}@${domain}`;
    } else {
      return input;
    }
  } else {
    return input;
  }
}

export function capitalizeFirstLetter(name?: string) {
  if (!name) return name;

  if (name.includes('lunch')) {
    return `Lunch/Dinner${name.split('lunch')?.[1] ? name.split('lunch')?.[1] : ''}`;
  }

  if (name.includes('_')) {
    return name
      .split('_')
      .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(' ');
  }

  return name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
}
export function formatNumber(num: number | string, fix?: boolean) {
  const parsedNumber = typeof num === 'string' ? parseFloat(num) : num;

  if (isNaN(parsedNumber)) {
    return num;
  }

  let formattedNumber: string;

  if (fix) {
    formattedNumber = parsedNumber?.toFixed(2);
  } else {
    formattedNumber = parsedNumber.toString();
  }

  const parts = formattedNumber.split('.');

  if (parts) {
    parts[0] = parts?.[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formattedNumber = fix ? parts.join('.') : parts[0];

  return formattedNumber;
}

export const isAddOns = (name: string) => {
  if (name.includes('Add-On')) {
    return true;
  }

  return false;
};

export function formatDate(dateString: string | Date, dateFormat?: string): string {
  const date = moment(dateString);
  const formattedDate = date.format(dateFormat || 'D MMMM, YY');

  return formattedDate;
}

export function formatDates(startDate: string, endDate: string) {
  if (startDate === '') {
    return '';
  }

  if (startDate === undefined) {
    return '-';
  }

  function getDate(date: Date) {
    const day = new Date(date).getDate();
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const month = monthNames[date.getMonth()];
    return `${day} ${month}`;
  }

  const formattedStartDate = getDate(new Date(startDate));
  const formattedEndDate = getDate(new Date(endDate));

  const year = new Date(endDate).getFullYear().toString().slice(-2);

  if (formattedEndDate === formattedStartDate) {
    return formatDate(new Date(startDate));
  } else {
    return `${formattedStartDate} - ${formattedEndDate}, ${year}`;
  }
}

const removeSpacesAndLowerCase = (inputString: string) => {
  return inputString?.toLowerCase().replace(/\s+/g, '');
};

export const getCategoryIcon = (category: string) => {
  const categoryType = removeSpacesAndLowerCase(category);

  switch (categoryType) {
    case 'appetizer/lightbite':
      return appetizers;
    case 'bakerybite':
      return bakeryBites;
    case 'dessert':
      return desserts;
    case 'hotmain':
      return hotMain;
    case 'hotside':
      return hotSide;
    default:
      return dinner;
  }
};

export const getPackageIcon = (index: number | undefined) => {
  switch (index) {
    case 0:
      return package1;
    case 1:
      return package2;
    case 2:
      return package3;
    default:
      return defaultImage;
  }
};

export const formatNumberDate = (date: string | Date) => {
  const currentDate = new Date(date || new Date());
  const year = currentDate.getFullYear();
  const day = currentDate.getDate();
  const month = currentDate.getMonth();

  return `${year}-${month.toString().length === 1 ? `0${month + 1}` : month + 1}-${day}`;
};

export const getCategoryDish = ({ category, dishes }: { dishes: EventDish[]; category: string }) => {
  return dishes?.filter(dish => dish?.category?.name === category);
};

export const getPackageColor = (type: string) => {
  switch (type) {
    case 'breakfast':
      return {
        border: theme.primaryColor.powderBlue,
        bgColor: theme.primaryColor.aliceBlue,
        buttonColor: theme.primaryColor.shadePurple,
      };
    case 'lunch':
      return {
        border: theme.primaryColor.coral,
        bgColor: theme.primaryColor.creamColor,
        buttonColor: theme.primaryColor.darkYellow,
      };
    case 'break':
      return {
        border: theme.primaryColor.powderBlue,
        bgColor: theme.primaryColor.aqua,
        buttonColor: theme.primaryColor.cyanGreen,
      };
    default:
      return {
        border: theme.primaryColor.lunarElegance,
        bgColor: theme.primaryColor.snow,
        buttonColor: theme.primaryColor.mutedGray,
      };
  }
};

export const getBookingPackageType = ({
  allBookings,
  type,
  notType,
}: {
  type?: string;
  allBookings?: Booking[];
  notType?: string[];
}) => {
  if (type) {
    return allBookings?.filter(item => item?.packageDetails?.type === type);
  } else {
    return allBookings?.filter(item =>
      item?.packageDetails?.type ? !notType?.includes(item?.packageDetails?.type) : false
    );
  }
};

export function groupByBookingDateAndTime(
  dataArray: Booking[],
  startDate: string,
  endDate: string
): Record<string, Booking[]> {
  const groupedData: Record<string, Booking[]> = {};

  let currentDate = moment(startDate).startOf('day');
  const lastDate = moment(endDate).startOf('day');

  const eventDate = currentDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
  groupedData[eventDate] = [];

  while (currentDate.diff(lastDate) <= 0) {
    const formattedDate = currentDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';
    groupedData[formattedDate] = [];
    currentDate = currentDate.add(1, 'day');
  }

  dataArray?.forEach(item => {
    const bookingDate = moment(item.bookingDate).startOf('day');
    const formattedBookingDate = bookingDate.format('YYYY-MM-DD') + 'T00:00:00.000Z';

    if (Object.prototype.hasOwnProperty.call(groupedData, formattedBookingDate)) {
      groupedData[formattedBookingDate].push(item);
    } else if (item.packageDetails?.service === DROP_OFF) {
      groupedData[eventDate].push(item);
    }
  });

  return groupedData;
}

export function formatsNumber(num: number | string, fix?: boolean) {
  const parsedNumber = typeof num === 'string' ? parseFloat(num) : num;

  if (isNaN(parsedNumber)) {
    return num;
  }

  let formattedNumber: string;

  if (fix) {
    formattedNumber = parsedNumber?.toFixed(2);
  } else {
    formattedNumber = parsedNumber.toString();
  }

  const parts = formattedNumber?.split('.');

  if (parts) {
    parts[0] = parts?.[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  formattedNumber = fix ? parts?.join('.') : parts[0];

  return formattedNumber;
}

export function getTotalAmount(bookings: Booking[], isMakeMyOwn?: boolean) {
  if (!Array.isArray(bookings) || bookings.length === 0) {
    return 0;
  }

  const totalAmount = bookings.reduce((acc, booking) => {
    if (booking && booking?.packageDetails && Array.isArray(booking?.dishes)) {
      const serviceType = booking?.packageDetails?.service ?? DEFAULT_SERVICE_TYPE;

      const bookingAmount =
        (booking?.numberOfPerson || 0) * (booking?.packageDetails?.price || 0) +
        booking?.dishes
          .filter(dish => dish && (!dish?.includedInPackage || isMakeMyOwn))
          .reduce(
            (dishAcc, dish) =>
              dishAcc + (dish?.itemCount || 0) * ((serviceType === BUFFET ? dish?.price : dish?.dropOffPrice) || 0),
            0
          ) +
        (booking?.addOns
          ?.filter(addon => addon && (!addon?.includedInPackage || isMakeMyOwn))
          ?.reduce((addonAcc, addon) => addonAcc + ((addon?.itemCount || 0) * addon?.price || 0), 0) || 0);

      return acc + bookingAmount;
    }

    return acc;
  }, 0);

  return totalAmount;
}

export function calculatePercentage(amount: number, percentage?: number): number {
  return percentage ? (amount * percentage) / 100 : 0;
}

export const removeDuplicates = (arr: string[]) => {
  return arr.filter((value, index, self) => {
    return self.indexOf(value) === index;
  });
};

export const dateFormatter = (dateStr: string) => {
  if (!dateStr) {
    return '';
  }

  const parsedDate = moment(dateStr);
  return parsedDate?.format('YYYY-MM-DD');
};

export const getAllergensIcon = (allergens: string) => {
  const allergensType = removeSpacesAndLowerCase(allergens);

  const allergensList = [
    { name: 'celery', icon: celeryIcon },
    { name: 'bakerybite', icon: crustaceansIcon },
    { name: 'eggs', icon: eggsIcon },
    { name: 'fish', icon: fishIcon },
    { name: 'lupin', icon: lupinIcon },
    { name: 'milk', icon: milkIcon },
    { name: 'mustard', icon: mustardIcon },
    { name: 'soya', icon: soyaIcon },
    { name: 'sesameseeds', icon: sesameseedsIcon },
    { name: 'peanuts', icon: peanutsIcon },
  ];

  const getSimilarityScore = (input: string, target: string) => {
    let score = 0;

    for (const char of input) {
      if (target?.includes(char)) {
        score++;
      }
    }

    return score / target?.length;
  };

  let bestMatchIcon = '';
  let highestScore = 0;

  for (const { name, icon } of allergensList) {
    const score = getSimilarityScore(allergensType, name);

    if (score > highestScore) {
      highestScore = score;
      bestMatchIcon = icon;
    }
  }

  const SIMILARITY_THRESHOLD = 0.7;

  if (bestMatchIcon && highestScore >= SIMILARITY_THRESHOLD) {
    return bestMatchIcon;
  } else {
    return celeryIcon;
  }
};

export const getDropDownOptions = ({
  bookings,
  makeMyOwn,
  selectedValue,
  selectedDate,
}: {
  bookings: Booking[];
  makeMyOwn?: boolean;
  selectedDate?: string;
  selectedValue?: string;
}): Record<string, string>[] => {
  const dropdownOptions: Record<string, string>[] = [];
  const packagesType = ['breakfast', 'lunch', 'break'];

  packagesType.map(type => {
    const bookingByType = getBookingPackageType({ allBookings: bookings, type })?.filter(({ bookingDate }) => {
      return moment(bookingDate).isSame(selectedDate, 'day');
    });

    if (bookingByType?.length) {
      if (bookingByType?.length === 2) {
        bookingByType?.forEach(item => {
          dropdownOptions.push({
            name:
              type === 'lunch'
                ? `Lunch/Dinner(${item.extra ? 2 : 1})`
                : `${capitalizeFirstLetter(type)}(${item.extra ? 2 : 1})`,
            key: type,
            id: item?._id as string,
          });
        });
      } else {
        dropdownOptions.push({
          name: type === 'lunch' ? 'Lunch/Dinner' : (capitalizeFirstLetter(type) as string),
          key: type,
          id: bookingByType[0]._id as string,
        });
      }
    }
  });

  if (selectedValue && makeMyOwn && packagesType.includes(selectedValue)) {
    if (!dropdownOptions.some(item => item.key === selectedValue)) {
      dropdownOptions.push({
        name: selectedValue === 'lunch' ? 'Lunch/Dinner' : (capitalizeFirstLetter(selectedValue) as string),
        key: selectedValue,
        id: '',
      });
    }
  }

  return dropdownOptions;
};

export const getUniqueRecords = <T extends { _id?: string }>(
  previous: ResponseProps<T> | undefined,
  response: { data: ResponseProps<T> }
) => {
  const { data } = response || {};
  const { page, totalPages, results } = data || {};
  const existingResultIds = new Set(previous?.results?.map(result => result._id));

  const updatedResults = results
    ? [...(previous?.results || []), ...results.filter(newResult => !existingResultIds.has(newResult._id))]
    : previous?.results || [];

  return {
    page: page ?? previous?.page,
    totalPages: totalPages ?? previous?.totalPages,
    results: updatedResults,
  };
};

export const checkEditableOrder = (orderDetails: string) => {
  if (orderDetails && orderSummaryStatus.includes(orderDetails as StatusType)) {
    return false;
  }

  return true;
};

export const preventInvalidChars = (e: React.KeyboardEvent<HTMLDivElement>) => {
  if (['e', 'E', '-', '+'].includes(e.key)) {
    e.preventDefault();
  }
};

export const getSelectValidation = (
  label: string,
  isValueNumber?: boolean,
  requiredMessage?: string,
  required?: boolean
) => {
  let validate = yup.mixed().label(label);

  if (required || required === undefined) {
    validate = validate.required(label + ' is required');
  }

  return validate.test('is-string-or-object', requiredMessage || label + ' is required', value => {
    if (value === undefined && required === false) return true;

    if (typeof value === 'string') return true;

    return (
      typeof value === 'object' &&
      value !== null &&
      typeof (value as SelectOptions).label === 'string' &&
      typeof (value as SelectOptions).value === (isValueNumber ? 'number' : 'string')
    );
  });
};

export function formattingDecimal(number: number): string {
  let formattedNumber = 'AED ' + parseFloat(number?.toFixed(2)).toLocaleString('en-US');

  if (!/\./.test(formattedNumber)) {
    formattedNumber += '.00';
  }

  return formattedNumber;
}

export const transformTimeTo12HourClock = (time: string) => {
  const newTime = moment(time, ['HH:mm']).format('hh:mm A');
  return newTime;
};

export const getUnsetData = ({ values, keys }: { values: Record<string, unknown>; keys: string[] }) => {
  const result: Record<string, number> = {};
  keys.forEach(item => {
    if ([undefined, ''].includes(values?.[item] as any)) result[item] = 1;
  });

  return result;
};

export const downloadReport = (response: {
  headers: {
    'content-disposition': string;
    'content-type': string;
  };
  data: BlobPart;
}) => {
  const contentComposition = response && response?.headers?.['content-disposition'];
  const reportArr = contentComposition?.match(/filename="(.+)"/);
  const url = window.URL.createObjectURL(new Blob([response.data]));
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${reportArr?.[1]}`);
  document.body.appendChild(link);
  link.click();

  if (link.parentNode) {
    link.parentNode.removeChild(link);
  }

  window.URL.revokeObjectURL(url);
};

export const getDeliveryCharges = (location: string, settings?: Settings) => {
  const deliveryCharge = settings?.deliveryCharges?.find(item => item?.emirate === location.toLowerCase())?.charges;
  return deliveryCharge || 0;
};

export const getApiFunction = (reportId: number) => {
  switch (reportId) {
    case 1:
      return getOrdersReport;
    case 2:
      return getTransactionReport;
    case 3:
      return getPromoCodeReport;
    case 4:
      return getProductsReport;
    case 5:
      return getDishesReport;
    case 6:
      return getLogistcsReport;
    default:
      throw new Error('Invalid report ID');
  }
};

export const getReportMessage = (reportId: number) => {
  switch (reportId) {
    case 1:
      return 'Select the date, download your order report!';
    case 2:
      return 'Select the date, download your transaction report!';
    case 3:
      return 'Download your promo code master report!';
    case 4:
      return 'Download your product master report!';
    case 5:
      return 'Select the date, download your consolidated kitchen report!';
    case 6:
      return 'Select the date, download your logistics report!';
    default:
      return 'Download Your Report';
  }
};

export const mealTimings = (Bookings?: Booking[]): MealTimings => {
  const timeOfFood: MealTimings = {};
  const extraItems: { [key: string]: number } = {};

  Bookings?.forEach(items => {
    const packageType = items?.packageDetails?.type;
    const isExtra = items?.extra;

    if (packageType) {
      if (isExtra) {
        extraItems[packageType] = 2;
        timeOfFood[`${packageType}(${extraItems[packageType]})`] = moment(items?.bookingTime, ['HH:mm']).format(
          'hh:mm A'
        );
      } else if (!timeOfFood[packageType]) {
        timeOfFood[packageType] = moment(items?.bookingTime, ['HH:mm']).format('hh:mm A');
      }
    }
  });

  Object.keys(extraItems)?.forEach(key => {
    if (timeOfFood[key]) {
      const newKey = `${key}(1)`;
      timeOfFood[newKey] = timeOfFood[key];
      const extraValue = timeOfFood[`${key}(2)`];
      delete timeOfFood[`${key}(2)`];
      delete timeOfFood[key];
      timeOfFood[`${key}(2)`] = extraValue;
    }
  });

  return timeOfFood;
};
export const getMealType = (mealType: string) => {
  switch (mealType) {
    case 'break':
      return 'Break';
    case 'lunch':
      return 'Lunch/Dinner';
    case 'breakfast':
      return 'Breakfast';
    default:
      return '';
  }
};

export const checkEditableOrders = (orderDetails: string) => {
  if (orderDetails && orderSummaryStatus.includes(orderDetails as StatusType)) {
    return false;
  }

  return true;
};

export const getMinimumCount = (packageData?: Package[]) => {
  const result: (number | null)[] = [null, null];

  if (!packageData) return result;

  for (const data of packageData) {
    if (!data.minimumValue) continue;

    if (result[0] === null && data.service === BUFFET) {
      result[0] = data.minimumValue;
    }

    if (result[1] === null && data.service === DROP_OFF) {
      result[1] = data.minimumValue;
    }
  }

  return result;
};

export const getDishPrice = (dish: EventDish | WeeklyDish, serviceType: Service) => {
  return (serviceType === BUFFET || dish?.addOn ? dish?.price : dish?.dropOffPrice) ?? 0;
};

export const getDishQuantity = (dish: EventDish, serviceType: Service) => {
  return (serviceType === BUFFET ? dish?.quantity : dish?.dropOffQuantity) ?? 0;
};

// for scroll event
export const debounce = (func: (...args: any[]) => void, wait: number) => {
  let timeout: NodeJS.Timeout;
  return function executedFunction(...args: any[]) {
    const later = () => {
      clearTimeout(timeout);
      func(...args);
    };

    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
  };
};

export const isElementInViewport = (el: HTMLElement) => {
  const rect = el.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export const getMealTypeIndex = ({ type }: { type: string }) => {
  switch (type) {
    case 'breakfast':
      return 0;
    case 'lunch':
      return 1;
    case 'break':
      return 2;
    // case 'all':
    //   return 0;
    // case 'beverages':
    //   return 3;
    default:
      return 0;
  }
};

export const getMealKeyFromIndex = (index: number) => {
  switch (index) {
    case 0:
      return 'breakfast';
    case 1:
      return 'lunch';

    case 2:
      return 'break';
    case 3:
      return 'beverages';
    // case 4:
    default:
      return '';
  }
};

export const capitalizeFirstLetterOnly = (name?: string) => {
  if (!name) return '';

  return name.charAt(0).toUpperCase() + name.slice(1);
};

export const getSteps = (
  paymentStatus: string,
  numberOfPerson: number,
  isPaymentRequired: boolean,
  eventStatus: string,
  isContract?: boolean
) => {
  if (stepperStatus?.includes(eventStatus)) {
    return steps.slice(0, 3);
  }

  if (!isPaymentRequired) {
    return [...steps.slice(0, 3), steps[4]];
  }

  if ((numberOfPerson > 150 || isContract) && !isPaymentRequired) {
    return [...steps.slice(0, 3), steps[4]];
  }

  return steps;
};

/**
 *
 * @param days
 * @param addDays This is only for testing purposes
 * @returns
 */
export const getThresholdDays = (days: number, addDays = 0, startDate?: string) => {
  let newThreshold = days + addDays;

  const currentUTCTime = startDate ? new Date(startDate).toISOString() : new Date().toISOString();

  const currentDubaiTime = DateTime.fromISO(currentUTCTime, {
    zone: 'Asia/Dubai',
  });

  const startDateDubai = currentDubaiTime.plus({ days: addDays });

  const isAfterTwo = startDateDubai.hour >= 14;

  switch (startDateDubai.weekday) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 7:
      newThreshold = isAfterTwo ? newThreshold + 1 : newThreshold;
      break;
    case 5:
      newThreshold = isAfterTwo ? newThreshold + 2 : newThreshold;
      break;
    case 6:
      newThreshold += 1;
      break;
    default:
      break;
  }

  return newThreshold;
};
