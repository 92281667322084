import { useNavigate } from 'react-router-dom';

import { useIsMobile } from '@src/hooks/useIsMobile';
import { leftArrowIcon, shoppingCartSolid } from '@src/lib/imgUrl';

import InvoiceMessage from '../InvoiceMessage';
import MaxWidthContainer from '../MaxWidthContainer/MaxWidthContainer';

import {
  StyledAmountContainer,
  StyledButton,
  StyledButtonsContainer,
  StyledCaret,
  StyledCartIcon,
  StyledCartWrapper,
  StyledContainer,
  StyledFigureContainer,
  StyledImage,
  StyledLeftContainer,
  StyledRightContainer,
  StyledTotalAmountContainer,
  StyledWrapper,
} from './style';

export interface OrderFooterProps {
  isDropOffEvent?: boolean;
  amount?: number | string;
  backButtonText?: string;
  nextButtonText: string;
  isButton: boolean;
  isDisable?: boolean;
  nextButtonId?: string;
  nextButtonType?: 'button' | 'submit' | 'reset';
  handleSave?: () => void;
  handleNext?: () => void;
  warningMessage?: string;
  showActionButtons?: boolean;
  handleCustomBack?: () => void;
  eventStatus?: string;
  setCartModalOpen?: any;
}

const OrderFooter = (props: OrderFooterProps) => {
  const {
    isDropOffEvent,
    amount,
    backButtonText,
    nextButtonText,
    isButton,
    isDisable = false,
    handleSave,
    handleNext,
    nextButtonId,
    nextButtonType,
    warningMessage,
    showActionButtons = true,
    handleCustomBack,
    eventStatus = 'DRAFT',
    setCartModalOpen,
  } = props;

  const navigate = useNavigate();
  const isMobile = useIsMobile();

  const handleBack = () => {
    navigate(-1);
  };

  const showSaveForLaterStatus = ['DRAFT', 'ACTION_REQUIRED', 'QUOTE', 'DEFAULT'];

  return (
    <StyledContainer>
      <MaxWidthContainer extraStyle={{ flexDirection: 'column' }}>
        <StyledButtonsContainer>
          <StyledLeftContainer $isButton={isButton ? true : false}>
            {isButton && (
              <StyledButton $isBackButton onClick={isDropOffEvent ? handleCustomBack : handleBack} type='button'>
                <StyledImage src={leftArrowIcon} alt='back icon' />
                {backButtonText}
              </StyledButton>
            )}
            <StyledWrapper>
              {warningMessage && <InvoiceMessage message={warningMessage} isErrorIcon isBorderRadius />}
            </StyledWrapper>
          </StyledLeftContainer>
          {showActionButtons && (
            <StyledRightContainer>
              <StyledCartWrapper
                onClick={() => {
                  if (setCartModalOpen && isMobile) {
                    setCartModalOpen((prev: boolean) => !prev);
                  }
                }}
              >
                {isDropOffEvent && <StyledCartIcon src={shoppingCartSolid} alt='shopping cart' />}
                {amount ? (
                  <StyledAmountContainer>
                    <StyledTotalAmountContainer>Total:</StyledTotalAmountContainer>
                    <StyledFigureContainer>
                      AED {amount} <StyledCaret>^</StyledCaret>
                    </StyledFigureContainer>
                  </StyledAmountContainer>
                ) : null}
              </StyledCartWrapper>
              <StyledButton
                $isDropOffEvent={isDropOffEvent ? true : false}
                $isBackOption
                onClick={isDropOffEvent ? handleCustomBack : handleBack}
                type='button'
              >
                Back
              </StyledButton>
              {showSaveForLaterStatus.includes(eventStatus) && (
                <StyledButton $isSaveButton onClick={handleSave} type='button'>
                  Save for Later
                </StyledButton>
              )}
              <StyledButton
                $isNextButton
                disabled={isDisable}
                onClick={handleNext}
                id={nextButtonId}
                type={nextButtonType}
              >
                {nextButtonText}
              </StyledButton>
            </StyledRightContainer>
          )}
        </StyledButtonsContainer>
      </MaxWidthContainer>
    </StyledContainer>
  );
};

export default OrderFooter;
