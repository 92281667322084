import styled from 'styled-components';

import { Divider } from '@mui/material';

import { tickSquareActive } from '@src/lib/imgUrl';
import { Breakpoints } from '@src/styles/breakpoints';

export const StyledDishContainer = styled.div<{ $isSelected: boolean; $isDesktop: boolean }>`
  display: flex;
  gap: 16px;
  padding: 12px;
  border-radius: 12px;
  border: 1px solid ${({ theme }) => theme.primaryColor.white};
  ${({ $isDesktop }) => ($isDesktop ? 'flex-direction: row;' : 'flex-direction: column;')}
  ${({ $isSelected, theme }) =>
    $isSelected
      ? `
      border-color: ${theme.primaryColor.royalBlue};
      background: ${theme.primaryColor.azure};`
      : ''}
`;

export const StyledDishImage = styled.div<{ $imageUrl: string; $isDesktop: boolean }>`
  ${({ $isDesktop }) => ($isDesktop ? 'min-width: 40%; max-width: 40%;' : '')}
  background-image: url('${({ $imageUrl }) => $imageUrl}');
  background-size: cover;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  border-radius: 8px;
  aspect-ratio: 62 / 36;
`;

export const StyledDishContent = styled.div<{ $isRateUs?: boolean }>`
  display: flex;
  flex-direction: column;
  gap: ${({ $isRateUs }) => ($isRateUs ? '8px' : '16px')};
  flex: 1;
`;

export const StyledDishHeader = styled.div`
  font-family: ${props => props.theme.fontFamily.lato};
  color: ${props => props.theme?.primaryColor.midnight};
  font-weight: 600;
  display: flex;
  justify-content: space-between;
`;

export const StyledDishNameContainer = styled.div<{ $isRateUs?: boolean }>`
  gap: 4px;
  display: flex;
  flex-direction: column;
  ${({ $isRateUs }) => $isRateUs && 'font-size: 20px;'}
`;

export const StyledAllergenContainer = styled.div`
  min-height: 24px;
  max-height: 24px;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledTextWithEllipsis = styled.div<{ $isRateUs?: boolean }>`
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-weight: 400;
  color: ${({ theme, $isRateUs }) => ($isRateUs ? theme?.primaryColor?.mutedGray : theme?.primaryColor.shadePurple)};
  font-family: ${props => props.theme.fontFamily.lato};
  text-overflow: ellipsis;
  white-space: normal;
  line-height: ${({ $isRateUs }) => ($isRateUs ? '20.8px' : '18.2px')};
  min-height: 36px;
  ${({ $isRateUs }) => $isRateUs && 'font-size: 16px;'}
`;

export const StyledMoreInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  gap: 4px;
  cursor: pointer;
  flex-wrap: nowrap;
  font-family: ${props => props.theme.fontFamily.lato};
`;

export const StyledSpanText = styled.span`
  color: ${props => props.theme.primaryColor.royalBlue};
  text-decoration: underline;
`;

export const StyledExtraPortion = styled.div`
  color: ${({ theme }) => theme.primaryColor.fluentGray};
  text-align: right;
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-weight: 600;
  white-space: nowrap;
`;
export const StyledRoundCheckbox = styled.input`
  min-width: 20px;
  max-width: 20px;
  height: 20px;
  background-color: ${props => props.theme.primaryColor.white};
  border-radius: 6px;
  vertical-align: middle;
  border: 2px solid ${props => props.theme.primaryColor.mutedGray};
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  cursor: pointer;

  &:checked {
    border: none;
    background: url(${tickSquareActive});
  }

  &:checked:after {
    transition: background 0.2s;
  }
`;

export const StyledMoreInfoContainer = styled.div`
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-top: auto;
`;

export const StyledItemCountWrapper = styled.div`
  display: flex;
  flex-direction: column-reverse;
  gap: 8px;
  align-items: start;
  justify-content: space-between;
  flex-wrap: wrap;
`;

export const StyledFlexContainer = styled.div`
  display: flex;
  align-self: end;
`;

export const StyledItemCountContainer = styled.button<{
  $minus?: boolean;
  $add?: boolean;
  $count?: boolean;
  $isDisable: boolean;
}>`
  height: 32px;
  width: 40px;
  display: flex;
  border: 0;
  align-items: center;
  justify-content: center;
  color: ${props => props.theme?.primaryColor.shadePurple};
  font-family: ${props => props.theme.fontFamily.lato};
  background: ${props => props.theme.primaryColor.babyBlue};
  font-weight: 600;

  ${({ $add, theme, $count, $minus, $isDisable }) => {
    if ($add) {
      return `
      border: 1px solid ${$isDisable ? theme.primaryColor.lightGray2 : theme.primaryColor.royalBlue};
      border-radius: 0 6px 6px 0;
      cursor: pointer;
      `;
    } else if ($count) {
      return `
      background: ${theme.primaryColor.white};
      border-top: 1px solid ${$isDisable ? theme.primaryColor.lightGray2 : theme.primaryColor.royalBlue};
      border-bottom: 1px solid ${$isDisable ? theme.primaryColor.lightGray2 : theme.primaryColor.royalBlue};
      `;
    } else if ($minus) {
      return `
      border: 1px solid ${$isDisable ? theme.primaryColor.lightGray2 : theme.primaryColor.royalBlue};
      border-radius: 6px 0 0 6px;
      cursor: pointer;
      `;
    }
  }}
  ${({ $isDisable, theme }) => ($isDisable ? `background: ${theme.primaryColor.lightGray};` : '')}
`;

export const StyledAllergensImage = styled.img`
  border-radius: 50%;
  height: 24px;
  width: 24px;
`;

export const StyledRatingContainer = styled.div`
  background: ${props => props.theme?.primaryColor.black};
  opacity: 0.8;
  border-radius: 4px;
  padding: 8px;
  margin: 8px;
  position: absolute;
  right: 0;
`;

export const StyledDishImageContainer = styled.div<{ $isDesktop: boolean }>`
  ${({ $isDesktop }) => ($isDesktop ? 'min-width: 40%; max-width: 40%;' : '')}
  position: relative;
  border-radius: 8px;
`;

export const StyledDivider = styled(Divider)`
  width: 75%;
`;

export const StyledAddPortion = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${props => props.theme?.primaryColor.shadePurple};
  border-bottom: 1px solid ${props => props.theme?.primaryColor.shadePurple};
  cursor: pointer;
`;

export const StyledBrandName = styled.div`
  color: ${props => props.theme?.primaryColor.shadePurple};
  background-color: ${props => props.theme?.primaryColor.white};
  padding: 4px 6px;
  position: absolute;
  bottom: 5%;
  left: 5%;
  border-radius: 4px;
  font-size: 10px;
  font-weight: 500;
  margin-right: 10px;
`;
