import axios, { AxiosInstance, AxiosResponse } from 'axios';
import { OptionsObject, SnackbarMessage } from 'notistack';

import apiEndpoints from '@src/lib/apiEndpoints';
import { clearJourney } from '@src/redux/action/journeyStatus';
import { clearUserData } from '@src/redux/action/login';
import { store } from '@src/redux/store';

// You can set your base URL here
const BASE_URL = process.env.REACT_APP_API_BASE_URL;

// This will hold the reference to the enqueueSnackbar function
let enqueueSnackbar: ((message: SnackbarMessage, options?: OptionsObject | undefined) => React.ReactText) | null = null;

// Function to be called in the root of the app to provide the enqueueSnackbar function
export function setSnackbar(
  enqueueSnackbarFn: (message: SnackbarMessage, options?: OptionsObject) => React.ReactText
): void {
  enqueueSnackbar = enqueueSnackbarFn;
}

let isRefreshing = false;

// Axios instance configuration
const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  withCredentials: true, // Include this line to send cookies with every request
  timeout: 50000,
  timeoutErrorMessage: 'Request timed out',
  // You can add your headers here
});

// Request Interceptor
axiosInstance.interceptors.request.use(
  config => {
    config.headers['X-Frame-Options'] = 'ALLOW-FROM *';
    config.headers['X-Content-Type-Options'] = 'nosniff';
    config.headers['Strict-Transport-Security'] = 'max-age=31536000;';
    config.headers['X-XSS-Protection'] = '1; mode=block';
    config.headers['Referrer-Policy'] = 'strict-origin';
    config.headers['Permissions-Policy'] = 'geolocation=();';
    config.headers['Cross-Origin-Opener-Policy'] = 'same-origin';
    config.headers['Cross-Origin-Resource-Policy'] = 'same-site';

    return config;
  },
  error => {
    // Handle request errors
    return Promise.reject(error);
  }
);

// Response Interceptor
axiosInstance.interceptors.response.use(
  async (response: AxiosResponse) => {
    // You can add any additional processing for the response here
    return response;
  },
  async error => {
    const customConfig = error?.config as any;

    // Check if the error response should be skipped
    if (customConfig?.skipErrorMessage) {
      return Promise.reject(error);
    }

    const dispatch = store.dispatch;

    if (
      error?.response?.data?.status === 401 &&
      error?.config?.url != apiEndpoints.AUTH_REFRESH_TOKEN &&
      error?.response?.data?.message === 'Unauthorized: Please authenticate.'
    ) {
      if (!isRefreshing) {
        isRefreshing = true;

        try {
          const testResponse = await axiosInstance.post(apiEndpoints.AUTH_REFRESH_TOKEN, {});

          const originalRequest = error.config;
          const retryResponse = await axiosInstance(originalRequest);

          return retryResponse;
        } catch (apiError) {
          console.error(apiError);
        } finally {
          isRefreshing = false;
        }
      }
    } else {
      if (error?.response?.data?.status === 401 && error?.config?.url === apiEndpoints.AUTH_REFRESH_TOKEN) {
        dispatch(clearUserData());
        dispatch(clearJourney());
      }
    }

    // Handle other response errors
    if (enqueueSnackbar && error?.response?.data?.status !== 401) {
      enqueueSnackbar(`An error occurred: ${error?.response?.data?.message}`, {
        variant: 'error',
        autoHideDuration: 3000,
      });
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;
