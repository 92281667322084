import { theme } from '@src/styles/theme';

const AllergensIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg
    fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    width='22'
    height='22'
    viewBox='0 0 22 22'
    id='icon'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g transform='scale(0.04296875)'>
      <line
        fill='none'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='16'
        id='XMLID_5_'
        x1='149.5'
        x2='75'
        y1='362.5'
        y2='437'
      />

      <line
        fill='none'
        stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
        strokeWidth='16'
        id='XMLID_4_'
        x1='437'
        x2='365.5'
        y1='75'
        y2='146.5'
      />

      <g id='XMLID_1_'>
        <path
          d='M256,16c32.4,0,63.8,6.3,93.4,18.9c28.6,12.1,54.2,29.4,76.3,51.4c22,22,39.4,47.7,51.4,76.3     c12.5,29.6,18.9,61,18.9,93.4s-6.3,63.8-18.9,93.4c-12.1,28.6-29.4,54.2-51.4,76.3c-22,22-47.7,39.4-76.3,51.4     c-29.6,12.5-61,18.9-93.4,18.9s-63.8-6.3-93.4-18.9c-28.6-12.1-54.2-29.4-76.3-51.4c-22-22-39.4-47.7-51.4-76.3     C22.3,319.8,16,288.4,16,256s6.3-63.8,18.9-93.4c12.1-28.6,29.4-54.2,51.4-76.3c22-22,47.7-39.4,76.3-51.4     C192.2,22.3,223.6,16,256,16 M256,0C114.6,0,0,114.6,0,256s114.6,256,256,256s256-114.6,256-256S397.4,0,256,0L256,0z'
          id='XMLID_14_'
        />
      </g>

      <g id='XMLID_859_'>
        <path
          fill='none'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='16'
          strokeMiterlimit='10'
          d='M225.1,243.8c7.3,14.1,5.9,34.2-14.6,41.6c-20.3,7.4-34.5-7.6-37.9-22.6     c-4.8-21.3,12.5-47.4,12.5-47.4S215.2,224.8,225.1,243.8z'
          id='XMLID_863_'
        />

        <path
          fill='none'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='16'
          strokeMiterlimit='10'
          d='M298.6,252.5c-10.8,11.6-30.3,16.9-44.1,0.1c-13.7-16.7-4.4-35.1,8.6-43.2     c18.5-11.7,48.9-4.1,48.9-4.1S313.1,236.9,298.6,252.5z'
          id='XMLID_862_'
        />

        <path
          fill='none'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='16'
          strokeMiterlimit='10'
          d='M272.7,336.6c-14.3,6.8-34.4,4.5-41-16.2c-6.6-20.6,8.9-34.2,24-37     c21.5-4,46.9,14.3,46.9,14.3S292.1,327.5,272.7,336.6z'
          id='XMLID_861_'
        />

        <circle
          fill='none'
          stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
          strokeWidth='16'
          strokeMiterlimit='10'
          cx='256'
          cy='256'
          id='XMLID_860_'
          r='152'
        />
      </g>
    </g>
  </svg>
);

export default AllergensIcon;
