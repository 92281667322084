import { theme } from '@src/styles/theme';

const PromoIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg
    fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    width='22'
    height='22'
    viewBox='0 0 64 64'
    xmlns='http://www.w3.org/2000/svg'
  >
    <g data-name='32 promo' id='_32_promo'>
      <path d='M60.27,42.05c-.28-.78-.38-1.07-.43-1.19a3.762,3.762,0,0,0-3.74-2.44c-.15,0-.3.01-.46.02-1.24.1-2.46.31-3.64.5-1.01.17-2.06.34-3.08.45a51.767,51.767,0,0,1-7.03.32,52.933,52.933,0,0,1-5.91-.51,3.661,3.661,0,0,0-.33-2.03,3.936,3.936,0,0,0-2.54-2.08l-9.48-2.58a9.588,9.588,0,0,0-4.58-.09l-4.08.91v-3.4a2,2,0,0,0-2-2H5.5a2,2,0,0,0-2,2v25a2.006,2.006,0,0,0,2,2h7.47a2.006,2.006,0,0,0,2-2V52.31l16.53,3a12.686,12.686,0,0,0,2.3.2,12.821,12.821,0,0,0,4.7-.89l19.52-7.73A3.8,3.8,0,0,0,60.27,42.05ZM12.97,54.93H5.5v-25h7.47Zm44.31-9.9L37.77,52.76a10.822,10.822,0,0,1-5.91.58L14.97,50.28V35.38l4.52-1.01a7.642,7.642,0,0,1,3.62.07l9.47,2.58a1.934,1.934,0,0,1,1.27,1.02,1.725,1.725,0,0,1,.03,1.44,2.022,2.022,0,0,1-2.02,1.17l-8.22-1.7a1,1,0,0,0-1.18.77,1.01,1.01,0,0,0,.77,1.19l8.28,1.71a.34.34,0,0,0,.1.01,2.748,2.748,0,0,0,.4.02,4.089,4.089,0,0,0,3.2-1.54,54.458,54.458,0,0,0,6.62.6,54.033,54.033,0,0,0,7.3-.33c1.08-.12,2.16-.29,3.2-.47,1.14-.19,2.32-.38,3.48-.48a1.948,1.948,0,0,1,2.15,1.11v.01l.43,1.18A1.814,1.814,0,0,1,57.28,45.03Z' />

      <path d='M55.42,20.28l-2.14-2.73.49-3.43a1.016,1.016,0,0,0-.62-1.07l-3.21-1.28L48.65,8.55a1.009,1.009,0,0,0-1.07-.62l-3.43.49L41.43,6.29a.992.992,0,0,0-1.24,0L37.47,8.42l-3.43-.49a1.009,1.009,0,0,0-1.07.62l-1.29,3.22-3.21,1.28a1.016,1.016,0,0,0-.62,1.07l.49,3.43L26.2,20.28a1.006,1.006,0,0,0,0,1.23l2.14,2.73-.49,3.42a.99.99,0,0,0,.62,1.07l3.21,1.29,1.29,3.21a1,1,0,0,0,1.07.62l3.43-.49,2.72,2.14a1.009,1.009,0,0,0,.62.21,1.031,1.031,0,0,0,.62-.21l2.72-2.14,3.43.49a1,1,0,0,0,1.07-.61l1.29-3.22,3.21-1.29a1,1,0,0,0,.62-1.07l-.49-3.42,2.14-2.73A1.006,1.006,0,0,0,55.42,20.28Zm-3.98,3.06a.984.984,0,0,0-.2.76l.44,3.07-2.89,1.15a.978.978,0,0,0-.55.56l-1.16,2.88-3.07-.44a1,1,0,0,0-.76.21l-2.44,1.91-2.44-1.91a1.02,1.02,0,0,0-.62-.22c-.05,0-.1.01-.14.01l-3.08.44-1.15-2.88a1,1,0,0,0-.56-.56l-2.88-1.15.44-3.07a1.026,1.026,0,0,0-.2-.76l-1.92-2.45,1.92-2.44a1.026,1.026,0,0,0,.2-.76l-.44-3.07,2.88-1.16a.978.978,0,0,0,.56-.55l1.15-2.88,3.08.43a.994.994,0,0,0,.76-.2l2.44-1.92,2.44,1.92a.994.994,0,0,0,.76.2l3.07-.43,1.16,2.88a.99.99,0,0,0,.55.55l2.89,1.16-.44,3.07a.984.984,0,0,0,.2.76l1.92,2.44Z' />

      <path d='M35.81,14.39a3,3,0,1,0,3,3A3,3,0,0,0,35.81,14.39Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,35.81,18.39Z' />

      <path d='M45.81,21.39a3,3,0,1,0,3,3A3,3,0,0,0,45.81,21.39Zm0,4a1,1,0,1,1,1-1A1,1,0,0,1,45.81,25.39Z' />

      <path d='M35.065,26.062a1,1,0,0,0,1.413.075l10-9A1,1,0,0,0,45.14,15.65l-10,9A1,1,0,0,0,35.065,26.062Z' />
    </g>
  </svg>
);

export default PromoIcon;
