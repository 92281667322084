import styled from 'styled-components';

import { Rating } from '@mui/material';

import { rateUSbg } from '@src/lib/imgUrl';
import { Breakpoints } from '@src/styles/breakpoints';

export const StyledStarContainer = styled.div<{ color?: string }>`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
  color: ${({ theme, color }) => color || `${theme.primaryColor.GlacierMist}`};
  font-weight: 600;
  font-size: 12px;
`;

export const StyledContainer = styled.div`
  background-color: #0a3739;
  padding: 16px 12px;
  border-radius: 8px;
  color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  width: 100%;
  position: relative;
  box-sizing: border-box;
`;

export const StyledBgImage = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 75%;
  margin: 0 -12px;
  background-image: url(${rateUSbg});
  background-repeat: no-repeat;
  background-position: initial;
  border-radius: 8px;
  background-size: cover;
  ${Breakpoints.MOBILE} {
    background-size: contain;
    background-position: bottom;
  }
`;

export const StyledDataContainer = styled.div`
  position: relative;
  display: flex;
  justify-content: space-between;
  z-index: 1;
`;

export const StyledDate = styled.div`
  padding: 4px 0px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18.2px;
  color: ${({ theme }) => theme.primaryColor.GlacierMist};
`;

export const StyledMealType = styled.div`
  padding: 4px 0px;
  font-weight: 700;
  font-size: 18px;
  line-height: 23.4px;
  color: ${({ theme }) => theme.primaryColor.GlacierMist};
`;

export const StyledAddress = styled.div`
  padding: 4px 0px;
  font-weight: 300;
  font-size: 14px;
  line-height: 18.2px;
  color: ${({ theme }) => theme.primaryColor.GlacierMist};
`;

export const StyledRateUSBtn = styled.button<{ color?: string }>`
  border: none;
  background: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 16px;
  font-weight: 700;
  color: ${({ theme, color }) => color || `${theme.primaryColor.GlacierMist}`};
  cursor: pointer;
  padding: 0;
`;

export const StyledStarsImg = styled.img`
  width: 12px;
  height: 12px;
  color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  margin-right: 2px;
`;

export const StyledStarsWrapper = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 4px;
`;

export const StyledRateUsLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  color: ${({ theme }) => `${theme.primaryColor.black}`};
  align-items: flex-end;
  gap: 10px;
  flex: 1;
  ${Breakpoints.TABLET} {
    word-wrap: break-word;
    text-align: center;
  }
`;

export const StyledRating = styled(Rating)`
  display: flex;
  gap: 6px;
`;

export const StyledSpan = styled.span`
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 15px;
  border-bottom: 0.1px solid ${({ theme }) => theme.primaryColor.white};
  opacity: 0.4;
`;
