import { theme } from '@src/styles/theme';

const FeedBackIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'>
    <path
      stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d='M22 6.25v5.1c0 1.27-.42 2.34-1.17 3.08-.74.75-1.81 1.17-3.08 1.17v1.81c0 .68-.76 1.09-1.32.71l-.97-.64c.09-.31.13-.65.13-1.01V12.4c0-2.04-1.36-3.4-3.4-3.4H5.4c-.14 0-.27.01-.4.02V6.25C5 3.7 6.7 2 9.25 2h8.5C20.3 2 22 3.7 22 6.25Z'
    />
    <path
      stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      strokeLinecap='round'
      strokeLinejoin='round'
      d='M9 7h9M16 11h2'
    />
    <path
      stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.5}
      d='M15.59 12.4v4.07c0 .36-.04.7-.13 1.01-.37 1.47-1.59 2.39-3.27 2.39H9.47l-3.02 2.01a.671.671 0 0 1-1.05-.56v-1.45c-1.02 0-1.87-.34-2.46-.93-.6-.6-.94-1.45-.94-2.47V12.4c0-1.9 1.18-3.21 3-3.38.13-.01.26-.02.4-.02h6.79c2.04 0 3.4 1.36 3.4 3.4Z'
    />
    <path
      stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      strokeLinecap='round'
      strokeLinejoin='round'
      d='m9.19 11.932.53 1.061c.072.144.261.288.432.315l.963.162c.612.1.755.55.315.99l-.747.747a.638.638 0 0 0-.153.549l.216.926c.17.73-.216 1.017-.864.63l-.9-.53a.63.63 0 0 0-.593 0l-.9.53c-.648.378-1.035.1-.864-.63l.216-.926c.036-.171-.027-.423-.153-.55l-.747-.746c-.44-.44-.296-.882.315-.99l.963-.162a.656.656 0 0 0 .423-.315l.53-1.061c.262-.576.73-.576 1.017 0Z'
    />
  </svg>
);

export default FeedBackIcon;
