import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps, DeleteBodySchema } from '@src/lib/getUpdate';
import Package from '@src/model/Package';

import apiRequest from './api';

interface CreatePackagesEventParams {
  startDate?: string;
  endDate?: string;
  emirate?: string;
  occasion?: string;
  numberOfPerson?: number;
  makeMyOwn: boolean;
  packageDetails?: {
    service: string;
    packageId?: string;
    type?: string[];
    name?: string;
  };
}

export const createPackage = (data: Package) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_PACKAGE}`,
    data,
  });
};

export const updatePackage = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_PACKAGE}/${id}`,
    data,
  });
};

export const getPackageById = (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.GET_PACKAGE}/${id}`,
  });
};

export const getPackageList = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PACKAGE_LIST}`,
    config: { params },
  });
};

export const updatePackageStatus = (data: DeleteBodySchema, status: boolean, doubleConfirmed: boolean) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_PACKAGE_STATUS}`,
    data: { ...data, status, doubleConfirmed },
  });
};

export const createPackagesEvent = (data: CreatePackagesEventParams) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_EVENT}`,
    data,
    config: {
      skipErrorMessage: true,
    },
  });
};

export const deletePackagesEvent = (data: DataProps) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.DELETE_EVENT}`,
    data,
  });
};
