import { styled } from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const FlexBox = styled.div<{ order?: string }>`
  display: flex;
  gap: 24px;
  flex-direction: row;
  order: ${({ order }) => (order ? order : 0)};

  ${Breakpoints.TABLET_SMALL} {
    flex-wrap: wrap;
    flex-direction: column;
  }
`;

export const GridBox = styled.div`
  display: grid;
  gap: 24px;
  grid-template-columns: repeat(3, minmax(0, 320px));
  ${Breakpoints.TABLET_SMALL} {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const StyledAddressFieldsContainer = styled(FlexBox)`
  flex-direction: column;
`;

export const StyledContainer = styled(FlexBox)`
  padding: 12px 20px;
  flex-direction: column;
`;

export const StyledTimeContainer = styled(FlexBox)`
  flex-direction: column;
  flex: 1;
  max-width: 320px;
`;

export const StyledLabelContainer = styled.div`
  padding: 8px;
  font-size: 14px;
  color: ${props => props.theme.primaryColor.fluentGray};
  background-color: ${props => props.theme.primaryColor.snow};
  box-sizing: border-box;
`;

export const StyledWidthProvider = styled.div<{ width?: string }>`
  max-width: ${({ width }) => (width ? width : '320px')};
  flex: 1;
`;
