import { emptyMenu } from '@src/lib/imgUrl';

import { StyledDescription, StyledEmptyContainer, StyledImage } from './style';

interface EmptyCardProps {
  desc: string;
}

const EmptyCard = ({ desc }: EmptyCardProps) => {
  return (
    <StyledEmptyContainer>
      <StyledImage src={emptyMenu} />
      <StyledDescription>{desc}</StyledDescription>
    </StyledEmptyContainer>
  );
};

export default EmptyCard;
