import ActionConstants from '../constants';

const initialClientState = {
  email: '',
  phoneNumber: '',
  role: '',
  isContract: false,
  active: false,
  company: '',
  firstName: '',
  lastName: '',
  _id: '',
};

export default function clientDetailsReducer(state = initialClientState, action: any) {
  switch (action.type) {
    case ActionConstants.UPDATE_CLIENT_DETAILS:
      return {
        ...state,
        ...action.payload,
      };
    case ActionConstants.CLEAR_CLIENT_DETAILS:
      return initialClientState;
    default:
      return state;
  }
}
