import styled from 'styled-components';

import { theme } from '@src/styles/theme';

export const StyledRatingContainer = styled.div`
  display: flex;
  font-size: 12px;
  align-items: center;
`;

export const customRatingStar = (size?: string) => {
  return {
    '.MuiRating-iconFilled': {
      svg: {
        ...(size
          ? {
              width: `${size}px`,
              height: `${size}px`,
            }
          : {}),
        path: {
          d: 'path("M 23.9519 9.56246 C 23.8423 9.19728 23.5355 8.92693 23.1716 8.87145 L 16.0545 7.78651 L 12.869 1.01864 C 12.543 0.327121 11.4599 0.327121 11.1301 1.01864 L 7.94842 7.78651 L 0.831293 8.87145 C 0.467841 8.92693 0.161117 9.19325 0.047178 9.56246 C -0.0662807 9.93217 0.0284285 10.3377 0.293326 10.604 L 5.44464 15.8734 L 4.2288 23.3085 C 4.16823 23.6904 4.3163 24.0797 4.61533 24.3062 C 4.91436 24.5327 5.30858 24.5645 5.63406 24.3814 L 12.0017 20.8723 L 18.365 24.3814 C 18.5087 24.4611 18.664 24.4969 18.8193 24.4969 C 19.0203 24.4969 19.2174 24.4333 19.3876 24.3062 C 19.6871 24.0757 19.8347 23.6904 19.7741 23.3085 L 18.5583 15.8734 L 23.7096 10.604 C 23.9745 10.3337 24.0658 9.93217 23.9519 9.56246 Z");',
        },
      },
    },
    '.MuiRating-iconEmpty': {
      svg: {
        ...(size
          ? {
              width: `${size}px`,
              height: `${size}px`,
            }
          : {}),
        fill: theme.primaryColor.lunarElegance,
        path: {
          d: 'path("M 23.9519 9.56246 C 23.8423 9.19728 23.5355 8.92693 23.1716 8.87145 L 16.0545 7.78651 L 12.869 1.01864 C 12.543 0.327121 11.4599 0.327121 11.1301 1.01864 L 7.94842 7.78651 L 0.831293 8.87145 C 0.467841 8.92693 0.161117 9.19325 0.047178 9.56246 C -0.0662807 9.93217 0.0284285 10.3377 0.293326 10.604 L 5.44464 15.8734 L 4.2288 23.3085 C 4.16823 23.6904 4.3163 24.0797 4.61533 24.3062 C 4.91436 24.5327 5.30858 24.5645 5.63406 24.3814 L 12.0017 20.8723 L 18.365 24.3814 C 18.5087 24.4611 18.664 24.4969 18.8193 24.4969 C 19.0203 24.4969 19.2174 24.4333 19.3876 24.3062 C 19.6871 24.0757 19.8347 23.6904 19.7741 23.3085 L 18.5583 15.8734 L 23.7096 10.604 C 23.9745 10.3337 24.0658 9.93217 23.9519 9.56246 Z");',
        },
      },
    },
  };
};
