const SearchIcon = () => (
  <svg width='20' height='20' viewBox='0 0 20 20' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M9.16669 16.6667C13.3088 16.6667 16.6667 13.3088 16.6667 9.16667C16.6667 5.02454 13.3088 1.66667 9.16669 1.66667C5.02455 1.66667 1.66669 5.02454 1.66669 9.16667C1.66669 13.3088 5.02455 16.6667 9.16669 16.6667Z'
      stroke='#4B5563'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M15.775 17.2415C16.2166 18.5748 17.225 18.7082 18 17.5415C18.7083 16.4748 18.2416 15.5998 16.9583 15.5998C16.0083 15.5915 15.475 16.3332 15.775 17.2415Z'
      stroke='#4B5563'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default SearchIcon;
