import styled from 'styled-components';

export const StyledHeader = styled.div<{ $isOpen?: boolean }>`
  display: flex;
  padding: 8px 12px 8px 4px;
  gap: 4px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme, $isOpen }) =>
    $isOpen ? theme.primaryColor.silkenMoonlight : theme.primaryColor.white};
`;

export const StyledIconWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
`;

export const StyledAccordionTitle = styled.div`
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  font-size: 14px;
  font-weight: 600;
  text-transform: capitalize;
`;

export const StyledContainer = styled.div`
  display: flex;
  gap: 4px;
  flex: 1;
`;

export const StyledAmount = styled.div`
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  font-size: 14px;
`;

export const StyledDishContainer = styled.div<{ $border?: boolean; $isLast?: boolean; $isFirst?: boolean }>`
  display: flex;
  padding: 6px 12px 6px 24px;
  gap: 40px;
  ${({ $border }) => ($border ? ` border-bottom: 1px solid #e5e7eb;` : '')}
  ${({ $border, $isFirst }) => ($border && $isFirst ? ` border-top: 1px solid #e5e7eb;` : '')}
  ${({ $border, $isLast }) => ($border && $isLast ? 'border-bottom: none;' : '')}
`;

export const StyledDishDetails = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  font-size: 12px;
`;

export const StyledPriceCalc = styled.div`
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
`;

export const StyledDishName = styled.div`
  color: ${({ theme }) => theme.primaryColor.mutedGray};
`;

export const StyledPrice = styled.div`
  font-size: 12px;
  font-weight: 400;
  line-height: 15.6px;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
`;

export const StyledBookingDate = styled.div`
  font-size: 14px;
  padding: 10px 12px 6px 24px;
  display: flex;
  justify-content: space-between;
  color: ${({ theme }) => theme.primaryColor.mutedGray};
`;

export const StyledDishTitleContainer = styled.div`
  padding: 6px 12px 6px 44px;
  display: flex;
  justify-content: space-between;
  gap: 40px;
  color: ${({ theme }) => theme.primaryColor.mutedGray};
  font-weight: 500;
  font-size: 14px;
`;

export const StyledDishTitle = styled.div`
  text-overflow: ellipsis;
  flex: 1;
  max-width: fit-content;
  overflow: hidden;
`;

export const StyledCategoryName = styled(StyledAccordionTitle)`
  padding: 6px 12px 6px 24px;
`;

export const StyledDeleteIcon = styled.img`
  cursor: pointer;
  height: 16px;
`;

export const StyledPriceContainer = styled.div`
  display: flex;
  gap: 12px;
`;

export const StyledEditIcon = styled.img`
  cursor: pointer;
  height: 14px;
`;
