export default {
  LOGIN_OTP: '/staff-auth/login-otp',
  VERIFY_LOGIN_OTP: '/staff-auth/verify-login-otp',
  USER_LOGOUT: '/staff-auth/logout',
  AUTH_REFRESH_TOKEN: '/staff-auth/refresh-tokens',
  CURRENT_USER: '/staff/self',
  STAFF_LIST: '/staff/list',
  CREATE_STAFF: '/staff/create',
  UPDATE_STAFF: '/staff',
  DELETE_STAFF: '/staff/delete',
  CURRENT_CLIENT: '/customer/self',
  CLIENT_LIST: '/customer/list',
  CREATE_CLIENT: '/customer/create',
  GET_CLIENT_BY_ID: '/customer',
  UPDATE_CLIENT: '/customer',
  DELETE_CLIENT: '/customer/delete',
  COMPANY_LIST: '/company/list',
  COMPANY: '/company',
  INGREDIENT_LIST: '/ingredient/list',
  CREATE_INGREDIENT: '/ingredient/create',
  UPDATE_INGREDIENT: '/ingredient',
  DELETE_INGREDIENT: '/ingredient/delete',
  GET_CATEGORY: '/category',
  CATEGORY_LIST: '/category/list',
  CREATE_CATEGORY: '/category/create',
  UPDATE_CATEGORY: '/category',
  DELETE_CATEGORY: '/category/delete',
  ALLERGEN_LIST: '/allergens/list',
  CREATE_ALLERGEN: '/allergens/create',
  UPDATE_ALLERGEN: '/allergens',
  DELETE_ALLERGEN: '/allergens/delete',
  DISH_LIST: '/dish/list',
  CREATE_DISH: '/dish/create',
  DISH: '/dish',
  DELETE_DISH: '/dish/delete',
  UPDATE_DISH_STATUS: '/dish/status',
  ADDONS_LIST: '/addon/list',
  CREATE_ADDONS: '/addon/create',
  ADDONS: '/addon',
  UPDATE_ADDONS_STATUS: '/addon/status',
  PROMO_LIST: '/promo/list',
  CREATE_PROMO: '/promo/create',
  PROMO_DELETE: '/promo/delete',
  PROMO_DETAILS: '/promo',
  PROMO_UPDATE: '/promo',
  PRESIGNED_URL_GENERATE: '/presignedurl/generate',
  DAILY_MENU_BY_ID: '/daily-menu',
  DAILY_MENU: '/daily-menu',
  DAILY_MENU_LIST: '/daily-menu/list',
  PACKAGE_LIST: '/package/list',
  GET_PACKAGE: '/package',
  CREATE_PACKAGE: '/package/create',
  UPDATE_PACKAGE: '/package',
  UPDATE_PACKAGE_STATUS: '/package/status',
  CREATE_DAILY_MENU: '/daily-menu/create',
  UPDATE_DAILY_MENU_STATUS: '/daily-menu/status',
  DELETE_DAILY_MENU: '/daily-menu/delete',
  ADDON_MENU_LIST: '/addon-menu/list',
  ADDON_MENU_BY_ID: '/addon-menu',
  ADDON_MENU: '/addon-menu',
  CREATE_ADDON_MENU: '/addon-menu/create',
  UPDATE_ADDON_MENU_STATUS: '/addon-menu/status',
  DELETE_ADDON_MENU: '/addon-menu/delete',
  CREATE_WEEKLY_MENU: '/weekly-menu/create',
  WEEKLY_MENU_LIST: '/weekly-menu/list',
  UPDATE_WEEKLY_MENU_STATUS: '/weekly-menu/status',
  WEEKLY_MENU_BY_ID: '/weekly-menu',
  GET_WEEKLY_MENU_TODAY: '/weekly-menu/todaysMenu',
  ORDER_LIST: '/order/list',
  SETTING_DETAILS: '/setting',
  UPDATE_SETTING: '/setting/update',
  UPDATE_ORDER: '/order',
  ORDER_DETAILS: '/order',
  CREATE_EVENT: '/event/create',
  DELETE_EVENT: '/event/delete',
  CREATE_BOOKING: '/booking/create',
  DELETE_BOOKING: '/booking/delete',
  GET_BOOKING_BY_ID: '/booking',
  SAVE_USER: '/auth/heading-out',
  GET_ALL_BOOKINGS: '/booking/list',
  GET_ALL_FEEDBACK: '/feedback/list',
  GET_FEEDBACK_DISH_RATING: '/feedback/dish/rating',
  GET_EVENT: '/event',
  VERIFY_PROMO: '/order/verifyPromo',
  CREATE_ORDER: '/order/create',
  GET_ORDER: '/order',
  GET_EVENT_HISTORY: '/event/history',
  GET_LABEL_DISHES_REPORT: 'report/label-dishes',
  GET_DISHES_REPORT: '/report/day-dishes',
  GET_TRANSACTION_REPORT: 'report/transactions',
  GET_PROMO_CODE_REPORT: 'report/promos',
  GET_PRODUCTS_REPORT: 'report/products',
  GET_LOGISTICS_REPORT: 'report/logistic-report',
  GET_ORDERS_REPORT: 'report/orders',
};
