import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';
import { StyledFullWidthWrapper } from '@src/styles/mainLayoutWrapper';

export const StyledContainer = styled(StyledFullWidthWrapper)`
  position: fixed;
  bottom: 0;
  z-index: 1001;
  width: 100%;
  background: ${({ theme }) => theme.primaryColor.white};
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  padding: 20px calc(${({ theme }) => theme.space.get('2xl')}*3.1);

  ${Breakpoints.DESKTOP} {
    padding: 20px calc(${({ theme }) => theme.space.get('2xl')}*2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 20px ${({ theme }) => theme.space.get('s')};
  }
  box-shadow: 4px 0 32px 0 rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`;

export const StyledButtonsContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 16px;
  ${Breakpoints.TABLET} {
    flex-direction: column;
    gap: 8px;
  }
  justify-content: end;
`;

export const StyledCartIcon = styled.img`
  height: 32px;
  width: 32px;
  object-fit: cover;
`;

export const StyledCartWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
`;

export const StyledButton = styled.button<{
  $isNextButton?: boolean;
  $isBackButton?: boolean;
  $isSaveButton?: boolean;
  $isBackOption?: boolean;
  $isDropOffEvent?: boolean;
}>`
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  border-radius: 8px;
  background-color: ${({ theme }) => theme.primaryColor.white};
  &:hover {
    cursor: pointer;
  }
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  display: flex;
  align-items: center;
  justify-content: center;
  height: fit-content;
  width: fit-content;
  font-family: ${({ theme }) => theme.fontFamily.lato}, ${({ theme }) => theme.fontFamily.seasons};
  ${({ $isBackOption }) => ($isBackOption ? `display: none;` : ``)}
  ${({ $isBackButton }) => ($isBackButton ? `padding: 12px; gap: 5px;` : ``)}
  ${({ $isSaveButton }) => ($isSaveButton ? `padding: 12px 27px;` : ``)}
  ${({ theme, $isNextButton }) =>
    $isNextButton
      ? `background-color: ${theme.primaryColor.shadePurple}; color:${theme.primaryColor.white}; padding: 12px 34px;`
      : ``}
  ${({ theme, disabled }) => (disabled ? `background-color: ${theme.primaryColor.aquaBlue};` : ``)}
  ${Breakpoints.MOBILE} {
    /* ${({ $isBackOption }) => ($isBackOption ? `display: block;` : ``)} */
    /* ${({ $isBackOption }) => ($isBackOption ? `padding: 12px;` : ``)} */
    ${({ $isBackButton }) => ($isBackButton ? `display: none;` : ``)}
    ${({ $isSaveButton }) => ($isSaveButton ? `padding: 12px;` : ``)}
    ${({ $isNextButton }) => ($isNextButton ? `padding: 12px;` : ``)}
    width: 100%;
  }
`;

export const StyledRightContainer = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
`;

export const StyledTotalAmountContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  color: ${({ theme }) => theme.primaryColor.fluentGray};
  ${Breakpoints.MOBILE} {
    font-size: 14px;
  }
`;

export const StyledFigureContainer = styled.div`
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  white-space: nowrap;
  display: flex;
  align-items: center;
`;

export const StyledCaret = styled.span`
  display: none;
  ${Breakpoints.MOBILE} {
    display: inline-block;
    height: 16px;
    margin-left: 4px;
    font-size: 20px;
  }
`;

export const StyledAmountContainer = styled.div`
  padding-right: 4px;
  font-weight: 600;
  ${Breakpoints.TABLET_SMALL} {
    padding: 0px;
  }
  ${Breakpoints.MOBILE} {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 4px;
  }
`;

export const StyledImage = styled.img`
  height: 13px;
`;

export const StyledLeftContainer = styled.div<{ $isButton: boolean }>`
  display: flex;
  flex: 1;
  align-items: center;
  ${({ $isButton }) => ($isButton ? `justify-content: space-between` : `justify-content: flex-end`)};
  ${Breakpoints.TABLET_SMALL} {
    width: auto;
  }
  ${Breakpoints.MOBILE} {
    flex-direction: column-reverse;
    gap: 8px;
  }
`;

export const StyledWrapper = styled.div`
  display: flex;
  gap: 8px;
  justify-content: center;
  align-items: center;
  ${Breakpoints.MOBILE} {
    flex-direction: column;
    gap: 8px;
    width: 100%;
    align-items: flex-start;
  }
`;

export const StyledMessage = styled.div`
  font-weight: 500;
  font-size: 14px;
  color: ${props => props.theme.primaryColor.deepSeaBlue};
  display: flex;
  flex-direction: row-reverse;
  margin-bottom: 8px;
  ${Breakpoints.MOBILE} {
    flex-direction: row;
  }
`;
