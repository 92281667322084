const RightArrowIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M5.19751 11.6199L9.00084 7.81655C9.45001 7.36738 9.45001 6.63238 9.00084 6.18322L5.19751 2.37988'
      stroke='#4B5563'
      strokeWidth='0.875'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default RightArrowIcon;
