import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Tooltip } from '@mui/material';

import { getBookingById, updateBookingById } from '@src/api/booking';
import { DROP_OFF, EDIT_MESSAGE, isAddonCategory, statusCodes, UiRoutes } from '@src/lib/constants';
import { getUpdateBody } from '@src/lib/getUpdate';
import {
  capitalizeFirstLetter,
  checkEditableOrder,
  checkEditableOrders,
  formatNumber,
  getBookingPackageType,
  getDishPrice,
  getTotalAmount,
} from '@src/lib/helper';
import { editIcon, trashOutlineIcon } from '@src/lib/imgUrl';
import { AddOn, Booking, Dish } from '@src/model/Event';
import { MealType } from '@src/model/WeeklyMenu';
import { updateBookingAction } from '@src/redux/action/bookingDetails';
import { updateBooking } from '@src/redux/action/eventDetails';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';
import { packagesType } from '@src/screens/OrderDetailScreen/hooks';
import { StyledFlexBox } from '@src/screens/OrderSummary/styles';

import DeleteModal from '../DeleteModal';
import DownArrowSmallIcon from '../Icons/DownArrowSmallIcon';
import RightArrowIcon from '../Icons/rightArrow';
import RatingStars from '../RatingStars';

import {
  StyledAccordionTitle,
  StyledAmount,
  StyledBookingDate,
  StyledCategoryName,
  StyledContainer,
  StyledDeleteIcon,
  StyledDishContainer,
  StyledDishDetails,
  StyledDishName,
  StyledDishTitle,
  StyledDishTitleContainer,
  StyledEditIcon,
  StyledHeader,
  StyledIconWrapper,
  StyledPrice,
  StyledPriceCalc,
  StyledPriceContainer,
} from './style';

export const PackageMealCard = ({
  numberOfPerson,
  packagesPrice,
  name,
  showDeleteButton,
  isLast,
  isFirst,
  dishId,
  handleDelete,
  hidePrice = false,
}: {
  numberOfPerson: number;
  packagesPrice: number;
  name: string;
  showDeleteButton?: boolean;
  isLast?: boolean;
  isFirst?: boolean;
  dishId?: string;
  handleDelete?: (dishId: string) => void;
  hidePrice?: boolean;
}) => {
  return (
    <StyledDishContainer $border={showDeleteButton} $isLast={isLast} $isFirst={isFirst}>
      <StyledDishDetails>
        {!hidePrice && (
          <StyledPriceCalc>
            {formatNumber(numberOfPerson)} X
            <span style={{ whiteSpace: 'nowrap' }}> AED {formatNumber(packagesPrice, true)}</span>
          </StyledPriceCalc>
        )}
        <StyledDishName>{name}</StyledDishName>
      </StyledDishDetails>
      <StyledPriceContainer>
        {!hidePrice && <StyledPrice>AED {formatNumber(packagesPrice * numberOfPerson, true)}</StyledPrice>}
        {showDeleteButton && handleDelete && (
          <StyledDeleteIcon src={trashOutlineIcon} alt='delete icon' onClick={() => dishId && handleDelete(dishId)} />
        )}
      </StyledPriceContainer>
    </StyledDishContainer>
  );
};

export const RenderMeals = ({
  name,
  numberOfPerson = 0,
  packagesPrice = 0,
  dishes,
  isEditIcon,
  showIncludedInPackage,
  bookingId,
  date,
  selectedBooking,
  addOns,
  isRealtimeBooking,
  packageType,
  selectedDate,
  ratings,
  expanded,
  printMode,
}: {
  name: string;
  bookingId?: string;
  packagesPrice?: number;
  numberOfPerson?: number;
  dishes?: Dish[];
  isEditIcon?: boolean;
  showIncludedInPackage?: boolean;
  date?: string;
  isRealtimeBooking?: boolean;
  selectedBooking?: string;
  addOns?: AddOn[];
  selectedDate: string;
  packageType: MealType;
  ratings?: { totalFeedbacks: number; averageRating: number };
  expanded?: boolean;
  printMode?: boolean;
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState<boolean>();

  const [dishIds, setDishId] = useState<string>('');
  const [dishCategory, setCategory] = useState<string | undefined>('');

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [dishesTree, setDishesTree] = useState<Record<string, (Dish | AddOn)[]>>();

  const {
    Bookings,
    makeMyOwn,
    bookedBy,
    eventStatus,
    eventCategories = {},
    packageDetails,
    overridePricing,
  } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const defaultBooking = useSelector((state: ReduxProps) => state?.bookingDetailsReducer?.booking);

  let totalPrice = packagesPrice * numberOfPerson;
  let makeMyOwnTotal = 0;

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  const handleEdit = () => {
    bookingId && date && navigate(`${UiRoutes.CHOOSE_MEAL}?selectedMenu=${bookingId}&date=${date}`);
  };

  const extraAddedDishes = dishes?.filter(dish => !dish?.includedInPackage);
  addOns?.forEach(addon => {
    if (addon?.includedInPackage === false) {
      extraAddedDishes?.push({
        addOn: true,
        category: addon.category,
        dishId: addon?.addOnId,
        name: addon?.name,
        price: addon?.price,
        quantity: addon?.quantity,
        sapId: addon?.sapId,
        subCategory: addon?.subCategory?._id as string,
        includedInPackage: false,
        itemCount: addon?.itemCount,
      });
    }
  });

  const arrangeDishes = useCallback(
    (isMakeMyOwn?: boolean) => {
      if (!dishes && !addOns) return;

      const updatedDishes: Record<string, (Dish | AddOn)[]> = {};

      const processDish = (dish: Dish) => {
        const categoryName = dish.category?.name;

        if (!categoryName || (!isMakeMyOwn && !dish.includedInPackage)) return;

        updatedDishes[categoryName] = updatedDishes[categoryName] || [];

        updatedDishes[categoryName].push(dish);
      };

      const processAddon = (addon: AddOn) => {
        if (!addon.includedInPackage && !isMakeMyOwn) return;

        const categoryName = addon.category?.name as string;

        updatedDishes[categoryName] = updatedDishes[categoryName] || [];

        updatedDishes[categoryName].push(addon);
      };

      dishes?.sort(({ category: a }, { category: b }) => (a.sequence || 0) - (b.sequence || 0)).forEach(processDish);

      if (addOns && addOns.length > 0) {
        addOns?.forEach(processAddon);
      }

      if (Object.keys(updatedDishes).length !== 0) {
        setDishesTree({ ...updatedDishes });
      }
    },
    [dishes, addOns]
  );

  const handleUpdateBooking = (id: string) => {
    getBookingById(id).then(res => {
      dispatch(updateBooking([...(Bookings?.filter(item => item?._id !== res?.data?._id) as Booking[]), res.data]));
    });
  };

  const handleDeleteAddons = async (addonId: string) => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      if (isRealtimeBooking) {
        dispatch(
          updateBookingAction(
            defaultBooking.map(item => {
              if (item?._id === bookingId) {
                return {
                  ...item,
                  addOns:
                    addOns?.filter(dish =>
                      dish?.addOnId !== addonId ? true : dish?.includedInPackage && !makeMyOwn
                    ) || [],
                };
              } else {
                return item;
              }
            })
          )
        );
      } else {
        if (bookingId) {
          const deleteResult = await updateBookingById(bookingId, getUpdateBody({ pullData: { addOns: [addonId] } }));

          if (deleteResult.status === statusCodes.API_SUCCESS_CODE) {
            handleUpdateBooking(deleteResult?._id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  const handleDishDelete = async (dishId: string) => {
    const loaderRequest = `unique_${Date.now()}`;

    try {
      dispatch(startLoaderAction(loaderRequest));

      if (isRealtimeBooking) {
        dispatch(
          updateBookingAction(
            defaultBooking.map(item => {
              if (item?._id === bookingId) {
                return {
                  ...item,
                  dishes:
                    dishes?.filter(dish => (dish?.dishId !== dishId ? true : dish?.includedInPackage && !makeMyOwn)) ||
                    [],
                };
              } else {
                return item;
              }
            })
          )
        );
      } else {
        if (bookingId) {
          const deleteResult = await updateBookingById(bookingId, getUpdateBody({ pullData: { dishes: [dishId] } }));

          if (deleteResult.status === statusCodes.API_SUCCESS_CODE) {
            handleUpdateBooking(deleteResult?._id);
          }
        }
      }
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(stopLoaderAction(loaderRequest));
    }
  };

  useEffect(() => {
    arrangeDishes(makeMyOwn);
  }, [dishes, addOns, makeMyOwn]);

  useEffect(() => {
    setIsOpen(bookingId === selectedBooking);
  }, [bookingId, selectedBooking]);

  extraAddedDishes?.forEach(dish => {
    totalPrice = totalPrice + (getDishPrice(dish, packageDetails?.service) || 0) * (dish?.itemCount || 0);
  });

  dishes?.forEach(dish => {
    makeMyOwnTotal = makeMyOwnTotal + (getDishPrice(dish, packageDetails?.service) || 0) * (dish?.itemCount || 0);
  });

  addOns?.forEach(addOn => {
    makeMyOwnTotal = makeMyOwnTotal + (addOn?.price || 0) * (addOn?.itemCount || 0);
  });

  const handleDelete = () => {
    if (isAddonCategory(dishCategory as string)) {
      handleDeleteAddons(dishIds);
    } else {
      handleDishDelete(dishIds);
    }

    setIsModalOpen(false);
  };

  const handleDeleteItem = (id: string, key: string | undefined) => {
    setIsModalOpen(true);
    setDishId(id);
    setCategory(key);
  };

  const canDelete = checkEditableOrder(eventStatus?.name as string);

  // Helper functions
  const renderHeader = () => (
    <StyledHeader onClick={handleClick} $isOpen={isOpen}>
      <StyledIconWrapper>
        {isOpen ? <DownArrowSmallIcon /> : <RightArrowIcon />}
        <StyledContainer>
          <StyledAccordionTitle>{name}</StyledAccordionTitle>
          {isEditIcon && (
            <Tooltip title={EDIT_MESSAGE}>
              <StyledEditIcon src={editIcon} height={14} alt='edit icon' onClick={handleEdit} />
            </Tooltip>
          )}
        </StyledContainer>
      </StyledIconWrapper>

      {!overridePricing && <StyledAmount>AED {formatNumber(makeMyOwn ? makeMyOwnTotal : totalPrice)}</StyledAmount>}
    </StyledHeader>
  );

  const renderPackageMealCard = () => (
    <PackageMealCard
      name={name}
      numberOfPerson={numberOfPerson}
      packagesPrice={packagesPrice}
      showDeleteButton={printMode || (!overridePricing ? canDelete : false)}
      hidePrice={overridePricing || printMode}
    />
  );

  const renderIncludedInPackageMeals = () =>
    (showIncludedInPackage || makeMyOwn) &&
    dishesTree &&
    Object.entries(dishesTree).map(([key, value]) => (
      <div key={key}>
        <IncludedInPackageMeal
          selectedDishes={isAddonCategory(key) ? (value as AddOn[]) : (value as Dish[])}
          title={packageDetails.service === 'drop-off' ? '' : key}
          allowedDishes={
            eventCategories?.[selectedDate.split('T')[0]]?.[packageType as MealType]?.find(
              ({ name: categoryName }) => categoryName == key
            )?.allowedDishes || 0
          }
          handleDelete={dishId => handleDeleteItem(dishId, key)}
          isMakeMyOwn={makeMyOwn}
          hideNoOfItems={printMode}
          isDeleteButton={
            !overridePricing ? (showIncludedInPackage && bookedBy?.role !== 'sales' && canDelete) || expanded : false
          }
          hidePrice={overridePricing}
          isDropOffAndBYO={packageDetails?.service === 'drop-off' && makeMyOwn}
        />
      </div>
    ));

  const renderExtraAddedDishes = () =>
    extraAddedDishes?.map((dish, index) => {
      const isLast = index === extraAddedDishes.length - 1;
      const price = getDishPrice(dish, packageDetails?.service);
      return (
        <PackageMealCard
          dishId={dish?.dishId}
          name={dish?.name}
          numberOfPerson={dish?.itemCount || 0}
          packagesPrice={price}
          key={`${dish?.name}_${index}` || `${dish?.name}`}
          showDeleteButton={
            !printMode ||
            (!overridePricing ? (showIncludedInPackage && bookedBy?.role !== 'sales' && canDelete) || expanded : false)
          }
          handleDelete={dishId => handleDeleteItem(dishId, dish?.category?.name)}
          isLast={isLast}
          isFirst={index === 0}
          hidePrice={overridePricing || printMode}
        />
      );
    });

  const renderDeleteModal = () => (
    <DeleteModal
      isOpen={isModalOpen}
      modalName='Are you sure you want to delete this item?'
      onRequestClose={() => setIsModalOpen(false)}
      onSave={handleDelete}
    />
  );

  return (
    <>
      {!makeMyOwn && packageDetails.service !== 'drop-off' && renderHeader()}
      {isOpen || expanded ? (
        <>
          {!makeMyOwn && renderPackageMealCard()}
          {renderIncludedInPackageMeals()}
          {makeMyOwn ? null : renderExtraAddedDishes()}
          {renderDeleteModal()}
        </>
      ) : null}
    </>
  );
};

const OrderDetails = ({
  bookings,
  label,
  showIncludedInPackage = false,
  makeMyOwnPrice,
  selectedBooking,
  isRealtimeBooking,
}: {
  label: string;
  bookings: Booking[];
  showIncludedInPackage?: boolean;
  isRealtimeBooking?: boolean;
  makeMyOwnPrice?: number;
  selectedBooking?: string;
}) => {
  const { makeMyOwn, overridePricing, packageDetails } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);
  const total = getTotalAmount(bookings, makeMyOwn);
  const params = useSearchParams();

  const selectedDate = params[0].get('date') as string;

  const isDropoffAndBYO = packageDetails?.service === 'drop-off' && makeMyOwn;

  return (
    <div>
      {makeMyOwn && packageDetails.service === 'drop-off' ? null : (
        <StyledBookingDate>
          <span>{label}</span>
          {makeMyOwn ? overridePricing ? <span>{`AED ${formatNumber(total, true)}`}</span> : null : null}
        </StyledBookingDate>
      )}
      {(isDropoffAndBYO ? [''] : packagesType)?.map(item => {
        const bookingPackageType = getBookingPackageType({ allBookings: bookings, type: item });
        return (isDropoffAndBYO ? bookings : bookingPackageType)?.map((booking, index) => {
          const packagesPrice = booking?.packageDetails?.price || 0;
          const numberOfPerson = booking?.numberOfPerson || 0;
          const { addOns, dishes } = booking;
          const mealType = booking?.packageDetails?.type ?? '';

          return packagesPrice || makeMyOwn ? (
            <RenderMeals
              name={
                (bookings?.length > 1 || bookingPackageType?.length || 0 > 1
                  ? capitalizeFirstLetter(`${mealType}(${index + 1})`)
                  : capitalizeFirstLetter(mealType)) ?? ''
              }
              selectedDate={selectedDate || ''}
              key={`${item}_${index}` || `${item}`}
              isRealtimeBooking={isRealtimeBooking}
              selectedBooking={selectedBooking}
              numberOfPerson={numberOfPerson}
              packagesPrice={makeMyOwn ? makeMyOwnPrice : packagesPrice}
              dishes={dishes}
              addOns={addOns || []}
              showIncludedInPackage={isDropoffAndBYO ? true : showIncludedInPackage}
              packageType={item as MealType}
              bookingId={booking?._id}
            />
          ) : null;
        });
      })}
    </div>
  );
};

export default OrderDetails;

const IncludedInPackageMeal = ({
  selectedDishes,
  title,
  allowedDishes,
  isMakeMyOwn = false,
  handleDelete,
  isDeleteButton = false,
  hidePrice = false,
  isDropOffAndBYO = false,
  hideNoOfItems,
}: {
  selectedDishes: Dish[] | AddOn[];
  title: string;
  allowedDishes: number;
  isMakeMyOwn?: boolean;
  handleDelete: (dishId: string) => void;
  isDeleteButton?: boolean;
  hidePrice?: boolean;
  isDropOffAndBYO?: boolean;
  hideNoOfItems?: boolean;
}) => {
  const { packageDetails } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const renderDish = (dish: Dish | AddOn, index: number) => {
    const isAddon = isAddonCategory(dish?.category?.name || '');
    const id = isAddon ? (dish as AddOn)?.addOnId : (dish as Dish).dishId;
    const name = dish.name;
    const price = isAddon ? dish?.price : getDishPrice(dish as Dish, packageDetails?.service);

    if (isMakeMyOwn) {
      return (
        <PackageMealCard
          dishId={id}
          name={name}
          numberOfPerson={dish.itemCount || 0}
          packagesPrice={price}
          key={name}
          showDeleteButton={!hidePrice || !hideNoOfItems ? isDeleteButton : false}
          handleDelete={handleDelete}
          isFirst={isDropOffAndBYO ? false : index === 0}
          isLast={index === selectedDishes.length - 1}
          hidePrice={hidePrice || hideNoOfItems}
        />
      );
    } else {
      return (
        <StyledDishTitleContainer style={{ padding: '6px 12px 6px 12px' }} key={id}>
          <StyledDishTitle>{name}</StyledDishTitle>
        </StyledDishTitleContainer>
      );
    }
  };

  return (
    <>
      <StyledCategoryName style={{ padding: '6px 12px 6px 12px', fontWeight: 'bold' }}>
        {title}
        {!hidePrice || !hideNoOfItems
          ? !isMakeMyOwn && allowedDishes
            ? `(${selectedDishes.length}/${allowedDishes})`
            : null
          : null}
      </StyledCategoryName>
      {selectedDishes.map(renderDish)}
    </>
  );
};
