import actionConstants from '../constants';

const staffState = {
  isLoading: true,
  isSuccess: false,
  isFailure: false,
  data: [],
};

const initialState = {
  staffState: { ...staffState },
};

export default function staffReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.STAFF_DETAILS_REQUEST:
      return {
        ...state,
        staffState: {
          ...state.staffState,
          isLoading: true,
          isSuccess: false,
          isFailure: false,
        },
      };
    case actionConstants.STAFF_DETAILS_SUCCESS:
      return {
        ...state,
        staffState: {
          ...state.staffState,
          data: action?.payload?.body,
          isLoading: false,
          isSuccess: true,
          isFailure: false,
        },
      };
    case actionConstants.STAFF_DETAILS_FAILURE:
      return {
        ...state,
        staffState: {
          ...state.staffState,
          isLoading: false,
          isSuccess: false,
          isFailure: true,
        },
      };

    default:
      return state;
  }
}
