import { Formik } from 'formik';

import Modal from '@mui/material/Modal';

import { PhoneInputLayout } from '@src/components/FormComponent/inputFieldsLayout';
import { StyledButton } from '@src/components/FormComponent/styles';
import { FormValues } from '@src/components/FormComponent/types';
import { closeIcon } from '@src/lib/imgUrl';
import { theme } from '@src/styles/theme';
import { validationSchema } from '@src/validations/loginModalValidtion';

import {
  StyledClose,
  StyledContainerBottom,
  StyledHeaderContainer,
  StyledHeading,
  StyledInputs,
  StyledMainContainer,
  StyledMessage,
  StyledNumberInput,
} from './styles';

interface ModalProps {
  isOpen: boolean;
  onRequestClose: () => void;
  onSave: (values: FormValues) => void;
  message: string;
  heading: string;
  submitButtonText?: string;
  onSubmitClose?: boolean;
  setSaveForLater?: React.Dispatch<React.SetStateAction<boolean>>;
  apiMessages?: {
    successMessage: string;
    errorMessage: string;
  };
}

const SaveProgressModal: React.FC<ModalProps> = ({
  isOpen,
  onRequestClose,
  onSave,
  message,
  heading,
  submitButtonText,
  onSubmitClose = true,
  setSaveForLater,
  apiMessages,
}) => {
  const onHandleSubmit = async (values: FormValues) => {
    onSave(values);
    onSubmitClose && handleClose();
  };

  const handleClose = () => {
    onRequestClose();
    setSaveForLater && setSaveForLater(false);
  };

  return (
    <Modal
      open={isOpen}
      onClose={handleClose}
      sx={{
        '& .MuiBackdrop-root': {
          backgroundColor: 'rgba(0, 0, 0, 0.8)',
          backdropFilter: 'blur(7px)',
        },
      }}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <Formik initialValues={{ mobileNumber: '' }} validationSchema={validationSchema} onSubmit={onHandleSubmit}>
        {({ handleSubmit, handleChange, values, handleBlur }) => {
          return (
            <form onSubmit={handleSubmit}>
              <StyledMainContainer>
                <StyledHeaderContainer>
                  <StyledHeading>{heading}</StyledHeading>
                  <StyledClose src={closeIcon} onClick={handleClose}></StyledClose>
                </StyledHeaderContainer>
                <StyledMessage>{message}</StyledMessage>
                <StyledInputs>
                  <StyledNumberInput>
                    <PhoneInputLayout
                      onChange={handleChange}
                      value={values.mobileNumber}
                      onBlur={handleBlur}
                      id='mobileNumber'
                      label='Mobile Number'
                      type='phoneNumber'
                      fixedCountryCode='+971'
                      isLogin={false}
                    />
                  </StyledNumberInput>
                </StyledInputs>
                {apiMessages?.errorMessage && (
                  <StyledMessage error={!!apiMessages?.errorMessage}>{apiMessages?.errorMessage}</StyledMessage>
                )}
                <StyledContainerBottom>
                  <StyledButton
                    border={true}
                    type='button'
                    $bgColor={theme.primaryColor.white}
                    color={theme.primaryColor.black}
                    onClick={handleClose}
                  >
                    Cancel
                  </StyledButton>
                  <StyledButton type='submit'>{submitButtonText ? submitButtonText : 'Confirm'}</StyledButton>
                </StyledContainerBottom>
              </StyledMainContainer>
            </form>
          );
        }}
      </Formik>
    </Modal>
  );
};

export default SaveProgressModal;
