import { BUFFET } from '@src/lib/constants';
import { getDeliveryCharges } from '@src/lib/helper';
import { Booking, Service } from '@src/model/Event';
import { Settings } from '@src/model/Setting';

function useCalculateDeliveryCharges(
  eventFormatData: Record<string, Booking[]>,
  emirate: string,
  serviceType: Service,
  settings?: Settings
): number {
  if (serviceType === BUFFET) {
    return 0;
  } else {
    return getDeliveryCharges(emirate, settings);
  }
}

export default useCalculateDeliveryCharges;
