import { theme } from '@src/styles/theme';

import './styles.css';

const CategoryIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg
    fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    width='22'
    height='22'
    viewBox='0 0 32 32'
    id='icon'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path d='M27,22.1414V18a2,2,0,0,0-2-2H17V12h2a2.0023,2.0023,0,0,0,2-2V4a2.0023,2.0023,0,0,0-2-2H13a2.002,2.002,0,0,0-2,2v6a2.002,2.002,0,0,0,2,2h2v4H7a2,2,0,0,0-2,2v4.1421a4,4,0,1,0,2,0V18h8v4.142a4,4,0,1,0,2,0V18h8v4.1414a4,4,0,1,0,2,0ZM13,4h6l.001,6H13ZM8,26a2,2,0,1,1-2-2A2.0023,2.0023,0,0,1,8,26Zm10,0a2,2,0,1,1-2-2A2.0027,2.0027,0,0,1,18,26Zm8,2a2,2,0,1,1,2-2A2.0023,2.0023,0,0,1,26,28Z' />
    <rect
      id='_Transparent_Rectangle_'
      data-name='&lt;Transparent Rectangle&gt;'
      className='cls-1'
      width='32'
      height='32'
    />
  </svg>
);

export default CategoryIcon;
