import styled from 'styled-components';

export const StyledHeaderContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.pristineSnowfall};
  padding: 20px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid ${({ theme }) => theme.primaryColor.silkenMoonlight};

  .css-1uk2kmj-MuiButtonBase-root-MuiMenuItem-root {
    font-family: ${({ theme }) => theme.fontFamily.firstFont} !important;
  }
`;

export const StyledProfileInfo = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const StyledProfileIcon = styled.img`
  width: 36px;
  height: 36px;
  border-radius: 50%;
  margin-right: 10px;
`;

export const StyledMenuButton = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
  cursor: pointer;
`;

export const StyledProfileNameContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledUserName = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.firstFont};
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  font-style: normal;
  font-weight: 700;
  color: ${({ theme }) => theme.primaryColor.duskHarbor};
`;

export const StyledUserRole = styled.span`
  color: ${({ theme }) => theme.primaryColor.slateShadow};
  font-size: 14px;
`;

export const StyledMenuItem = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.firstFont};
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  font-style: normal;
  font-weight: 500;
`;

export const StyledMenuContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const DefaultProfileIcon = styled.div`
  margin-right: 10px;
  border-radius: 50%;
  width: 36px;
  height: 36px;
  background-color: ${props => props.theme.primaryColor.navyBlue};
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${props => props.theme.primaryColor.white};
  font-size: ${({ theme }) => theme.fontSize.get('m')};
  text-transform: uppercase;
`;
