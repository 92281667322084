const KitchenIcon = () => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    xmlSpace='preserve'
    width='22'
    height='22'
    fill='#2e4a5c'
    stroke='#2e4a5c'
    viewBox='0 0 612 612'
  >
    <path d='M28.688 545.062h133.875V363.375H28.688v181.687zm97.422-101.601c6.273 0 11.36 5.087 11.36 11.36 0 6.272-5.087 11.36-11.36 11.36s-11.36-5.097-11.36-11.37c0-6.263 5.087-11.35 11.36-11.35z' />
    <path d='M191.25 602.438v-267.75H0v267.751h28.688V573.75h133.875v28.688h28.687zM28.688 554.625h-9.562V353.813h153v200.812H28.688zM229.5 545.062h133.875V363.375H229.5v181.687zm106.985-101.601c6.272 0 11.36 5.087 11.36 11.36 0 6.272-5.088 11.36-11.36 11.36-6.273 0-11.36-5.097-11.36-11.37 0-6.263 5.087-11.35 11.36-11.35z' />
    <path d='M392.062 602.438v-267.75H200.812v267.751H229.5V573.75h133.875v28.688h28.687zM229.5 554.625h-9.562V353.813h153v200.812H229.5zM430.312 439.875h153v105.188h-153z' />
    <path d='M573.75 325.125h-47.812c-5.278 0-9.562 4.284-9.562 9.562H497.25c0-5.278-4.284-9.562-9.562-9.562h-47.812c-5.278 0-9.562 4.284-9.562 9.562h-28.688v267.751h28.688V573.75h153v28.688H612v-267.75h-28.688c0-5.279-4.284-9.563-9.562-9.563zm-25.102 43.624c0 6.273-5.087 11.36-11.36 11.36-6.272 0-11.351-5.087-11.351-11.36 0-6.272 5.087-11.36 11.36-11.36s11.351 5.096 11.351 11.36zm25.102 32.876a4.782 4.782 0 0 1 0 9.562H439.875a4.782 4.782 0 0 1 0-9.562H573.75zm-72.914-32.876c0 6.273-5.087 11.36-11.36 11.36-6.272 0-11.351-5.087-11.351-11.36 0-6.272 5.087-11.36 11.36-11.36s11.351 5.096 11.351 11.36zm-59.163-11.351c6.272 0 11.36 5.087 11.36 11.36 0 6.272-5.088 11.36-11.36 11.36-6.273 0-11.36-5.097-11.36-11.37-.001-6.263 5.086-11.35 11.36-11.35zm151.202 197.227H420.751V430.313h172.125v124.312zm-7.765-197.227c6.272 0 11.36 5.087 11.36 11.36 0 6.272-5.088 11.36-11.36 11.36-6.273 0-11.36-5.097-11.36-11.37 0-6.263 5.087-11.35 11.36-11.35zM545.062 133.875V9.562H459v124.312h-28.688l-38.25 66.938H612l-38.25-66.938h-28.688zm1.798 45.422c-6.273 0-11.36-5.087-11.36-11.36s5.087-11.36 11.36-11.36c6.272 0 11.36 5.087 11.36 11.36-.009 6.272-5.096 11.36-11.36 11.36zM248.625 296.438h38.25c5.278 0 9.562-4.284 9.562-9.562s-4.284-9.562-9.562-9.562h-47.812c-5.278 0-9.562 4.284-9.562 9.562v38.25h19.125v-28.688zM153 325.125v-38.25c0-5.278-4.284-9.562-9.562-9.562H95.625c-5.278 0-9.562 4.284-9.562 9.562s4.284 9.562 9.562 9.562h38.25v28.688H153zM200.812 325.125V267.75c5.278 0 9.562-4.284 9.562-9.562s-4.284-9.562-9.562-9.562V229.5c0-5.278-4.284-9.562-9.562-9.562s-9.562 4.284-9.562 9.562v19.125c-5.278 0-9.562 4.284-9.562 9.562s4.284 9.562 9.562 9.562v57.375h19.124zM353.812 124.312h-203.04c13.053-11.073 21.353-27.569 21.353-46.015 0-33.335-27.023-60.358-60.358-60.358S51.398 44.953 51.398 78.298c0 18.456 8.3 34.951 21.353 46.015H19.125v19.125h334.688v-19.126zM71.719 78.298c0-22.089 17.958-40.048 40.048-40.048s40.048 17.958 40.048 40.048-17.958 40.048-40.048 40.048-40.048-17.969-40.048-40.048z' />
    <circle cx={111.767} cy={78.298} r={30.485} />
    <path d='M205.594 114.75h133.875a4.782 4.782 0 0 0 0-9.562H205.594c-2.639 0-4.781 2.142-4.781 4.781s2.142 4.781 4.781 4.781zM215.156 95.625h114.75a4.782 4.782 0 0 0 0-9.562h-114.75c-2.639 0-4.781 2.142-4.781 4.781s2.142 4.781 4.781 4.781zM215.156 76.5h114.75a4.782 4.782 0 0 0 0-9.562h-114.75c-2.639 0-4.781 2.142-4.781 4.781s2.142 4.781 4.781 4.781zM240.478 57.375h59.326c9.056 0 14.783-12.833 14.783-22.089 0-6.426-2.754-16.161-7.458-16.161h-73.995c-4.705 0-7.459 9.744-7.459 16.161.019 9.256 5.747 22.089 14.803 22.089z' />
  </svg>
);

export default KitchenIcon;
