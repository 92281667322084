import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';
import { StyledFullWidthWrapper } from '@src/styles/mainLayoutWrapper';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.primaryColor.whiteSmoke};
  width: 100%;
  min-height: 100vh;
`;

export const StyledStepper = styled(StyledFullWidthWrapper)`
  position: sticky;
  top: 77px;
  z-index: 2;
  padding: 16px 0px;
  background-color: ${({ theme }) => theme.primaryColor.white};
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 16px calc(${({ theme }) => theme.space.get('2xl')}*3.1);
  ${Breakpoints.DESKTOP} {
    padding: 16px calc(${({ theme }) => theme.space.get('2xl')}*2);
  }
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 16px ${({ theme }) => theme.space.get('s')};
  }
`;

export const StyledContentWrapper = styled.div`
  display: flex;
  padding: 16px;
  gap: 16px;
  border: 1px solid ${({ theme }) => theme.primaryColor.lunarElegance};
  border-radius: 16px;
  margin: 16px 0;
  background: ${({ theme }) => theme.primaryColor.white};
  flex: 1;
`;
export const StyledDateWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px 20px 20px;
`;

export const StyledDateText = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  font-family: ${props => props.theme.fontFamily.lato};
  font-weight: 700;
`;

export const StyledOrderDetailsContainer = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  flex-wrap: wrap;
  justify-content: center;
`;

export const StyledDivider = styled.div`
  border-bottom: 1px solid ${props => props.theme.primaryColor.denseGray};
`;

export const StyledColumnOne = styled.div<{ $isDesktop?: boolean; $gap?: string }>`
  display: flex;
  gap: ${({ $gap }) => $gap || '16px'};
  flex-direction: column;
  min-width: ${({ $isDesktop }) => ($isDesktop ? '70%' : '100%')};
  max-width: ${({ $isDesktop }) => ($isDesktop ? '70%' : '100%')};
`;

export const StyledColumnTwo = styled.div`
  display: flex;
  min-width: 29%;
  max-width: 30%;
`;

export const carouselResponsiveOrderDetails = (isOpen: boolean) => ({
  small: {
    breakpoint: { max: 1402, min: 0 },
    items: 2,
    partialVisibilityGutter: !isOpen ? 0 : 50,
  },
  medium: {
    breakpoint: { max: 1605, min: 1403 },
    items: 2,
    partialVisibilityGutter: 110,
  },
  large: {
    breakpoint: { max: 1670, min: 1606 },
    items: 2,
    partialVisibilityGutter: 125,
  },
  extraLarge: {
    breakpoint: { max: 2290, min: 1671 },
    items: 3,
    partialVisibilityGutter: !isOpen ? 0 : 50,
  },
  superLarge: {
    breakpoint: { max: 2660, min: 2291 },
    items: 3,
    partialVisibilityGutter: 100,
  },
});

export const StyledFieldset = styled.fieldset<{ $bgColor: string; $border: string }>`
  flex: 1;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background: ${({ $bgColor }) => $bgColor};
  border: 1px solid ${({ $border }) => $border};
  min-width: 175px;
  max-width: 291.9px;
  min-height: 450px;
  ${Breakpoints.MOBILE} {
    max-width: none;
  }
`;
export const StyledDishHeader = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.lato}, ${({ theme }) => theme.fontFamily.seasons};
  font-size: 14px;
  font-weight: 700;
  padding: 8px 12px 8px 4px;
  color: ${({ theme }) => theme.primaryColor.midnight};
  text-transform: capitalize;
`;

export const StyledDishItems = styled.div<{ $includedInPackage?: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.lato}, ${({ theme }) => theme.fontFamily.seasons};
  font-size: 14px;
  font-weight: 500;
  color: ${({ theme }) => theme.primaryColor.mutedGray};
  padding: 6px 12px 6px 22px;
  ${({ theme, $includedInPackage }) =>
    !$includedInPackage
      ? `
      font-weight: 600;
      color: ${theme.primaryColor.shadePurple};
      font-style: italic;`
      : ``}
`;

export const StyledLegendWrapper = styled.div<{ $isEmpty?: boolean }>`
  display: flex;
  margin-top: -20px;
  margin-bottom: 9px;
  z-index: 1;
  width: 100%;
  align-items: center;
  justify-content: ${({ $isEmpty }) => ($isEmpty ? 'center' : 'space-between')};
`;

export const StyledLegendItems = styled.div<{ $bgColor: string; $isButton?: boolean }>`
  color: ${({ theme }) => theme.primaryColor.white};
  padding: ${({ $isButton }) => ($isButton ? '5px' : '5px 10px 5px 10px')};
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  width: fit-content;
  align-items: center;
  gap: 8px;
  background: ${({ $bgColor }) => $bgColor};
`;

export const LegendItemsWrapper = styled.div`
  display: flex;
  gap: 4px;
`;

export const ContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 410px;
  flex-direction: column;
  gap: 8px;
`;

export const EmptyMessage = styled.div`
  text-align: center;
  font-weight: 600;
  font-size: 12px;
  color: ${({ theme }) => theme.primaryColor.oceanicTeal};
`;

export const AddLink = styled.div`
  text-decoration: underline;
  font-weight: 600;
  font-family: ${({ theme }) => theme.fontFamily.lato};
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  cursor: pointer;
`;

export const StyledCardMarginWrapper = styled.div<{ $isCarousel: boolean }>`
  flex: 1;
  display: flex;
  margin-top: 30px;
  ${({ $isCarousel }) => ($isCarousel ? 'margin: 30px 9px 24px;' : '')}
  justify-content: center;
`;

export const StyledBarContainer = styled.div`
  margin-top: 16px;
`;
