import { Booking } from '@src/model/Event';

import ActionConstants from '../constants';

export const updateBookingAction = (payload: Booking[]) => {
  return {
    type: ActionConstants.UPDATE_BOOKING_DETAILS,
    payload,
  };
};

export const clearBookingAction = () => {
  return {
    type: ActionConstants.CLEAR_BOOKING_DETAILS,
  };
};
