import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps } from '@src/lib/getUpdate';

import apiRequest from './api';

export const updateSettings = (data: DataProps) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_SETTING}`,
    data,
  });
};

export const getSettings = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SETTING_DETAILS}`,
    config: { params },
  });
};
