import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100vh;
`;

export const StyledSidebar = styled.div`
  flex: 0 0 15%;
  background-color: #f0f0f0;
  box-sizing: border-box;
  overflow: auto;
  height: 100vh;
  @media (max-width: 768px) {
    flex: 0 0 17%;
  }
`;

export const StyledContent = styled.div`
  flex: 1;
  box-sizing: border-box;
  overflow: auto;
  height: 100vh;
`;

export const StyledHeader = styled.div`
  flex: 0 0 auto;
  position: sticky;
  top: 0;
  z-index: 10;
`;
