import actionConstants from '../constants';
import { FilterStateProps } from '../type';

const initialState: FilterStateProps = {
  orders: undefined,
  eventFeedback: undefined,
  dishes: undefined,
};

export default function filterDetailsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.SET_FILTER:
      return {
        ...state,
        ...action?.payload,
      };
    default:
      return state;
  }
}
