import { useState } from 'react';

import { ClickAwayListener, Tooltip } from '@mui/material';
import Modal from '@mui/material/Modal';

import { OpenTooltips } from '@src/components/DishItem';
import { BUFFET } from '@src/lib/constants';
import { getAllergensIcon } from '@src/lib/helper';
import { closeIcon, notFound } from '@src/lib/imgUrl';
import { Service } from '@src/model/Event';
import { WeeklyAllergens, WeeklyDish } from '@src/model/WeeklyMenu';

import {
  StyledAllergensContainer,
  StyledAllergensImgContainer,
  StyledAllergensLeftContainer,
  StyledAllergensRightContainer,
  StyledBrandName,
  StyledContainer,
  StyledDescriptionContainer,
  StyledDishContainer,
  StyledDishImage,
  StyledDishImageContainer,
  StyledDishImgContainer,
  StyledDishNameContainer,
  StyledHeadingContainer,
} from './styles';

interface MoreInfoProps {
  dishData: WeeklyDish | undefined;
  showModal: boolean;
  handleClose: () => void;
  serviceType: Service;
  allergens?: WeeklyAllergens[];
}

const MoreInfo = ({ handleClose, showModal, allergens, dishData, serviceType }: MoreInfoProps) => {
  const [openTooltips, setOpenTooltips] = useState<OpenTooltips>({});

  const dishImage = dishData?.pictures?.find(({ serviceType: service }) => {
    return service === serviceType;
  });

  const handleTooltipToggle = (index: number, isOpen: boolean) => {
    setOpenTooltips(prevOpenTooltips => ({
      ...prevOpenTooltips,
      [index]: isOpen,
    }));
  };

  return (
    <Modal
      open={showModal}
      onClose={handleClose}
      aria-labelledby='modal-modal-title'
      aria-describedby='modal-modal-description'
    >
      <StyledContainer>
        <StyledDishContainer>
          <StyledDishNameContainer>{dishData?.name}</StyledDishNameContainer>
          <StyledDishImgContainer onClick={handleClose} src={closeIcon} alt='close icon' />
        </StyledDishContainer>
        <StyledDishImage>
          <StyledDishImageContainer src={dishImage?.url || notFound} alt='dish image' />
          {dishData?.byBrand && <StyledBrandName>by {dishData?.byBrand}</StyledBrandName>}
        </StyledDishImage>
        <StyledAllergensContainer>
          {allergens?.length ? (
            <StyledAllergensLeftContainer>
              {allergens?.map((allergen, index) => {
                return (
                  <ClickAwayListener onClickAway={() => handleTooltipToggle(index, false)}>
                    <Tooltip
                      key={`${allergen?._id}+${index}`}
                      title={allergen?.name}
                      placement='right-start'
                      onClose={() => handleTooltipToggle(index, false)}
                      open={openTooltips[index] || false}
                      onMouseEnter={() => handleTooltipToggle(index, true)}
                      onMouseLeave={() => handleTooltipToggle(index, false)}
                    >
                      <StyledAllergensImgContainer
                        onClick={() => handleTooltipToggle(index, true)}
                        src={allergen?.icon?.url || getAllergensIcon(allergen?.name)}
                        alt={allergen?._id}
                      />
                    </Tooltip>
                  </ClickAwayListener>
                );
              })}
            </StyledAllergensLeftContainer>
          ) : null}
          <StyledAllergensRightContainer>Actual product may differ from image</StyledAllergensRightContainer>
        </StyledAllergensContainer>
        <StyledDescriptionContainer>{dishData?.description}</StyledDescriptionContainer>
        {dishData?.ingredients?.length ? (
          <div>
            <StyledHeadingContainer>Ingredients:</StyledHeadingContainer>
            <StyledDescriptionContainer>
              {dishData?.ingredients?.map(item => item?.name).join(', ')}.
            </StyledDescriptionContainer>
          </div>
        ) : null}
        <StyledHeadingContainer>
          Once serving contains about {serviceType === BUFFET ? dishData?.quantity : dishData?.dropOffQuantity}gm
        </StyledHeadingContainer>
      </StyledContainer>
    </Modal>
  );
};

export default MoreInfo;
