import apiEndpoints from '@src/lib/apiEndpoints';
import { Params } from '@src/lib/getOptions';
import { DataProps, DeleteBodySchema } from '@src/lib/getUpdate';
import { AddOns } from '@src/model/AddOns';

import apiRequest from './api';

export const createAddOns = (data: AddOns) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CREATE_ADDONS}`,
    data,
  });
};

export const updateAddOns = (data: DataProps, id: string) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.ADDONS}/${id}`,
    data,
  });
};

export const getAddOnsById = (id: string, params?: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ADDONS}/${id}`,
    config: { params },
  });
};

export const getAddOns = (params: Params) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ADDONS_LIST}`,
    config: { params },
  });
};

export const updateAddOnStatus = (data: DeleteBodySchema, status: boolean, doubleConfirmed: boolean) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.UPDATE_ADDONS_STATUS}`,
    data: { ...data, status, doubleConfirmed },
  });
};
