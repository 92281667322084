import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';

import { createBooking, deleteBookingById, getBookingById } from '@src/api/booking';
import AddCircleIcon from '@src/components/Icons/addCircle';
import EditWhiteIcon from '@src/components/Icons/editWhiteIcon';
import ThreshIcon from '@src/components/Icons/threshIcon';
import { useFetchEventData } from '@src/hooks/usefetchEventData';
import { description, isAddonCategory, orderSummaryStatus, StatusType, UiRoutes } from '@src/lib/constants';
import {
  capitalizeFirstLetter,
  dateFormatter,
  getCategoryDish,
  getPackageColor,
  groupByBookingDateAndTime,
} from '@src/lib/helper';
import { emptyMenu } from '@src/lib/imgUrl';
import { AddOn, Booking, CreateBookingProps, Dish } from '@src/model/Event';
import { MealType } from '@src/model/WeeklyMenu';
import { addNewBooking } from '@src/redux/action/eventDetails';
import { startLoaderAction, stopLoaderAction } from '@src/redux/action/login';
import { ReduxProps } from '@src/redux/type';

import CustomModal from '../../components/DeleteModal';

import {
  AddLink,
  ContentWrapper,
  EmptyMessage,
  LegendItemsWrapper,
  StyledCardMarginWrapper,
  StyledDishHeader,
  StyledDishItems,
  StyledFieldset,
  StyledLegendItems,
  StyledLegendWrapper,
} from './styles';

const LabelLegend = ({
  label,
  buttonColor,
  isEmpty,
  booking,
  date,
}: {
  label?: string;
  buttonColor: string;
  isEmpty?: boolean;
  booking?: Booking;
  date?: string;
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { eventId } = useParams();
  const { fetchEventData } = useFetchEventData();

  const {
    Bookings: allBookings,
    startDate,
    endDate,
    eventStatus,
    makeMyOwn,
  } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const isAllowedToDelete = orderSummaryStatus?.includes(eventStatus?.name as StatusType);

  const isAddButton = () => {
    const bookingDate = booking?.bookingDate;

    if (orderSummaryStatus?.includes(eventStatus?.name as StatusType)) return false;

    if (!bookingDate) return false;

    const data = groupByBookingDateAndTime(allBookings || [], startDate, endDate);

    let groupedBookingDateAndTime;

    for (const dateKey in data) {
      const dateKeyMoment = moment(dateKey);

      if (dateKeyMoment.isSame(bookingDate, 'day')) groupedBookingDateAndTime = data[dateKey];
    }

    if (!groupedBookingDateAndTime) return false;

    if (label && ['lunch', 'break'].includes(label)) {
      const details = groupedBookingDateAndTime.filter(item => item?.packageDetails?.type === label && item?.extra);

      return !details?.length;
    }

    return false;
  };

  const handleEditDish = () => {
    navigate(`${UiRoutes.CHOOSE_MEAL}?selectedMenu=${booking?._id}&date=${date}`);
  };

  const handelAddBooking = () => {
    const loaderRequest = `unique_${Date.now()}`;
    dispatch(startLoaderAction(loaderRequest));

    const newBooking = {
      eventId: booking?.eventId,
      bookingDate: date && dateFormatter(date),
      extra: true,
      makeMyOwn,
      packageDetails: {
        name: booking?.packageDetails?.name,
        packageId: booking?.packageDetails?.packageId?._id,
        service: booking?.packageDetails?.service,
        type: booking?.packageDetails?.type,
      },
    };

    createBooking(newBooking as CreateBookingProps)
      .then(res => {
        getBookingById(res?._id).then(bookingRes => {
          dispatch(addNewBooking(bookingRes?.data));
        });
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        dispatch(stopLoaderAction(loaderRequest));
      });
  };

  const handelUpdateBooking = () => {
    deleteBookingById(booking?._id as string)
      .then(() => fetchEventData({ id: eventId }))
      .catch(error => {
        console.error(error);
      });
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {isEmpty ? (
        <StyledLegendWrapper $isEmpty>
          <StyledLegendItems $bgColor={buttonColor}>
            {label?.includes('lunch') ? 'Lunch/Dinner' + label?.split('lunch')?.[1] : capitalizeFirstLetter(label)}
          </StyledLegendItems>
        </StyledLegendWrapper>
      ) : (
        <StyledLegendWrapper>
          <StyledLegendItems $bgColor={buttonColor} onClick={() => handleEditDish()}>
            {label?.includes('lunch') ? 'Lunch/Dinner' + label?.split('lunch')?.[1] : capitalizeFirstLetter(label)}
            <EditWhiteIcon />
          </StyledLegendItems>
          <LegendItemsWrapper>
            {isAddButton() ? (
              <StyledLegendItems $bgColor={buttonColor} $isButton onClick={() => handelAddBooking()}>
                <AddCircleIcon />
              </StyledLegendItems>
            ) : null}
            {!isAllowedToDelete ? (
              <StyledLegendItems $bgColor={buttonColor} $isButton onClick={() => setIsModalOpen(true)}>
                <ThreshIcon />
              </StyledLegendItems>
            ) : null}
          </LegendItemsWrapper>
          <CustomModal
            isOpen={isModalOpen}
            modalName={`Are you sure you want to delete ${capitalizeFirstLetter(label)} ?`}
            onRequestClose={() => {
              setIsModalOpen(false);
            }}
            onSave={() => {
              handelUpdateBooking();
            }}
          />
        </StyledLegendWrapper>
      )}
    </>
  );
};

const OrderDetailCardNoData = React.memo<{ type: string; isCarousel: boolean; date: string }>(
  ({ type, isCarousel, date }) => {
    const { eventId } = useParams();
    const { fetchEventData } = useFetchEventData();
    const bookingId = type?.split('##')?.[1] || '';

    const { packageDetails, _id, makeMyOwn, eventStatus } = useSelector(
      (state: ReduxProps) => state?.eventDetailsReducer
    );

    const isAllowedToAdd = orderSummaryStatus.includes(eventStatus?.name as StatusType);

    const cardColor = getPackageColor('noData');
    const navigate = useNavigate();
    const dispatch = useDispatch();

    //admin changes
    const handleEditDish = () => {
      const CreatePackageBooking = async () => {
        const loaderRequest = `unique_${Date.now()}`;
        dispatch(startLoaderAction(loaderRequest));

        const newBooking = {
          eventId: _id,
          bookingDate: date && dateFormatter(date),
          extra: false,
          packageDetails: {
            name: packageDetails?.name,
            packageId: packageDetails.packageId,
            service: packageDetails?.service,
            type: type,
          },
          makeMyOwn,
        };

        return await createBooking(newBooking as CreateBookingProps)
          .then(async res => {
            const bookingData = await getBookingById(res._id);

            !bookingData?.data?.dishes?.length &&
              navigate(`${UiRoutes.CHOOSE_MEAL}?selectedMenu=${res?._id}&date=${date}`);

            fetchEventData({ id: eventId });
            return res?._id;
          })
          .catch(error => {
            console.error(error);
          })
          .finally(() => {
            dispatch(stopLoaderAction(loaderRequest));
          });
      };

      if (makeMyOwn || bookingId) {
        if (makeMyOwn && !bookingId) {
          CreatePackageBooking().then(id => {
            navigate(`${UiRoutes.CHOOSE_MEAL}?selectedMenu=${id}&date=${date}`);
          });
        } else {
          navigate(`${UiRoutes.CHOOSE_MEAL}?selectedMenu=${bookingId || type?.split('##')[0]}&date=${date}`);
        }
      } else {
        CreatePackageBooking();
      }
    };
    //admin changes

    return (
      <StyledCardMarginWrapper $isCarousel={isCarousel}>
        <StyledFieldset $bgColor={cardColor.bgColor} $border={cardColor.border}>
          <LabelLegend label={type?.split('##')[0]} buttonColor={cardColor.buttonColor} isEmpty date={date} />
          <ContentWrapper>
            <img src={emptyMenu} />
            <EmptyMessage>{description}</EmptyMessage>
            {!isAllowedToAdd ? <AddLink onClick={handleEditDish}>Add</AddLink> : null}
          </ContentWrapper>
        </StyledFieldset>
      </StyledCardMarginWrapper>
    );
  }
);

const OrderDetailCard = React.memo<{
  booking: Booking;
  isCarousel: boolean;
  date: string;
  label: string;
}>(({ booking, isCarousel, date, label }) => {
  const { packageDetails, dishes, addOns } = booking;
  const [dishesType, setDishesType] = useState<string[]>([]);
  const cardColor = getPackageColor(packageDetails?.type ?? '');
  const { makeMyOwn: isMakeMyOwn, eventCategories } = useSelector((state: ReduxProps) => state?.eventDetailsReducer);

  const getDishCount = ({ categoryDishes, category }: { categoryDishes: (Dish | AddOn)[]; category: string }) => {
    const dishMaxCount = eventCategories?.[booking?.bookingDate.split('T')[0]]?.[label.split('(')[0] as MealType]?.find(
      ({ name }) => name === category
    );

    if (dishMaxCount?.allowedDishes) {
      return ` (${categoryDishes?.filter(({ includedInPackage }) => includedInPackage).length}/${
        dishMaxCount.allowedDishes
      })`;
    } else {
      return '';
    }
  };

  useEffect(() => {
    const result =
      eventCategories?.[booking?.bookingDate.split('T')[0]]?.[label.split('(')[0] as MealType].reduce(
        (acc: string[], item) => {
          const categoryAndDish = item.name as string;
          const selectedCount = booking?.selectedCount ? Object.keys(booking?.selectedCount) : [];

          if (
            !acc.includes(categoryAndDish) &&
            (item.allowedDishes || isMakeMyOwn || (item?._id && selectedCount?.includes(item?._id)))
          ) {
            acc.push(categoryAndDish);
          }

          return acc;
        },
        []
      ) || [];

    setDishesType(result);
  }, [eventCategories, booking]);

  return (
    <StyledCardMarginWrapper $isCarousel={isCarousel}>
      <StyledFieldset $bgColor={cardColor.bgColor} $border={cardColor.border}>
        <LabelLegend label={label} buttonColor={cardColor.buttonColor} booking={booking} date={date} />
        <div>
          {dishesType.map(category => {
            const isAddon = isAddonCategory(category);
            const categoryDishes = isAddon ? addOns || [] : getCategoryDish({ category, dishes });

            const isAddonsAllowed = isAddon
              ? eventCategories?.[booking?.bookingDate.split('T')[0]]?.[label.split('(')[0] as MealType]?.find(
                  ({ name }) => name === category
                )?.allowedDishes || addOns?.length
              : true;

            return isAddonsAllowed && (isMakeMyOwn ? categoryDishes.length : !isMakeMyOwn) ? (
              <div key={category}>
                <StyledDishHeader>
                  {category}
                  {isMakeMyOwn ? '' : getDishCount({ category, categoryDishes })}
                </StyledDishHeader>
                {categoryDishes.map(categoryDish => {
                  return (
                    <StyledDishItems
                      $includedInPackage={isMakeMyOwn || categoryDish?.includedInPackage}
                      key={categoryDish?.name}
                    >
                      {categoryDish?.name}
                    </StyledDishItems>
                  );
                })}
              </div>
            ) : null;
          })}
        </div>
      </StyledFieldset>
    </StyledCardMarginWrapper>
  );
});

export { OrderDetailCard, OrderDetailCardNoData };
