import { all } from 'redux-saga/effects';

import ingredientWatcher from './ingredient';
import loginWatcher from './login';
import staffWatcher from './staff';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export default function* rootSaga() {
  yield all([loginWatcher(), staffWatcher(), ingredientWatcher()]);
}
