import { useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';

import { getDefaultRoute } from '@src/lib/constants';
import { ReduxProps } from '@src/redux/type';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const PublicRoute = ({ component: Component }: any) => {
  const { isUserAuthenticated, role } = useSelector((state: ReduxProps) => state?.loginReducer?.loginState);

  return !isUserAuthenticated ? <Component /> : <Navigate to={getDefaultRoute(role)} replace />;
};

export default PublicRoute;
