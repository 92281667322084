import { Booking } from '@src/model/Event';

import actionConstants from '../constants';

const initialState: Booking[] = [
  {
    orderId: '',
    eventId: '',
    bookingStatus: {
      _id: '',
      name: '',
    },
    numberOfPerson: 0,
    _id: '',
    totalAmount: 0,
    deliveryAddress: {
      address: '',
      apartmentDetail: '',
      emirate: '',
    },
    bookingDate: '',
    bookingTime: '',
    packageDetails: {
      packageId: {
        _id: '',
        picture: {
          name: '',
          type: '',
          url: '',
        },
      },
      name: '',
      price: 0,
      service: '',
      type: '',
    },
    QR: {
      name: '',
      url: '',
      type: '',
    },
    dishes: [
      {
        dishId: '',
        sapId: '',
        name: '',
        quantity: 0,
        category: { name: '', _id: '' },
        subCategory: '',
        price: 0,
        addOn: false,
        QR: {
          name: '',
          url: '',
          type: '',
        },
      },
    ],
    addOns: [
      {
        addOnId: '',
        sapId: '',
        name: '',
        quantity: 0,
        category: { _id: '', name: '' },
        subCategory: { _id: '', name: '' },
        price: 0,
        QR: {
          name: '',
          url: '',
          type: '',
        },
      },
    ],
  },
];

export default function bookingDetailsReducer(state = initialState, action: any) {
  switch (action.type) {
    case actionConstants.UPDATE_BOOKING_DETAILS:
      return {
        ...state,
        booking: [...action.payload],
      };

    case actionConstants.CLEAR_BOOKING_DETAILS:
      return {
        booking: [],
      };
    default:
      return state;
  }
}
