import { useQuery, UseQueryResult } from '@tanstack/react-query';

import { getSettings } from '@src/api/setting';

interface FetchSettingsProps<T> {
  onComplete?: (result: T) => void;
  onError?: (error: any) => void;
  onFinally?: () => void;
}

export const fetchSettings =
  <T>({ onComplete, onError, onFinally }: FetchSettingsProps<T>) =>
  async (): Promise<T> => {
    try {
      const result = await getSettings({});
      const data = result.data as T;
      onComplete?.(data);
      return data;
    } catch (error) {
      console.error(error);
      onError?.(error);
      throw error;
    } finally {
      onFinally?.();
    }
  };

export const useFetchSettings = <T>(fetchSettingsProps: FetchSettingsProps<T>): UseQueryResult<T, unknown> =>
  useQuery<T>({
    queryKey: ['fetchSettings'],
    queryFn: fetchSettings<T>(fetchSettingsProps),
  });
