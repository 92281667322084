import { Cancel, CancelOutlined } from '@mui/icons-material';
import { Box, Dialog } from '@mui/material';

import { useIsMobile } from '@src/hooks/useIsMobile';

export interface CartModalProps {
  isOpen: boolean;
  handleClose: (event?: {}, reason?: 'backdropClick' | 'escapeKeyDown') => void;
  children: React.ReactNode;
}

// for mobile
export default function CartModal({ isOpen: isVisible, handleClose, children }: CartModalProps) {
  const isMobile = useIsMobile();

  return (
    <Dialog
      fullWidth
      fullScreen={isMobile}
      maxWidth='lg'
      open={isVisible}
      onClose={() => {
        handleClose();
      }}
      sx={{
        marginTop: isMobile ? '25px' : '0px',
      }}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          bottom: '-20%',
          marginBottom: isMobile ? '40%' : '0px',
        },
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: '#fff',
          outline: 'none',
          padding: isMobile ? '0 16px' : '0 40px',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div>{children}</div>
          <div onClick={handleClose}>
            <CancelOutlined />
          </div>
        </div>
      </Box>
    </Dialog>
  );
}
