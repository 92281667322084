import { theme } from '@src/styles/theme';

const SettingIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg xmlns='http://www.w3.org/2000/svg' width='22' height='22' fill='none'>
    <path
      stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeWidth={1.375}
      d='M8.25 20.167h5.5c4.583 0 6.416-1.834 6.416-6.417v-5.5c0-4.583-1.833-6.417-6.416-6.417h-5.5c-4.584 0-6.417 1.834-6.417 6.417v5.5c0 4.583 1.833 6.417 6.417 6.417Z'
    />
    <path
      stroke={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
      strokeLinecap='round'
      strokeLinejoin='round'
      strokeMiterlimit={10}
      strokeWidth={1.375}
      d='M14.273 16.958v-3.575M14.273 6.83V5.041M14.273 11.596a2.383 2.383 0 1 0 0-4.767 2.383 2.383 0 0 0 0 4.767ZM7.728 16.958v-1.787M7.728 8.617V5.042M7.727 15.171a2.383 2.383 0 1 0 0-4.767 2.383 2.383 0 0 0 0 4.767Z'
    />
  </svg>
);

export default SettingIcon;
