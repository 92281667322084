import { stepIconClasses } from '@mui/material/StepIcon';
import { createTheme } from '@mui/material/styles';

import { Breakpoints } from './breakpoints';
import { theme as styledTheme } from './theme';

export const customTheme = createTheme({
  typography: {
    fontFamily: ['Lato', 'the-seasons'].join(','),
  },
  palette: {
    primary: {
      main: `${styledTheme.primaryColor.royalBlue}`,
    },
    secondary: {
      main: `${styledTheme.primaryColor.aquaBlue}`,
    },
  },
  components: {
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontFamily: 'Lato',
          fontWeight: 400,
          fontSize: '15px',
          lineHeight: '19px',
          letterSpacing: '-0.01em',
          color: '#09142F',
          '&:hover': {
            background: '#F6F7FA',
          },
        },
      },
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          marginLeft: '25px',
          marginTop: '8px',
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          width: '100%',
        },
      },
    },
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: `${styledTheme.primaryColor.skyBlue}`,
        },
      },
    },
    MUIDataTableBodyRow: {
      styleOverrides: {
        root: {
          '&:hover': {
            cursor: 'pointer',
          },
          [`${Breakpoints.TABLET}`]: {
            '&:nth-of-type(odd)': {
              backgroundColor: `${styledTheme.primaryColor.GlacierMist}`,
            },
            '&:nth-of-type(even)': {
              backgroundColor: `${styledTheme.primaryColor.white}`,
            },
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          width: 36,
          height: 36,
          [`&.${stepIconClasses.completed}`]: {
            borderRadius: '50%',
            cursor: 'pointer',
            color: `${styledTheme.primaryColor.shadePurple}`,
          },
          [`&.${stepIconClasses.active}`]: {
            borderRadius: '50%',
            color: `${styledTheme.primaryColor.royalBlue}`,
          },
        },
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontFamily: `${styledTheme.fontFamily.lato}, ${styledTheme.fontFamily.seasons}`,
          fontWeight: 600,
          color: `${styledTheme.primaryColor.duskHarbor}`,
        },
      },
    },
  },
});
