import { theme } from '@src/styles/theme';

const DishIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg
    fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    height='22'
    width='22'
    version='1.1'
    id='Layer_1'
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 22 22'
  >
    <g transform='scale(0.04296875)'>
      <g>
        <g>
          <path
            d='M455.483,414.7h-49.097c2.789-7.137,4.185-14.622,4.185-22.456c0-3.502-0.283-7.004-0.685-10.506l21.345,21.285
            c1.797,1.797,3.593,2.695,6.288,2.695c1.797,0,4.491-0.898,6.288-1.797c80.165-80.958,89.478-206.093,27.972-296.716
            c0.864-0.751,1.716-1.548,2.568-2.4c17.067-17.965,17.067-45.811,0-63.775c-17.965-17.067-46.709-17.067-63.775,0
            c-0.883,0.883-1.765,1.972-2.648,3.177c-91.073-60.2-215.024-50.487-294.671,29.16c-1.797,1.796-2.695,3.593-2.695,6.288
            c0,2.695,0.898,4.491,2.695,6.288L267.1,239.355c-7.415,9.647-12.998,21.591-13.999,36.041c-1.497-1.251-2.896-2.462-4.215-3.517
            c-0.898-0.898-1.796-1.797-2.695-2.695c-46.709-39.523-86.232-41.319-112.281-34.133c-2.695-2.695-5.39-4.491-7.186-7.186
            c-5.389-6.288-12.575-10.779-21.558-11.677h-0.898h-0.898c-0.89,0.141-1.752,0.3-2.608,0.465l-21.644-26.514
            c-2.695-3.593-1.796-7.186-1.796-9.881c0.898-8.084-1.797-16.168-8.084-22.456c-8.084-8.084-20.66-9.881-30.54-4.491
            c-5.389,2.695-8.982,6.288-11.677,11.677c-5.389,1.797-9.881,4.491-13.474,8.982c-7.186,8.982-8.084,21.558-1.797,31.439
            c4.491,7.186,11.677,11.677,20.66,11.677c1.797,0,6.288,0,8.982,3.593l25.381,30.573c-0.006,0.369-0.008,0.736-0.003,1.101
            c-0.109,10.847,4.264,19.797,4.264,20.425l0.898,1.797c0.546,0.668,1.067,1.346,1.599,2.02
            c-29.572,33.181-58.174,70.808-51.9,102.177c2.995,13.177,8.401,25.149,16.455,35.93h-9.269c-8.982,0-17.965,4.491-23.354,12.575
            s-7.186,17.965-3.593,26.947l17.067,44.912c0.898,2.695,4.491,5.389,8.084,5.389h431.158c3.593,0,7.186-2.695,8.084-5.389
            l17.067-44.912c2.695-8.982,1.796-18.863-3.593-26.947S465.364,414.7,455.483,414.7z M442.908,72.468
            c-6.288-6.288-12.575-12.575-19.761-17.965c0.898,0,0.898,0,0.898-0.898c9.881-10.779,26.947-10.779,37.726,0
            c10.779,9.881,10.779,26.947,0,37.726l-0.898,0.898c-1.119-1.305-2.284-2.655-3.472-4.021
            c-4.284-5.093-8.805-10.052-13.595-14.842c-0.098-0.097-0.199-0.19-0.298-0.288C443.308,72.877,443.108,72.668,442.908,72.468z
             M430.891,85.611c0.542,0.545,1.078,1.09,1.612,1.636c0.399,0.406,0.796,0.813,1.191,1.221c0.784,0.811,1.561,1.623,2.327,2.438
            c0.284,0.303,0.565,0.608,0.847,0.912c0.728,0.783,1.455,1.565,2.166,2.353c0.939,1.042,1.866,2.091,2.781,3.147
            c3.582,4.143,6.94,8.398,10.075,12.877c0.431,0.646,0.916,1.184,1.438,1.636c59.362,81.743,54.538,196.321-15.81,272.329
            l-29.642-29.642l-61.979-61.979h0l-66.47-66.47L133.013,79.654C217.334,1.613,348.234,3.303,430.891,85.611z M333.528,305.595
            l1.77,2.213l1.617,2.695c5.186,7.347,10.575,15.109,14.577,22.887c3.783,7.79,6.051,15.565,5.185,22.923
            c-0.898,8.084-5.389,15.27-11.677,17.067c-8.084,2.695-19.761-2.695-31.439-15.27c-23.354-25.151-37.726-48.505-42.218-68.267
            l-0.898-4.491l-0.082-0.033c-1.131-12.286,1.877-23.401,9.064-33.202l34.596,34.029L333.528,305.595z M92.592,271.879
            c-0.983-1.475-2.235-2.95-3.462-4.425c-0.924-1.228-1.86-2.451-2.826-3.659c-0.475-1.899-1.447-5.054-1.997-8.401l1.099-4.174
            c0-2.695,1.797-9.881,16.168-13.474l4.081-1.088c2.76,1.008,5.286,2.563,6.698,4.681c5.295,6.131,11.284,11.829,17.855,16.935
            l0.11,0.132l0.038-0.015c14.578,11.292,32.021,19.665,51.162,23.37c11.677,2.695,22.456,8.982,30.54,18.863
            c17.965,21.558,15.27,54.793-5.389,73.656c-11.677,9.881-25.151,14.372-40.421,13.474c-15.27-1.796-27.846-8.084-37.726-19.761
            c-6.288-8.084-10.779-18.863-11.677-29.642c-1.415-20.513-8.421-41.019-20.099-59.777l0.338-0.405L92.592,271.879z
             M32.41,198.222c-2.695,0-4.491-0.898-6.288-3.593c-1.797-2.695-1.797-7.186,0.898-9.881c1.796-1.797,2.695-2.695,4.491-3.593
            c5.389-0.898,9.881-4.491,11.677-8.982c0.898-1.797,1.797-2.695,2.695-4.491c3.593-0.898,7.186-0.898,9.881,1.796
            c1.797,1.797,2.695,4.491,2.695,7.186c-0.898,8.982,0.898,17.067,6.288,23.354l18.526,22.937
            c-3.896,2.324-7.108,5.123-9.65,8.375l-18.757-22.329C49.476,202.714,41.392,198.222,32.41,198.222z M39.596,376.075
            c-4.698-24.274,24.732-60.832,44.855-83.498c8.724,14.985,14.057,31.049,15.327,47.568c0.898,14.372,7.186,27.846,16.168,39.523
            c12.575,15.27,30.54,24.253,50.302,26.049c1.797,0,3.593,0,5.39,0c17.965,0,34.133-6.288,47.607-17.965
            c27.846-25.151,30.54-70.063,7.186-98.807c-9.881-12.575-24.253-21.558-40.421-25.151c-11.05-2.456-21.342-6.232-30.646-11.298
            c25.215-1.42,52.457,9.674,79.151,32.855c0.898,0,1.796,0.898,2.695,1.796c4.436,3.549,10.629,9.722,18.57,13.336
            c1.87,6.943,4.729,14.313,8.759,22.125c7.975,15.626,20.415,32.337,37.336,49.864c0.003,0.003,0.005,0.006,0.008,0.009
            c0.001,0.001,0.001,0.001,0.002,0.002c23.353,24.25,41.317,22.454,50.3,19.759c4.793-1.712,9.059-4.604,12.609-8.366
            c6.256-6.169,10.476-14.94,11.644-24.869c0.31-3.714,0.157-7.373-0.358-10.981l14.732,14.69
            c0.013,5.351,0.902,10.701,1.794,16.052c0.898,5.389,1.797,9.881,1.797,15.27c0,7.186-1.796,14.372-5.389,20.66H62.052
            C50.375,403.022,43.189,390.447,39.596,376.075z M466.262,447.935l-14.372,38.625H33.308l-14.372-38.625
            c-1.797-2.695-0.898-7.186,0.898-9.881c1.797-2.695,5.389-4.491,8.982-4.491h427.565c3.593,0,7.186,1.797,8.982,4.491
            C467.16,440.749,467.16,444.342,466.262,447.935z'
          />
          <path
            d='M373.743,85.942c11.677,7.186,21.558,15.27,31.439,25.151c1.797,1.796,3.593,2.695,6.288,2.695
            c2.695,0,5.39-0.898,6.288-2.695c3.593-3.593,3.593-8.982,0-12.575c-9.881-10.779-21.558-19.761-34.133-27.846
            c-4.491-2.695-9.881-1.797-12.575,2.695C368.353,77.857,369.252,83.247,373.743,85.942z'
          />
          <path
            d='M326.136,64.384c4.491,1.797,9.881-1.796,10.779-6.288c1.797-4.491-1.797-9.881-6.288-10.779
            c-52.098-13.474-111.382-1.797-158.091,31.439c-4.491,2.695-5.389,8.084-2.695,12.575c1.797,1.797,4.491,3.593,7.186,3.593
            c1.796,0,3.593-0.898,5.389-1.796C224.634,63.486,278.529,52.707,326.136,64.384z'
          />
        </g>
      </g>
    </g>
  </svg>
);

export default DishIcon;
