import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';

import { StyledCheckBox, StyledCheckboxLabel, StyledInvoiceMessage, StyledLink } from './style';

export interface InvoiceMessageProps {
  message: string;
  isCheckBox?: boolean;
  isErrorIcon?: boolean;
  isBorderRadius?: boolean;
  handleCheck?: () => void;
  placeLink?: { placeLinkAt: string; link: string };
  textColor?: string;
  bgColor?: string;
  checkboxColor?: string;
}

const InvoiceMessage = ({
  message,
  isCheckBox,
  isErrorIcon,
  isBorderRadius,
  handleCheck,
  placeLink,
  textColor,
  bgColor,
  checkboxColor,
}: InvoiceMessageProps) => {
  const renderMessage = () => {
    if (placeLink && message.includes(placeLink.placeLinkAt)) {
      const { placeLinkAt, link } = placeLink;
      const startIndex = message.indexOf(placeLinkAt);
      const endIndex = startIndex + placeLinkAt.length;
      return (
        <>
          {message.substring(0, startIndex)}
          <StyledLink to={link}>{placeLinkAt}</StyledLink>
          {message.substring(endIndex)}
        </>
      );
    } else {
      return <StyledCheckboxLabel>{message}</StyledCheckboxLabel>;
    }
  };

  return (
    <StyledInvoiceMessage backgroundColor={bgColor} isBorderRadius={isBorderRadius} textColor={textColor}>
      {isErrorIcon && <ErrorOutlineIcon />}
      {isCheckBox && <StyledCheckBox color={checkboxColor} type='checkbox' onChange={handleCheck} />}
      <div>{renderMessage()}</div>
    </StyledInvoiceMessage>
  );
};

export default InvoiceMessage;
