import { theme } from '@src/styles/theme';

const DownArrowIcon = ({ color = theme?.primaryColor?.mutedGray }: { color?: string }) => (
  <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M14.9401 11.787L10.0501 6.89699C9.47256 6.31949 8.52756 6.31949 7.95006 6.89699L3.06006 11.787'
      stroke={color}
      strokeWidth='1.125'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default DownArrowIcon;
