import { theme } from '@src/styles/theme';

const DashboardIcon = ({ isSelected = false, isHovered = false }: { isSelected: boolean; isHovered: boolean }) => (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    width='22'
    height='22'
    fill={isHovered || isSelected ? theme.primaryColor.twilightBlue : theme.primaryColor.whiteSmoke}
    viewBox='0 0 64 64'
  >
    <g strokeLinecap='round' strokeLinejoin='round' strokeWidth={2.752} clipPath='url(#a)'>
      <path d='M25.545 8H11a3 3 0 0 0-3 3v17.931a3 3 0 0 0 3 3h14.545a3 3 0 0 0 3-3V11a3 3 0 0 0-3-3ZM52.94 8H38.395a3 3 0 0 0-3 3v4.272a3 3 0 0 0 3 3H52.94a3 3 0 0 0 3-3V11a3 3 0 0 0-3-3ZM52.94 25.121H38.395a3 3 0 0 0-3 3V52.94a3 3 0 0 0 3 3H52.94a3 3 0 0 0 3-3V28.121a3 3 0 0 0-3-3ZM25.545 38.818H11a3 3 0 0 0-3 3v11.121a3 3 0 0 0 3 3h14.545a3 3 0 0 0 3-3V41.818a3 3 0 0 0-3-3Z' />
    </g>
    <defs>
      <clipPath id='a'>
        <path d='M6 6h51.94v51.939H6z' />
      </clipPath>
    </defs>
  </svg>
);

export default DashboardIcon;
