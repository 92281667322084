import styled from 'styled-components';

import { Breakpoints } from '@src/styles/breakpoints';

export const StyledMainContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.white};
`;

export const StyledAccordionContainer = styled.div`
  background-color: ${({ theme }) => theme.primaryColor.white};
`;

export const StyledFoodContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

export const StyledHelmet = styled.div`
  width: 100%;
`;

export const StyledItemCategory = styled.div`
  font-size: 18px;
  font-weight: 700;
`;

export const StyledDishesContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 8px;
`;

export const StyledCenteredFlexColumn = styled.div`
  flex: 1;
  padding: 24px;
  padding-top: 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  /* gap: 24px; */
  ${Breakpoints.MOBILE} {
    padding: 0px;
  }
`;

export const StyledSelectedMenu = styled.div`
  padding: 5px 10px;
  border-radius: 4px;
  background: ${({ theme }) => theme.primaryColor.azure};
  color: ${({ theme }) => theme.primaryColor.shadePurple};
  font-weight: 500;
`;

export const StyledColumnTwo = styled.div`
  display: flex;
  min-width: 29%;
  max-width: 30%;
  position: sticky;
  top: 50px;
  max-height: 85vh;
`;

export const BreadCrumbText = styled.span`
  font-family: ${({ theme }) => theme.fontFamily.seasons};
  font-size: '24px';
  font-weight: 700;
  color: #ffffff;
`;

export const BreadCrumbContainer = styled.div`
  padding: 32px 0 32px 32px;
  align-items: center;
  justify-content: flex-start;
  background-color: ${({ theme }) => theme.primaryColor.shadePurple};
  flex-direction: row;
  border-radius: 8px;
  margin-top: 16px;
  display: flex;
`;
