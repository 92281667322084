const DownArrowSmallIcon = () => (
  <svg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M11.6201 5.2207L7.81679 9.02404C7.36763 9.4732 6.63263 9.4732 6.18346 9.02404L2.38013 5.2207'
      stroke='#0F222E'
      strokeWidth='0.875'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);

export default DownArrowSmallIcon;
